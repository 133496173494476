<template>
  <div class="menu-page">
    <v-card class="nd-border" flat>
      <v-card-title>
        Minha Equipe
      </v-card-title>

      <v-card-subtitle>
        Cadastre gerentes e atendentes aqui. Organize sua equipe e dê permissões específicas para cada um. <ib-btn small @click="showVideo = true"><v-icon size="16" class="mr-1">mdi-help-circle-outline</v-icon>Saiba mais</ib-btn>
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-spacer></v-spacer>
            <ib-btn color="primary" @click.native="openNew">
              <v-icon left>mdi-plus</v-icon>
              Novo membro
            </ib-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="objects" :loading="loading">
              <template #[`item.user_role`]="{ item }">
                {{ types_dictionary[item.user_role] }}
              </template>

              <template #[`item.departments`]="{ item }">
                {{ getDepartmentsNames(item.departments) }}
              </template>

              <template #[`item.actions`]="{ item }">
                <v-menu>
                  <template #activator="{ on, attrs }">
                    <ib-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </ib-btn>
                  </template>

                  <v-card>
                    <v-list-item :disabled="item.user_role == 'owner'" @click="openObject(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-file-edit-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item :disabled="item.user_role == 'owner'" @click="confirmDeleteObject(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Apagar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <ib-dialog v-model="dialog" scrollable max-width="600" persistent>
      <v-card>
        <ib-dialog-header title="Gerenciar equipe" subtitle="Gerentes podem ver e editar tudo, exceto a área de assinatura. Atendentes podem apenas interagir com contatos e e conversas." :close="() => { dialog = false }"></ib-dialog-header>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <ib-text-field v-model="object.name" class="pt-1" label="Nome" outlined hide-details="auto" />
            </v-col>

            <v-col cols="12">
              <ib-text-field v-model="object.email" class="pt-1" label="E-mail" outlined hide-details="auto" />
            </v-col>

            <v-col cols="12">
              <ib-select v-model="object.user_role" outlined :items="types" item-text="name" item-value="id" label="Tipo de usuário" hide-details="auto" />
            </v-col>

            <v-col v-if="object.user_role == 'user'" cols="12">
              <ib-select v-model="object.departments" outlined chips small-chips multiple :items="departments" item-text="name" item-value="_id" label="Departamentos" hide-details="auto" :message="`${object.name} é atendente e só poderá ver e interagir com mensagens de seus departamentos. Gerentes podem interagir com todos os departamentos.`">
                <template #selection="{ item }">
                  <ib-chip small color="primary">
                    {{ item.name }}
                  </ib-chip>
                </template>
              </ib-select>
            </v-col>

            <div v-if="object.user_role && object.user_role != 'owner' && access_restrictions[object.user_role]">
              <v-col class="pl-4" cols="12" v-for="(access, access_index) in access_restrictions[object.user_role]" :key="`access_${access_index}`">
                <v-checkbox v-model="object.access_restrictions" class="mt-0" inset dense color="error lighten-1" hide-details="auto" :label="access.name" :value="access.id"></v-checkbox>
              </v-col>
            </div>

            <v-col cols="12">
              <ib-text-field v-model="object.password" class="pt-1" type="password" label="Senha" outlined hide-details="auto" messages="O usuário poderá trocar a senha depois de acessar" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <ib-btn outlined color="gray200" @click.native="dialog = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn color="primary" :loading="loading" @click.native="save">
            Salvar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>


    <ib-dialog v-model="deleteDialog" overlay-opacity="0.5" overlay-color="black" max-width="350">
      <v-card>
        <ib-dialog-header title="Apagar conta" :subtitle="`Tem certeza que deseja apagar a conta de ${object.name}?`" :close="() => { deleteDialog = false }"></ib-dialog-header>

        <v-card-actions>
          <ib-btn outlined color="gray200" @click.native="deleteDialog = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn color="error" @click="deleteDialog = false; deleteObject()">
            Apagar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <video-dialog v-model="showVideo" videoUrl="https://www.youtube.com/embed/yWSrkDnB2Ds?rel=0" />
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import VideoDialog from "@/components/VideoDialog.vue";

export default {
  name: "MensagensRapidasView",

  components: {
    VideoDialog
  },

  data() {
    return {
      showVideo: false,
      objects: [],
      object: { access_restrictions: [] },
      departments: [],
      loading: true,
      dialog: false,
      deleteDialog: false,
      types_dictionary: {
        "owner": "Proprietário da conta",
        "manager": "Gerente",
        "user": "Atendente"
      },
      types: [
        { id: 'manager', name: 'Gerente' },
        { id: 'user', name: 'Atendente' },
      ],
      access_restrictions: {
        manager: [
          { id: 'dashboard', name: 'Restringir acesso: Dashboard' },
          { id: 'team', name: 'Restringir acesso: Minha equipe' },
          { id: 'contacts', name: 'Restringir acesso: Contatos' }
        ],
        user: [
          { id: 'contacts', name: 'Restringir acesso: Contatos' }
        ],
      },
      headers: [
        { text: 'Nome', value: 'name', sortable: false },
        { text: 'E-mail', value: 'email', sortable: false },
        { text: 'Permissões', value: 'user_role', sortable: false },
        { text: 'Departamentos', value: 'departments', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false },
      ]
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },

  async mounted() {
    if (this.currentUser?.access_restrictions?.includes('team')) {
      emitToastr("error", "Permissões insuficientes.");
      this.$router.replace({ name: 'Atendimento' });
    }

    await this.getObjects();
    await this.getDepartments();
  },

  methods: {
    async getObjects() {
      this.loading = true;
      const resp = await Api.getTeamUsers();
      this.loading = false;

      if (!resp.error && resp.message) {
        this.objects = resp.message;
      }
    },

    async getDepartments() {
      const resp = await Api.getDepartmentsByUser();

      if (!resp.error && resp.message) {
        this.departments = resp.message;
      }
    },

    getDepartmentsNames(departments) {
      let names = "";
      const user_deparments = this.departments.filter(d => departments.includes(d._id.toString()));

      user_deparments.forEach(d => names += `, ${d.name}`);
      if (names.length > 0) names = names.substring(2);

      return names;
    },

    openNew() {
      this.object = { access_restrictions: [] };
      this.dialog = true;
    },

    confirmDeleteObject(item) {
      this.object = item;
      this.deleteDialog = true;
    },

    async deleteObject() {
      this.object.filed = true;
      this.save();
    },

    openObject(item) {
      this.object = item;
      if (!this.object.access_restrictions) this.object.access_restrictions = [];
      this.dialog = true;
    },

    async save() {
      if (!this.object.name) return emitToastr("error", "Todos os campos são obrigatórios");
      if (!this.object.email) return emitToastr("error", "Todos os campos são obrigatórios");
      if (!this.object.user_role) return emitToastr("error", "Todos os campos são obrigatórios");

      if (!this.object.access_restrictions) this.object.access_restrictions = [];

      if (this.object.user_role == 'user') {
        if (!this.object.departments || this.object.departments.length == 0) return emitToastr("error", "É necessário definir pelo menos 1 departamento");
      }

      if (!this.object._id) {
        if (!this.object.password) return emitToastr("error", "Todos os campos são obrigatórios");
        if (this.object.password.length < 6) return emitToastr("error", "A senha precisa ter pelo menos 6 dígitos");
      }

      if (!this.object._id) {
        this.loading = true;
        const resp = await Api.createTeamUser(this.object);
        this.loading = false;

        if (!resp.error) {
          emitToastr("success", "Atendente criado com sucesso");
          this.getObjects();
          this.dialog = false;
        }
      }
      else {
        this.loading = true;
        const resp = await Api.updateUser(this.object);
        this.loading = false;

        if (!resp.error) {
          emitToastr("success", "Atendente atualizado com sucesso");
          this.getObjects();
          this.dialog = false;
        }
      }
    }
  },
};
</script>

<style scoped></style>