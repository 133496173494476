<template>
    <div>
        <v-card-title class="d-flex flex-row-reverse justify-space-between">
            <div v-if="close">
                <v-btn icon tile small rounded @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
            <div>{{ title }}</div>
        </v-card-title>

        <v-card-subtitle>{{ subtitle }}<v-divider class="mt-1"></v-divider></v-card-subtitle>
    </div>
</template>

<script>
export default {
    props: {
        close: {
            type: Function,
            default: () => { }
        },
        title: {
            type: String,
            default: ""
        },
        subtitle: {
            type: String,
            default: ""
        },
    }
}
</script>


<style lang="scss"></style>