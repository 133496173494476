<template>
  <div class="menu-page">
    <v-card class="mb-4 nd-border" flat max-width="1000">
      <v-card-title>
        Meus dados
      </v-card-title>

      <v-card-text>
        <v-form @submit.prevent="save">
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" sm="4" md="3" lg="2" class="d-flex justify-center">
                  <v-avatar size="140">
                    <v-img v-if="userImage" :src="userImage" />
                    <div v-else class="w-full h-full primary d-flex justify-center">
                      <v-icon style="font-size:120px">
                        mdi-account
                      </v-icon>
                    </div>
                  </v-avatar>
                </v-col>

                <v-col cols="12" sm="auto" class="d-flex align-center text-center text-sm-left">
                  <div>
                    <ib-btn color="primary" class="ma-1" @click="pickFile">
                      Alterar Foto
                    </ib-btn>
                    <ib-btn color="error" class="ma-1" outlined @click="removeImage">
                      Remover Foto
                    </ib-btn>

                    <div class="w-full mt-4">
                      Imagens permitidas: JPG, PNG e com tamanho máximo de 2MB.
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <ib-text-field v-model="user.name" label="Nome" required outlined hide-details="auto" />
            </v-col>


            <v-col cols="12" md="6">
              <ib-text-field v-model="user.phone" v-mask="'(##) #####-####'" label="Celular" required outlined hide-details="auto" />
            </v-col>

            <v-col cols="12" class="d-flex">
              <ib-btn outlined color="gray200" :disabled="saving" @click="load">
                <div class="gray1000--text">
                  Cancelar
                </div>
              </ib-btn>

              <v-spacer />

              <ib-btn color="primary" type="submit" :loading="saving">
                Salvar
              </ib-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-card class="nd-border" flat max-width="1000">
      <v-card-title>
        Redefinir senha
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="updatePassword">
          <v-row>
            <v-col cols="12">
              <ib-text-field v-model="updatePasswordForm.oldPassword" label="Senha atual" outlined hide-details="auto" :type="updatePasswordForm.showPassword ? 'text' : 'password'" :append-icon="updatePasswordForm.showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="updatePasswordForm.showPassword = !updatePasswordForm.showPassword" />
            </v-col>

            <v-col cols="6">
              <ib-text-field v-model="updatePasswordForm.newPassword" label="Nova senha" outlined hide-details="auto" :type="updatePasswordForm.showPassword ? 'text' : 'password'" />
            </v-col>

            <v-col cols="6">
              <ib-text-field v-model="updatePasswordForm.confirmNewPassword" label="Confirmar nova senha" outlined hide-details="auto" :type="updatePasswordForm.showPassword ? 'text' : 'password'" />
            </v-col>

            <v-col cols="12" class="d-flex justify-end">
              <ib-btn color="primary" type="submit">
                Redefinir senha
              </ib-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
  name: "PerfilView",

  data() {
    return {
      user: {
        name: '',
        email: '',
        profile_picture: ''
      },
      saving: false,
      image: {
        file: null,
        preview: null,
      },
      updatePasswordForm: {
        showPassword: false,
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      }
    };
  },
  computed: {
    userImage() {
      return this.image.preview || this.user.profile_picture;
    },
    currentUser: {
      get() {
        return this.$store.state.currentUser;
      },
      set(value) {
        this.$store.commit('setCurrentUser', value);
      }
    }
  },
  mounted() {
    this.load()

    this.$store.commit('setBreadcrumbs', [
      { label: 'Perfil' },
    ])
  },
  methods: {
    async load() {
      const user = await Api.getRemoteUser();

      this.user = user;

      this.image.preview = null;
      this.image.file = null;
    },

    async pickFile() {
      const input = document.createElement('input')

      input.type = 'file'
      input.accept = 'image/jpeg, image/png'

      input.onchange = async (e) => {
        const file = e.target.files[0]

        this.setImage(file)
      }

      input.click()
    },
    removeImage() {
      this.image.file = null
      this.user.profile_picture = null
    },
    setImage(file) {
      const preview = URL.createObjectURL(file)

      this.image.file = file
      this.image.preview = preview
    },
    async saveUserImage(file) {
      const response = await Api.uploadProfilePicture(file);

      if (response.error) {
        emitToastr('error', response.message);
        return
      }

      this.user.profile_picture = response.message;
    },
    async save() {
      this.saving = true;

      if (this.image.file) {
        await this.saveUserImage(this.image.file);
      }

      const response = await Api.updateUser(this.user);
      this.saving = false;

      if (response.error) {
        return;
      }

      this.currentUser.name = this.user.name;
      this.currentUser.email = this.user.email;
      this.currentUser.profile_picture = this.user.profile_picture;

      emitToastr('success', 'Dados atualizados com sucesso');
    },

    async updatePassword() {
      const { oldPassword, newPassword, confirmNewPassword } = this.updatePasswordForm;

      if (!oldPassword || !newPassword || !confirmNewPassword) {
        emitToastr('error', 'Preencha todos os campos');
        return;
      }

      if (newPassword !== confirmNewPassword) {
        emitToastr('error', 'As senhas não conferem');
        return;
      }

      const response = await Api.changeUserPassword(newPassword, oldPassword);

      if (response.error) {
        return;
      }

      emitToastr('success', 'Senha alterada com sucesso');
    }
  },
};
</script>