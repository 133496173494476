<template>
  <v-col v-if="!isMobile" v-bind="$attrs" v-on="$listeners">
    <slot />
  </v-col>

  <v-dialog v-else fullscreen persistent v-bind="$attrs" v-on="$listeners" class="custom-dialog">
    <slot />
  </v-dialog>
</template>

<script>

/**
 * Component that shows a dialog on desktop and a bottom sheet on mobile
 */

export default {
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  }
}

</script>

<style>
.v-dialog--fullscreen {
  background-color: var(--v-gray25-base) !important;
}

</style>