<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <ib-dialog-header title="Finalizar conversas em massa" subtitle="Finalize várias conversas de uma vez através do tempo de inatividade. Por exemplo, ao selecionar 48 horas, todas as conversas que não trocaram nenhuma mensagem nesse período serão finalizadas." :close="closeDialog"></ib-dialog-header>


      <v-card-text>
        <v-row>
          <v-col cols="12">
            <ib-text-field v-model="hours" label="Horas de inatividade" hide-details="auto" type="number" messages="Quanto menor o tempo, mais conversas serão finalizadas. Tenha cuidado." outlined />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <ib-btn color="gray200" outlined @click="closeDialog">
          <div class="gray1000--text">
            Cancelar
          </div>
        </ib-btn>
        <v-spacer />
        <ib-btn color="primary" :loading="loading" @click="finishInactiveConversations">
          Finalizar conversas
        </ib-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  data() {
    return {
      loading: false,
      hours: 48
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },

    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    clear() {
      this.hours = 48;
    },

    closeDialog() {
      this.clear();
      this.dialog = false;
    },

    async finishInactiveConversations() {
      this.loading = true;
      const resp = await this.$api.finishInactiveConversations(this.hours);
      this.loading = false;

      if (!resp.error && resp.message) {
        this.$toast("success", "Conversas finalizadas com successo!");
        this.closeDialog();
      }
    },
  }
};
</script>

<style>
.status-label {
  font-size: 16px;
  font-weight: 500;
  margin-top: 12px;
}
</style>