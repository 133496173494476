<template>
  <div class="menu-page">
    <v-card class="nd-border" flat>
      <v-card-title>
        Clientes
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex">
            <ib-text-field v-model="search" class="mb-6" append-icon="mdi-magnify" label="Busque por nome ou e-mail" clearable outlined single-line hide-details style="max-width: 500px;" @input="searchOnServer" />
          </v-col>
        </v-row>

        <v-data-table :headers="headers" :items="objects" :options.sync="options" :server-items-length="totalObjects" :loading="loading">
          <template #[`item.created`]="{ item }">
            {{ new Date(item.created).toLocaleString('pt-BR').replace(',', ' -') }}
          </template>

          <template #[`item.name`]="{ item }">
            {{ item.override_name || item.name }}
          </template>

          <template #[`item.user_role`]="{ item }">
            <span v-if="item.user_role == 'owner'"> Proprietário(a)</span>
            <span v-if="item.user_role == 'manager'"> Gerente</span>
            <span v-if="item.user_role == 'user'"> Atendente</span>
          </template>

          <template #[`item.account.subscription_status`]="{ item }">
            <span v-if="item.account.subscription_status == 'active'"> <v-chip small color="success">Ativo</v-chip></span>
            <span v-if="item.account.subscription_status == 'canceled'"> <v-chip small color="error lighten-1">Cancelado</v-chip></span>
          </template>

          <template #[`item.account.custom_negotiation`]="{ item }">
            <span v-if="item.account.custom_negotiation"> Sim</span>
            <span v-if="!item.account.custom_negotiation"> Não</span>
          </template>

          <template #[`item.profile_picture`]="{ item }">
            <v-avatar size="60" class="my-2">
              <v-img v-if="item.profile_picture" :src="item.profile_picture" />
              <div v-else class="w-full h-full primary d-flex justify-center">
                <v-icon color="white" style="font-size:60px">
                  mdi-account
                </v-icon>
              </div>
            </v-avatar>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-menu>
              <template #activator="{ on, attrs }">
                <ib-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </ib-btn>
              </template>

              <v-card>
                <v-list-item @click="selectObject(item); dialog2 = true">
                  <v-list-item-icon>
                    <v-icon>mdi-lock-reset</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Mudar senha</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="selectObject(item); dialog = true">
                  <v-list-item-icon>
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Editar</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <ib-dialog v-model="dialog" scrollable max-width="600" persistent>
      <v-card>
        <ib-dialog-header title="Gerenciar conta" :close="() => { dialog = false }"></ib-dialog-header>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <ib-text-field v-model="object.account.max_channels" class="pt-1" type="number" label="Limite de Canais" outlined hide-details="auto" />
            </v-col>

            <v-col cols="12">
              <ib-text-field v-model="object.account.max_users" class="pt-1" type="number" label="Limite de Usuários" outlined hide-details="auto" />
            </v-col>

            <v-col cols="12">
              <ib-text-field v-model="object.account.max_flows" class="pt-1" type="number" label="Limite de Fluxos" outlined hide-details="auto" />
            </v-col>

            <v-col cols="12">
              <v-switch v-model="object.account.campaigns_enabled" inset class="mt-0" label="Campanhas Habilitadas?" />
            </v-col>

            <v-col cols="12">
              <v-switch v-model="object.account.custom_negotiation" inset class="mt-0" label="Negociação customizada?" messages="Ative caso a conta receba alguma cortesia a mais do que seu plano permite (como um canal a mais). Dessa forma a cortesia não será perdida na renovação." />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <ib-btn outlined color="gray200" @click.native="dialog = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn class="mb-2" color="primary" :loading="loading" @click.native="save">
            Salvar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <ib-dialog v-model="dialog2" scrollable max-width="600" persistent>
      <v-card>
        <ib-dialog-header title="Mudar senha" :close="() => { dialog2 = false }"></ib-dialog-header>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <ib-text-field v-model="newPassword" class="pt-2" label="Nova senha" outlined hide-details="auto" :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" />
            </v-col>

            <v-col cols="6">
              <ib-text-field v-model="confirmNewPassword" class="pt-2" label="Confirmar nova senha" outlined hide-details="auto" :type="showPassword ? 'text' : 'password'" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <ib-btn outlined color="gray200" @click.native="newPassword = ''; confirmNewPassword = ''; dialog2 = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn class="mb-2" color="primary" :loading="loading" @click.native="changePassword">
            Mudar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

let timeout = null;

export default {
  name: "AdminClientesView",

  data() {
    return {
      objects: [],
      object: { account: {} },
      totalObjects: 0,
      search: "",
      loading: true,
      dialog: false,
      dialog2: false,
      selectedContact: null,
      newPassword: '',
      confirmNewPassword: '',
      showPassword: false,
      options: { itemsPerPage: 10 },
      headers: [
        { text: 'Foto', value: 'profile_picture', sortable: false },
        { text: 'Nome', value: 'name', sortable: false },
        { text: "E-mail", value: "email", sortable: false },
        { text: "Tipo", value: "user_role", sortable: false },
        { text: "Plano", value: "account.plan_id", sortable: false },
        { text: "Status plano", value: "account.subscription_status", sortable: false },
        { text: "Plano customizado", value: "account.custom_negotiation", sortable: false },
        { text: 'Criação', value: 'created', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false },
      ]
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },

  watch: {
    options: {
      handler() {
        this.getObjects(this.options);
      },
      deep: true,
    },
  },

  async mounted() {

  },

  methods: {
    selectObject(item) {
      this.object = JSON.parse(JSON.stringify(item))
    },

    async changePassword() {
      if (!this.newPassword || this.newPassword.length < 6) return emitToastr("error", "A senha deve ter pelo menos 6 dígitos");
      if (this.confirmNewPassword != this.newPassword) return emitToastr("error", "As senhas não conferem");

      this.loading = true;
      const resp = await Api.changeCustomerPassword(this.object._id, this.newPassword);
      this.loading = false;

      if (!resp.error && resp.message) {
        emitToastr("success", "Senha alterada com sucesso!");
        this.newPassword = '';
        this.confirmNewPassword = '';
        this.showPassword = false;
        this.dialog2 = false;
      }
    },

    async getObjects(options) {
      this.loading = true;
      const resp = await Api.getCustomers(options);
      this.loading = false;

      if (!resp.error && resp.message) {
        this.objects = resp.message.data;
        this.totalObjects = resp.message.total;
      }
    },

    async searchOnServer() {
      if (timeout) clearTimeout(timeout);
      this.loading = true;

      timeout = setTimeout(() => {
        this.options.page = 1;
        this.options.query = this.search;
        this.loading = false;
        this.getObjects(this.options);
      }, 1000);
    },

    async save() {
      if (isNaN(this.object.account.max_channels)) return emitToastr("error", "Quantidade de canais inválida!");
      if (isNaN(this.object.account.max_users)) return emitToastr("error", "Quantidade de usuários inválida!");
      if (isNaN(this.object.account.max_flows)) return emitToastr("error", "Quantidade de fluxos inválida!");

      this.loading = true;
      const resp = await Api.updateAccount(this.object.account);
      this.loading = false;
      if (!resp.error && resp.message) {
        emitToastr("success", "Conta atualizada com sucesso!");
        this.search = '';
        this.dialog = false;
        this.getObjects();
      }
    },
  },
};
</script>

<style scoped></style>