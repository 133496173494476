<template>
  <div class="ib-data-table">
    <v-data-table v-bind="$attrs" hide-default-footer :options.sync="innerOptions" :headers="headers" :items="items" :server-items-length="serverItemsLength" v-on="$listeners">
      <template v-for="header in headers" #[`header.${header.value}`]="props">
        <slot :name="header.value" v-bind="props">
          <div :key="header.value" class="d-flex align-center">
            <v-icon v-if="header.icon" left size="18" v-text="header.icon" />

            <span>
              {{ header.text }}
            </span>
          </div>
        </slot>
      </template>

      <template v-for="(_s, k) of $scopedSlots" #[k]="props">
        <slot v-bind="props" :name="k" />
      </template>
    </v-data-table>

    <div class="text-center d-flex justify-center align-center pt-8">
      <ib-btn color="gray25" height="40" :disabled="innerPage <= 1" @click="innerPage -= 1" class="d-none d-md-block">
        <v-icon left v-text="'mdi-chevron-left'" />
        <div>
          Anterior
        </div>
      </ib-btn>

      <v-spacer></v-spacer>

      <v-pagination v-model="innerPage" :length="totalPage" :total-visible="5" color="gray75" navigation-color="gray50" />

      <v-spacer></v-spacer>

      <ib-btn color="gray25" height="40" :disabled="innerPage >= totalPage" @click="innerPage += 1" class="d-none d-md-block">
        <div>
          Próximo
        </div>

        <v-icon right v-text="'mdi-chevron-right'" />
      </ib-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    serverItemsLength: {
      type: Number,
      default: undefined
    },
    options: {
      type: Object,
      default: () => ({
        page: 1,
        itemsPerPage: 10
      })
    },
  },
  data() {
    return {
    }
  },
  computed: {
    innerOptions: {
      get() {
        return this.options;
      },
      set(value) {
        this.$emit('update:options', value);
      }
    },
    innerPage: {
      get() {
        return this.options.page;
      },
      set(value) {
        this.$set(this.options, 'page', value);
      }
    },
    totalPage() {
      return Math.ceil(this.serverItemsLength / this.options.itemsPerPage) || 0;
    }
  },
}
</script>


<style lang="scss">
.ib-data-table {
  th {
    background-color: var(--v-gray25-base);
    border-bottom-color: var(--v-gray100-base) !important;
  }

  .v-pagination {
    .v-pagination__item {
      background-color: white;
      color: var(--v-gray950-base);
      height: 40px;
      box-shadow: none;
      border: 1px solid var(--v-gray50-base);
    }

    .v-pagination__item--active {
      background-color: var(--v-gray50-base);
      color: var(--v-gray600-base);
    }

    .v-pagination__navigation {
      display: none;
    }

    .ib-btn {
      border: 1px solid var(--v-gray75-base);
      height: 40px;
    }
  }
}
</style>
