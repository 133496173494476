<template>
  <div class="menu-page">
    <v-card class="nd-border" flat>
      <v-card-title>
        Tags ou rótulos
      </v-card-title>

      <v-card-subtitle>
        Crie tags para organizar seus contatos de forma rápida e eficiente. <ib-btn small @click="showVideo = true"><v-icon size="16" class="mr-1">mdi-help-circle-outline</v-icon>Saiba mais</ib-btn>
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-spacer></v-spacer>
            <ib-btn color="primary" @click.native="openNew">
              <v-icon left>mdi-plus</v-icon>
              Nova tag
            </ib-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="objects" :loading="loading">
              <template #[`item.color`]="{ item }">
                <div class="color-preview" :style="`background-color:${item.color}`" />
              </template>

              <template #[`item.actions`]="{ item }">
                <v-menu>
                  <template #activator="{ on, attrs }">
                    <ib-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </ib-btn>
                  </template>

                  <v-card>
                    <v-list-item @click="openObject(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-file-edit-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="confirmDeleteObject(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Apagar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <ib-dialog v-model="dialog" scrollable max-width="600" persistent>
      <v-card>
        <ib-dialog-header title="Tag ou rótulo" subtitle="Crie ou modifique suas tags para organizar os atendimentos de forma simplificada e eficiente" :close="() => { dialog = false }"></ib-dialog-header>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <ib-text-field v-model="object.name" class="pt-1" label="Nome da tag ou rótulo" outlined hide-details="auto" />
            </v-col>
            <v-col cols="12">
              <v-switch v-model="object.auto_tag" inset label="Associar automaticamente com palavras-chave" />
            </v-col>

            <v-col v-if="object.auto_tag" cols="12">
              <ib-text-field v-model="new_keyword" class="pt-1" label="Nova palavra-chave" placeholder="Digite a aperte ENTER" outlined hide-details="auto" @keyup.enter="addItem(new_keyword)" />
            </v-col>

            <v-col v-if="object.auto_tag" cols="12">
              <label>Palavras-chave enviadas pelo contato que irão associá-lo com a tag {{ object.name }}</label>
              <div>
                <ib-chip v-for="(item, index) in object.keywords" :key="index" dark close class="primary mr-2 my-2" @click:close="object.keywords.splice(index, 1)">
                  {{ item }}
                </ib-chip>

                <ib-chip v-if="!object.keywords || object.keywords.length == 0" small class="warning">
                  Crie pelo menos uma palavra-chave
                </ib-chip>
              </div>
            </v-col>

            <v-col cols="12" class="d-flex">
              <h3 class="mr-2">
                Cor de identificação
              </h3>
              <input v-model="object.color" type="color">
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <ib-btn outlined color="gray200" @click.native="dialog = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn color="primary" :loading="loading" @click.native="save">
            Salvar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>


    <ib-dialog v-model="deleteDialog" overlay-opacity="0.5" overlay-color="black" max-width="350">
      <v-card>
        <ib-dialog-header title="Apagar tag" :subtitle="`Tem certeza que deseja apagar a tag ${object.name}?`" :close="() => { deleteDialog = false }"></ib-dialog-header>

        <v-card-actions>
          <ib-btn outlined color="gray200" @click.native="deleteDialog = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn color="error" @click="deleteDialog = false; deleteObject()">
            Apagar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <video-dialog v-model="showVideo" videoUrl="https://www.youtube.com/embed/tX77sJgITTA?rel=0" />
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import VideoDialog from "@/components/VideoDialog.vue";

export default {
  name: "MensagensRapidasView",

  components: {
    VideoDialog
  },

  data() {
    return {
      showVideo: false,
      objects: [],
      object: { color: '#00FF00', keywords: [] },
      new_keyword: '',
      loading: true,
      dialog: false,
      deleteDialog: false,
      headers: [
        { text: 'Nome', value: 'name', sortable: false },
        { text: 'Cor', value: 'color', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false },
      ]
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },


  async mounted() {
    await this.getObjects();
  },

  methods: {
    async getObjects() {
      this.loading = true;
      const resp = await Api.getTagsByUser();
      this.loading = false;

      if (!resp.error && resp.message) {
        this.objects = resp.message;
      }
    },

    openNew() {
      this.object = { color: '#00FF00', keywords: [] };
      this.dialog = true;
    },

    confirmDeleteObject(item) {
      this.object = item;
      this.deleteDialog = true;
    },

    async deleteObject() {
      this.object.filed = true;
      const resp = await Api.createOrUpdateTag(this.object);
      if (!resp.error && resp.message) {
        emitToastr("success", "Tag apagada com sucesso");
        this.object = {};
        this.getObjects();
      }
    },

    openObject(item) {
      this.object = item;
      this.dialog = true;
    },

    addItem(item) {
      if (!item || item == '') return emitToastr("error", "Palavra-chave necessária");
      if (this.object.keywords.find(k => k.toLowerCase() == item.toLowerCase())) return emitToastr("error", "Palavra-chave já cadastrada");
      this.object.keywords.push(item);
      this.new_keyword = "";
    },

    async save() {
      if (!this.object.name) return emitToastr("error", "O nome é um campo necessário");
      if (!this.object.color) return emitToastr("error", "A cor é um campo necessário");
      if (this.object.auto_tag && (!this.object.keywords || this.object.keywords.length == 0)) return emitToastr("error", "Sem palavra-chave cadastrada. Desative a opção de associar automaticamente com palavras-chave ou cadastre ao menos uma.");

      if (!this.object.auto_tag) this.object.keywords = [];

      if (this.new_keyword != '') this.addItem(this.new_keyword);

      this.loading = true;
      const resp = await Api.createOrUpdateTag(this.object);
      this.loading = false;

      if (!resp.error) {
        emitToastr("success", "Tag salva com sucesso");
        this.getObjects();
        this.dialog = false;
      }
    }
  },
};
</script>

<style scoped>
.color-preview {
  width: 80px;
  height: 25px;
  border-radius: 5px;
}
</style>