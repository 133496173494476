<template>
  <v-dialog v-model="dialog.show" max-width="500">
    <v-card>
      <ib-dialog-header :title="dialog.title" :close="disagree"></ib-dialog-header>
      <v-card-text v-html="dialog.message" />
      <v-card-actions>
        
        <ib-btn color="gray200" outlined @click="disagree">
          <div class="gray1000--text">
              Cancelar
            </div>
        </ib-btn>
        <v-spacer />
        <ib-btn color="primary" @click="agree">
          Confirmar
        </ib-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Global component to show a confirmation dialog
 */
export default {
  computed: {
    dialog: {
      get() {
        return this.$store.state.dialog
      },
      set(value) {
        this.$store.commit('setDialog', value)
      }
    }
  },
  watch: {
    dialog: {
      handler() {
        if (!this.dialog.show && this.dialog.resolve) {
          this.dialog.resolve(false);
        }
      },
    }
  },
  methods: {
    agree() {
      this.dialog.show = false;

      if (this.dialog.resolve) {
        this.dialog.resolve(true);
      }
    },
    disagree() {
      this.dialog.show = false;

      if (this.dialog.resolve) {
        this.dialog.resolve(false);
      }
    }
  }
}
</script>