<template>
    <v-chip v-bind="$attrs" class="ib-chip" :color="color" :class="{ 'ib-chip--tonal': tonal }" :style="{
        '--h': hsl.h,
        '--s': hsl.s + '%',
        '--l': hsl.l + '%'
    }" v-on="$listeners">
        <slot />
    </v-chip>
</template>

<script>
import { hexToHSL } from '@/lib/Utils';

export default {
    props: {
        tonal: {
            type: Boolean,
            default: true
        },
        color: {
            type: String,
            default: 'gray100'
        }
    },
    computed: {
        hsl() {
            if (this.color.startsWith('hsl')) {
                return this.color.replace('hsl', '').replace(')', '').replace(', ')
            }

            if (this.color.startsWith('#')) {
                return hexToHSL(this.color)
            }

            return {
                h: 0,
                s: 0,
                l: 0
            }
        }
    }
}
</script>

<style lang="scss">
.ib-chip {
    --h: 0;
    --s: 0%;
    --l: 0%;

    &.ib-chip--tonal:not(.v-chip--active) {
        background-color: hsla(var(--h), var(--s), var(--l), 0.15) !important;
        color: hsl(var(--h), var(--s), 32%) !important;
    }
}
</style>
