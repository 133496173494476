<template>
  <div class="menu-page pa-5">
    <div>
      <div>
        <span class="section-title mb-2">
          <v-icon size="18" class="mr-2">mdi-clock-time-eight-outline</v-icon>
          Conversas em tempo real
        </span>

        <v-row>
          <v-col cols="12" md="3" lg="3" sm="12" xs="12">
            <v-card class="mx-auto pt-4 pb-4 nd-border" height="170" flat style="background-color: #edfdf3; border: 1px solid #AAF0C7 !important;">
              <v-card-title class="d-flex py-0 flex-column justify-space-between" style="height: 100%;">

                <div class="card-row">
                  <span class="text-h3" style="color: #0A5B39 !important">
                    <animated-number :value="realTimeData.open_conversations?.all || 0" round="1" :duration="600" style="font-weight: 500; font-size: 38px;" />
                  </span>
                  <v-icon class="icon" size="30" color="#0B9055" left>
                    mdi-message-outline
                  </v-icon>
                </div>


                <div right class="div-text subtitle-1 mt-1" style="color: #0B9055 !important; font-weight: 500;">
                  Conversas em andamento
                </div>
              </v-card-title>
            </v-card>
          </v-col>

          <v-col cols="12" md="3" lg="3" sm="12" xs="12">
            <v-card class="mx-auto pt-4 pb-4 nd-border" height="170" flat style="background-color: #FFEBEE; border: 1px solid #FFCDD2 !important;">
              <v-card-title class="d-flex py-0 flex-column justify-space-between" style="height: 100%;">

                <div class="card-row">
                  <span class="text-h3" style="color: #EF5350 !important">
                    <animated-number :value="realTimeData.open_conversations?.waiting_response || 0" round="1" :duration="600" style="font-weight: 500; font-size: 38px;" />
                  </span>
                  <v-icon class="icon" size="30" color="#EF5350" left>
                    mdi-message-text-clock-outline
                  </v-icon>
                </div>


                <div right class="div-text subtitle-1 mt-1" style="color: #EF5350 !important; font-weight: 500;">
                  Aguardando resposta
                </div>
              </v-card-title>
            </v-card>
          </v-col>

          <v-col cols="12" md="3" lg="3" sm="12" xs="12">
            <v-card class="mx-auto pt-4 pb-4 nd-border" height="170" flat style="background-color: #edfdf3; border: 1px solid #AAF0C7 !important;">
              <v-card-title class="d-flex py-0 flex-column justify-space-between" style="height: 100%;">

                <div class="card-row">
                  <span class="text-h3" style="color: #0A5B39 !important">
                    <animated-number :value="realTimeData.open_conversations?.chatbot || 0" round="1" :duration="600" style="font-weight: 500; font-size: 38px;" />
                  </span>
                  <v-icon class="icon" size="30" color="#0B9055" left>
                    mdi-robot-outline
                  </v-icon>
                </div>


                <div right class="div-text subtitle-1 mt-1" style="color: #0B9055 !important; font-weight: 500;">
                  Atendimento chatbot
                </div>
              </v-card-title>
            </v-card>
          </v-col>

          <v-col cols="12" md="3" lg="3" sm="12" xs="12">
            <v-card class="mx-auto pt-4 pb-4 nd-border" height="170" flat style="background-color: #FFF3E0; border: 1px solid #FFE0B2 !important;">
              <v-card-title class="d-flex py-0 flex-column justify-space-between" style="height: 100%;">

                <div class="card-row">
                  <span class="text-h3" style="color: #F57C00 !important">
                    <animated-number :value="realTimeData.open_conversations?.manual || 0" round="1" :duration="600" style="font-weight: 500; font-size: 38px;" />
                  </span>
                  <v-icon class="icon" size="30" color="#FB8C00" left>
                    mdi-face-agent
                  </v-icon>
                </div>


                <div right class="div-text subtitle-1 mt-1" style="color: #FB8C00 !important; font-weight: 500;">
                  Atendimento humano
                </div>
              </v-card-title>
            </v-card>
          </v-col>

        </v-row>
      </div>

      <div>
        <span class="section-title mt-6 mb-2">
          <v-icon size="18" class="mr-2">mdi-chart-bar</v-icon>
          Relatórios
        </span>
      </div>

      <div>
        <v-row class="mb-1">
          <v-col cols="12">
            <ib-select v-model="selected_chip" class="chip-select mr-2 mb-2" color="primary" :items="chips" item-text="name" item-value="_id" outlined hide-details @change="updateReports(interval)" />
            <ib-btn color="primary" class="mr-2 mb-2 mt-1" :outlined="interval != 1" :loading="loading" @click.native="getReports(1)">
              Hoje
            </ib-btn>
            <ib-btn color="primary" class="mr-2 mb-2 mt-1" :outlined="interval != 7" :loading="loading" @click.native="getReports(7)">
              7 dias
            </ib-btn>
            <ib-btn color="primary" class="mr-2 mb-2 mt-1" :outlined="interval != 30" :loading="loading" @click.native="getReports(30)">
              30 dias
            </ib-btn>
            <ib-btn color="primary" class="mr-2 mb-2 mt-1" :outlined="interval != 90" :loading="loading" @click.native="getReports(90)">
              3 meses
            </ib-btn>

            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
              <template #activator="{ on, attrs }">
                <ib-btn color="primary" class="mr-2 mb-2 mt-1" :outlined="(typeof (interval) != 'object')" :loading="loading" v-bind="attrs" v-on="on">
                  Intervalo personalizado
                </ib-btn>
              </template>
              <v-date-picker v-model="date" locale="pt-BR" range no-title scrollable>
                <v-spacer />
                <ib-btn color="gray200" outlined @click="menu = false">
                  <div class="gray1000--text">
                    Cancelar
                  </div>
                </ib-btn>
                <ib-btn color="primary" @click="saveDate(date)">
                  OK
                </ib-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col cols="12" lg="9" md="8" sm="12">
          <v-row>
            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
              <v-card class="mx-auto pt-4 pb-4 nd-border" height="170" flat>
                <v-card-title class="d-flex py-0 flex-column justify-space-between" style="height: 100%;">
                  <div class="card-row">
                    <span class="text-h3">
                      <animated-number :value="conversation_cards?.all || 0" round="1" :duration="600" />
                    </span>
                    <v-icon class="icon" size="30" color="#0B9055" left>
                      mdi-message-outline
                    </v-icon>

                  </div>

                  <div right class="div-text subtitle-1 right mt-1">
                    Novas conversas
                  </div>
                </v-card-title>
              </v-card>
            </v-col>

            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
              <v-card class="mx-auto pt-4 pb-4 nd-border" height="170" flat>
                <v-card-title class="d-flex py-0 flex-column justify-space-between" style="height: 100%;">
                  <div class="card-row">
                    <span class="text-h3">
                      <animated-number :value="conversation_cards?.chatbot || 0" round="1" :duration="600" />
                    </span>
                    <v-icon class="icon" size="30" color="#0B9055" left>
                      mdi-robot-outline
                    </v-icon>

                  </div>

                  <div right class="div-text subtitle-1 right mt-1">
                    Atendidas por chatbot
                  </div>
                </v-card-title>
              </v-card>
            </v-col>

            <v-col cols="12" md="4" lg="4" sm="12" xs="12">
              <v-card class="mx-auto pt-4 pb-4 nd-border" height="170" flat>
                <v-card-title class="d-flex py-0 flex-column justify-space-between" style="height: 100%;">
                  <div class="card-row">
                    <span class="text-h3">
                      <animated-number :value="conversation_cards?.manual || 0" round="1" :duration="600" />
                    </span>
                    <v-icon class="icon" size="30" color="#FB8C00" left>
                      mdi-face-agent
                    </v-icon>
                  </div>

                  <div right class="div-text subtitle-1 right mt-1">
                    Atendidas por humano
                  </div>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col cols="12">
              <v-card class="nd-border" flat>
                <v-card-title>
                  Evolução das conversas
                </v-card-title>
                <v-card-text>
                  <div id="chart1">
                    <apexChart ref="chart1" height="350" :type="chart_type" :options="chart1Options" :series="series1" />
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="mt-4">
            <v-col cols="12">
              <v-card class="nd-border" flat>
                <v-card-title>
                  Evolução das conversas por departamento
                </v-card-title>
                <v-card-text>
                  <div id="chart2">
                    <apexChart ref="chart2" height="350" type="area" :options="chart1Options" :series="series2" />
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" lg="3" md="4" sm="12">
          <v-card class="fill-height nd-border" flat>
            <v-card-title>Últimos contatos</v-card-title>
            <v-card-text>
              <v-list>
                <v-list-item-group color="primary">
                  <v-list-item v-for="(item, i) in realTimeData.last_contacts" :key="i">
                    <v-list-item-icon class="my-2">
                      <ib-user-avatar :user="item" size="50" color="gray75" />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.override_name || item.name" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="6" md="6" sm="12">
          <v-card class="fill-height nd-border" flat>
            <v-card-title>Distribuição por departamento</v-card-title>
            <v-card-text>
              <div id="chart3">
                <apexChart ref="chart3" type="donut" height="400" :options="chart3Options" :series="series3" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col v-if="selected_chip == -1" cols="12" lg="6" md="6" sm="12">
          <v-card class="fill-height nd-border" flat>
            <v-card-title>Distribuição por tags</v-card-title>
            <v-card-text>
              <div id="chart4">
                <apexChart ref="chart4" type="donut" height="400" :options="chart4Options" :series="series4" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import AnimatedNumber from "animated-number-vue";

export default {
  name: "DashboardView",

  components: { AnimatedNumber },

  data() {
    return {
      loading: false,
      interval: 7,

      menu: false,
      date: null,
      chips: [],
      selected_chip: -1,

      conversation_cards: { all: 0, chatbot: 0, manual: 0 },

      series1: [],
      series2: [],
      series3: [],
      series4: [],

      departments_labels: [],

      chart_type: 'bar',

      chart1Options: {
        grid: { show: false },
        chart: {
          type: 'line',
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10
          }
        },
        dataLabels: {
          enabled: true
        },
        legend: {
          position: 'top',
          fontSize: '14px',
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [""],
          convertedCatToNumeric: false,
          labels: {
            formatter: function (val) {
              return new Date(`${val} 12:00:00`).toLocaleDateString('pt-BR');
            },
            style: {
              colors: "#000",
            },
          },
        },
        yaxis: {
          title: {
            text: "Conversas",
            style: {
              color: "#000",
            },
          },
          labels: {
            style: {
              colors: "#FFFFFF",
            },
          },
        },
      },

      chart3Options: {
        labels: [],
        grid: { show: false },
        dataLabels: {
          enabled: true
        },
        legend: {
          position: 'top',
          fontSize: '14px',
        },
      },

      chart4Options: {
        labels: [],
        grid: { show: false },
        dataLabels: {
          enabled: true
        },
        legend: {
          position: 'top',
          fontSize: '14px',
        },
      },
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },

    realTimeData() {
      return this.$store.state.realtime.data;
    },

    getIntervalName() {
      switch (this.interval) {
        case 1:
          return 'Hoje'
        case 7:
          return '7 dias'
        case 30:
          return '30 dias'
        case 90:
          return '3 meses'
        case 180:
          return '6 meses'
        case 365:
          return '1 ano'

        default:
          return 'Intervalo personalizado'
      }
    },
  },

  async mounted() {
    if (this.currentUser?.access_restrictions?.includes('dashboard') || this.currentUser?.user_role === 'user') {
      emitToastr("error", "Permissões insuficientes.");
      this.$router.replace({ name: 'Atendimento' });
    }

    this.$store.dispatch('realtime/load', this.selected_chip);

    this.getReports(this.interval);
    let chips = [{ name: "Todos os canais", _id: -1 }];

    const resp = await Api.getChipNumbersByUser();

    if (resp.message) {
      resp.message.forEach(c => {
        chips.push({ name: c.name, _id: c._id.toString() })
      })
    }
    this.chips = chips;
  },

  methods: {
    saveDate(date) {
      this.menu = false;
      this.getReports(date);
    },

    updateReports(interval) {
      this.getReports(interval);
      this.$store.dispatch('realtime/load', this.selected_chip);
    },

    async getReports(interval) {
      this.loading = true;
      this.interval = interval;
      this.chart_type = interval > 7 ? 'area' : 'bar';
      const resp = await Api.getDashboardData(this.interval, this.selected_chip);
      this.loading = false;

      if (resp && resp.error) {
        return emitToastr("error", resp.message)
      }

      if (resp && resp.message) {
        if (resp.message.conversation_cards) this.conversation_cards = resp.message.conversation_cards;

        if (resp.message.conversation_chart) {
          let cat = [];
          let serie1 = { name: "Total de conversas", data: [] };
          let serie2 = { name: "Atendimento chatbot", data: [] };
          let serie3 = { name: "Atendimento humano", data: [] };

          resp.message.conversation_chart.forEach(i => {
            cat.push(i._id);
            serie1.data.push(i.all);
            serie2.data.push(i.chatbot);
            serie3.data.push(i.manual);
          });

          this.series1 = [serie1, serie2, serie3];
          this.chart1Options.xaxis.categories = cat;
          if (this.$refs.chart1) this.$refs.chart1.refresh();
        }


        if (resp.message.conversation_chart_departments) {
          let series2 = [];

          resp.message.conversation_chart_departments.forEach(i => {
            Object.keys(i).forEach(key => {
              if (key != "_id") {
                if (!series2.find(s => s.name == key)) series2.push({ name: key, data: [] });
                series2.find(s => s.name == key).data.push(i[key]);
              }
            })
          });

          this.series2 = series2;
          if (this.$refs.chart2) this.$refs.chart2.refresh();
        }

        if (resp.message.department_distribution) {
          let series3 = [];
          this.chart3Options.labels = [];

          resp.message.department_distribution.forEach(i => {
            if (i.name) {
              this.chart3Options.labels.push(i.name);
              series3.push(i.all);
            }
          });

          this.series3 = series3;
          if (this.$refs.chart3) this.$refs.chart3.refresh();
        }

        if (resp.message.tag_distribution) {
          let series4 = [];
          this.chart4Options.labels = [];

          resp.message.tag_distribution.forEach(i => {
            if (i.name) {
              this.chart4Options.labels.push(i.name);
              series4.push(i.all);
            }
          });

          this.series4 = series4;
          if (this.$refs.chart4) this.$refs.chart4.refresh();
        }
      }
    }
  },
};
</script>

<style scoped>
.section-title {
  display: flex;
  flex-direction: row;
  align-content: center;
  font-size: 16px;
  font-weight: 600;
  color: var(--v-gray600-base);
}

.mx-auto {}

.icon {
  font-size: 40px;
}

.card-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.chip-select {
  max-width: 250px;
  display: inline-flex;
}

.div-text {
  width: 100%;
  text-align: left;
}

.card-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right {
  justify-content: flex-end !important;
}

.text-h3 {
  color: rgb(53, 53, 53);
}
</style>
