<template>
  <svg :width="size" :height="size" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.2726 0H19.6649C25.2693 0 29.8125 4.52061 29.8125 10.125C29.8059 13.1434 28.4876 15.7434 26.6528 17.4726L25.0361 19.0839C24.8816 19.2378 24.9149 19.4963 25.0999 19.6119C27.7263 21.2517 29.7893 23.7394 29.7893 27.0604C29.7893 32.2644 25.5706 36 20.3664 36H8.18146C7.08023 36 6.1875 35.1073 6.1875 34.006V18.8965V1.99396C6.1875 0.89273 7.08023 0 8.18146 0H11.2726ZM14.688 17.8343C14.7287 17.6289 14.5715 17.4375 14.362 17.4375H11.2726H10.3617C9.26047 17.4375 8.36774 16.5448 8.36774 15.4435V10.125V4.80646C8.36774 3.70523 9.26047 2.8125 10.3617 2.8125H11.2726H19.6649C23.6602 2.76856 27 6.04688 27 10.125C27 12.2908 26.0354 14.2054 24.6774 15.471L13.5947 26.4962C13.3622 26.7275 12.9707 26.5179 13.0343 26.1961L14.688 17.8343Z" fill="currentColor" />
  </svg>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: '100%'
    }
  }
}
</script>
