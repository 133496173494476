<template>
  <ib-dialog v-model="dialog" width="720" scrollable>
    <v-card>
      <ib-dialog-header title="Agendamento de envio" subtitle="Envie mensagens individualmente por aqui. Você pode disparar imediatamente ou agendar o envio." :close="closeDialog"></ib-dialog-header>

      <v-card-text>
        <v-row class="pt-1">
          <v-col cols="12" lg="8" md="8" sm="12">
            <v-row>
              <v-col cols="4">
                <ib-text-field v-model="country_code" outlined label="Cód País" hide-details="auto" />
              </v-col>
              <v-col cols="8">
                <ib-text-field v-model="object.to" label="Número destino" placeholder="Insira um número com DDI e DDD, exemplo: 5511955555555" outlined hide-details="auto" />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" lg="4" md="4" sm="12">
            <ib-select v-model="object.chip" clearable item-text="name" item-value="_id" :items="chips" label="Chip de envio" outlined hide-details="auto" />
          </v-col>

          <v-col cols="12">
            <ib-select v-model="object.type" clearable item-text="name" item-value="id" :items="types" label="Tipo de conteúdo" outlined hide-details="auto" />
          </v-col>

          <v-col v-if="object.type == 'flow'" cols="12">
            <ib-autocomplete v-model="object.flow" clearable item-text="name" item-value="_id" :items="flows" label="Fluxo" outlined hide-details="auto" />
          </v-col>

          <v-col v-if="object.type == 'text'" cols="12">
            <v-textarea class="nd-text-field" v-model="object.message" rows="4" label="Texto da mensagem" placeholder="Escreva aqui a mensagem de texto que será enviada." outlined hide-details="auto" messages="Mensagens do tipo texto serão enviadas, mas só criarão uma nova conversa na plataforma após a resposta do contato (para evitar que o chatbot interfira em conversas pessoais iniciadas por você diretamente no celular)" />
          </v-col>

          <v-col cols="12">
            <label class="mb-2"><b>Quando a mensagem deverá ser enviada?</b></label>

            <v-datetime-picker v-model="object.scheduledTo" label="Data e hora do envio" date-format="dd/MM/yyyy" :date-picker-props="{ locale: 'pt-BR' }" :time-picker-props="{ format: '24hr' }" :text-field-props="{ outlined: true }">
              <template slot="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </template>

              <template slot="timeIcon">
                <v-icon>mdi-clock-outline</v-icon>
              </template>

              <template slot="actions" slot-scope="{ parent }">
                <ib-btn color="gray200" outlined @click.native="parent.clearHandler">
                  <div class="gray1000--text">
                    Cancelar
                  </div>
                </ib-btn>
                <ib-btn color="primary" @click="parent.okHandler">
                  OK
                </ib-btn>
              </template>
            </v-datetime-picker>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>

        <ib-btn outlined color="gray200" @click="closeDialog">
          <div class="gray1000--text">
            Cancelar
          </div>
        </ib-btn>

        <v-spacer />

        <ib-btn color="primary" :loading="loading" @click="createNew">
          Salvar
        </ib-btn>
      </v-card-actions>
    </v-card>
  </ib-dialog>
</template>

<script>
import Api from '@/lib/Api';
import { emitToastr, onlyNumbers } from '@/lib/Utils';

export default {
  props: {
    value: Boolean,
    chips: Array,
    phone: String,
    allMessage: Object,
    callback: Function
  },
  data() {
    return {
      loading: false,
      object: {},
      flows: [],
      country_code: '55',
      types: [
        { id: 'text', name: 'Texto' },
        { id: 'flow', name: 'Fluxo' },
      ]
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          if (!this.allMessage) {
            this.object.to = this.phone;
          }
          else {
            let data = this.allMessage
            if (data.scheduledTo) data.scheduledTo = new Date(data.scheduledTo);
            this.object = data;
          }

          if (this.chips.length == 1) this.object.chip = this.chips[0]._id;
        }
      },
    }
  },
  async mounted() {
    this.flows = [];
    const resp = await Api.getFlowsByUser();
    if (!resp.error) this.flows = resp.message;
  },
  methods: {
    clear() {
      this.loading = false;
    },

    closeDialog() {
      this.clear();
      this.dialog = false;
    },

    async createNew() {
      if (!this.object.chip) return emitToastr("error", "Chip de envio necessário");
      if (!this.object.to) return emitToastr("error", "Número de destino necessário");
      if (!this.object.scheduledTo) return emitToastr("error", "Data e hora do agendamento necessárias");

      if (!onlyNumbers(this.object.to)) return emitToastr("error", "Número de telefone inválido.");
      if (!onlyNumbers(this.country_code)) return emitToastr("error", "Código do país inválido.");

      if (this.object.type == 'text' && !this.object.message) return emitToastr("error", "Texto da mensagem necessário");
      if (this.object.type == 'flow' && !this.object.flow) return emitToastr("error", "Fluxo necessário");

      this.loading = true;
      let resp;

      this.object.to = this.country_code + this.object.to;

      if (this.object._id) {
        resp = await Api.updateScheduledMessage(this.object);
      }
      else {
        resp = await Api.createScheduledMessage(this.object);
      }

      this.loading = false;

      if (!resp.error) {
        this.object = { to: "", message: "" };
        emitToastr("success", "Agendamento cadastrado com sucesso.");
        if (this.callback) this.callback();
        this.closeDialog();
      }
    },
  }
};
</script>

<style></style>