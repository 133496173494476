import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from '@/store';
import VueMask from "v-mask";
import "@/assets/global.css";
import "@/assets/utils.scss";
import '@/assets/components.scss';
import VueApexCharts from "vue-apexcharts";
import DatetimePicker from 'vuetify-datetime-picker';

import './plugins/components'
import './plugins/globals'

Vue.config.productionTip = false;
Vue.use(VueMask);
Vue.use(VueApexCharts);
Vue.component("ApexChart", VueApexCharts);
Vue.use(DatetimePicker);

const app = new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
})

app.$mount("#app");
