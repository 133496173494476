<template>
  <v-switch
    v-bind="$attrs"
    class="ib-switch"
    inset
    v-on="$listeners"
  >
    <slot />
  </v-switch>
</template>

<script>
export default {
    inheritAttrs: false
}
</script>

<style lang="scss">
.ib-switch {

    &.v-input--switch--inset {
        .v-input--switch__track {
            opacity: 1;
            color: var(--v-gray100-base);
        }

        .v-input--switch__thumb {
            color: white !important;
        }
    }
}
</style>
