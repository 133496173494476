<template>
  <div class="login_container fade">
    <div class="left">
      <div class="left-header">
        <v-img class="logo mt-2" src="@/assets/logo2.png" />

        <v-alert v-if="showInstructions" icon="mdi-information-outline" dense class="first-access-alert green lighten-4 mt-4">
          <b>Tudo certo com a sua compra!</b> <br>Seu acesso será liberado em até 3 minutos. No primeiro acesso, utilize o <b>mesmo email da compra. Sua senha inicial é CPF ou CNPJ</b> da compra, somente números, sem pontos, traços ou barras.
        </v-alert>
      </div>

      <div v-if="mode === 'login'" class="login-form px-4 fade">
        <v-row>
          <v-col cols="12" class="text-center">
            <h1>Entre na sua conta</h1>
            <span>Insira seus dados abaixo.</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="email" class="nd-text-field" color="green700" label="Digite seu e-mail" placeholder="Digite seu e-mail" outlined hide-details="auto" />
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="password" class="nd-text-field" color="green700" :type="showPassword ? 'text' : 'password'" label="Digite sua senha" placeholder="Digite sua senha" @keydown.enter="login" outlined hide-details="auto" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" />
          </v-col>

          <v-col cols="12">
            <v-btn class="nd-button-large" height="50" dark large block color="green600" @click="login">
              Fazer login
            </v-btn>
          </v-col>

          <v-col cols="12" class="d-flex align-center justify-sm-end">
            <div small text class="cursor-pointer text-none text-caption" :loading="loading" @click="mode = 'forgot_password'">
              Esqueceu sua senha?
            </div>
          </v-col>
        </v-row>
      </div>

      <div v-if="mode === 'forgot_password'" class="login-form px-4 fade">
        <v-row>
          <v-col cols="12" class="text-center">
            <h1>Esqueceu sua senha?</h1>
            <span>Insira seu e-mail e verifique sua caixa de entrada para mudar a sua senha.</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="email" class="nd-text-field" color="green700" label="Digite seu e-mail" placeholder="Digite seu e-mail" outlined hide-details="auto" />
          </v-col>

          <v-col cols="12">
            <v-btn class="nd-button-large" height="50" dark large block color="green600" :loading="loading" @click="resetPassword">
              Recuperar acesso
            </v-btn>
          </v-col>

          <v-col cols="12" class="d-flex align-center justify-sm-end">
            <div small text class="cursor-pointer text-none text-caption" @click="mode = 'login'">
              Voltar para o <b>login</b>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="bottom-text px-4">
        <span style="text-align: left;">© 2025 ∙ Inteligência Bot</span>
        <span style="text-align: right;">Todos os direitos reservados</span>
      </div>
    </div>

    <div class="right">
      <v-img class="login-image" src="@/assets/login_image.png" />
    </div>
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
  name: "LoginView",
  data() {
    return {
      // mode
      mode: "login",
      loading: false,

      // Form
      email: "",
      password: "",
      showPassword: false,
      showInstructions: false
    };
  },
  mounted() {
    if (this.$route.name === 'welcome') this.showInstructions = true;
  },
  methods: {
    async login() {
      if (!this.email || !this.password) return emitToastr('error', 'Por favor, preencha todos os campos.');
      this.loading = true;
      const response = await Api.login(this.email, this.password, true);
      this.loading = false;

      if (response.error) {
        this.$toast('error', response.message);
        return
      }

      this.$router.push("/");
    },
    async resetPassword() {
      if (!this.email) return emitToastr('error', 'Por favor, preencha todos os campos.');

      this.loading = true;
      const resp = await Api.recoveryPassword(this.email);
      this.loading = false;

      if (resp && !resp.error) {
        emitToastr('success', 'Email enviado com sucesso! Verifique sua caixa de entrada, incluindo a pasta de Spam.');
        this.mode = 'login';
        return;
      }

      if (resp && resp.error && resp.error.message) {
        emitToastr('error', resp.error.message)
        return;
      }
    }
  },
};
</script>

<style scoped>
.login_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 100%;
  align-content: center;
  align-items: center;
  background: white;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 16px;
}

.left-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.first-access-alert {
  max-width: 500px;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: white;
  padding: 16px;
}

.login-form {
  max-width: 450px;
}

.login-image {
  max-height: calc(100vh - 32px);
  max-width: 50vw;
  border-radius: 6px;
}

.bottom-text {
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo {
  max-width: 350px;
}

@media only screen and (max-width: 900px) {
  .login_container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    height: 100%;
    align-content: center;
    align-items: center;
    background: white;
  }

  .right {
    padding: 0px;
    display: none;
  }

  .login-image {
    display: none;
  }

  .logo {
    max-width: 270px;
  }
}
</style>