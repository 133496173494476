<template>
  <div class="menu-page">
    <div>

      <div class="mx-4 mb-4">
        <ib-btn class="primary" @click="filter_sheet = true">
          <v-icon left>mdi-filter-outline</v-icon>
          Buscar ou filtrar
        </ib-btn>
      </div>

      <v-card class="nd-border pa-2" flat>
        <ib-data-table :headers="headers" :items="objects" :options.sync="options" :server-items-length="totalObjects" :loading="loading">
          <template #[`item.created`]="{ item }">
            {{ $date.defaultFormat(item.created) }}
          </template>

          <template #[`item.finished`]="{ item }">
            {{ item.finished ? $date.defaultFormat(item.finished) : 'Em andamento' }}
          </template>

          <template #[`item.chatbot`]="{ item }">
            {{ `${item.chip?.name || 'Canal apagado'} - ${item.chatbot?.name || 'Chatbot apagado'}` }}
          </template>

          <template #[`item.contact.tags`]="{ item }">
            <ib-chip v-for="(tag, index_tag) in item.contact?.tags" v-show="tags_dictionary[tag]" :key="`tag_${index_tag}`" small class="ma-1" :color="tags_dictionary[tag]?.color">
              {{ tags_dictionary[tag]?.name }}
            </ib-chip>
          </template>


          <template #[`item.contact.name`]="{ item }">
            {{ item.contact?.override_name || item.contact?.name }}
          </template>

          <template #[`item.department`]="{ item }">
            <ib-chip v-if="item.department" small>
              <v-icon small left>
                mdi-briefcase-outline
              </v-icon>{{ item.department?.name || 'Departamento apagado' }}
            </ib-chip>
          </template>

          <template #[`item.contact.profile_picture`]="{ item }">
            <ib-user-avatar class="my-2" :user="item.contact" size="60" color="gray75" />
          </template>

          <template #[`item.contact.phone`]="{ item }">
            {{ $utils.formatPhone(item.contact?.phone) }}
          </template>

          <template #[`item.actions`]="{ item }">
            <v-menu>
              <template #activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-list-item @click="transferDepartment(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-face-agent</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Enviar para atendimento humano</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item @click="getMessages(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-eye</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>Ver conversa</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-menu>
          </template>
        </ib-data-table>
      </v-card>

      <v-bottom-sheet v-model="filter_sheet">
        <v-list nav dense>
          <v-list-item class="mb-0">
            <v-list-item-title>Tipo de atendimento</v-list-item-title>
          </v-list-item>
          <v-list-item class="mb-0">
            <v-btn-toggle v-model="pre_filter.types" multiple mandatory dense color="primary">
              <v-btn v-for="(item, index) in types" :key="index" small :value="item">
                <v-icon size="18" class="mr-2">
                  {{ item.icon }}
                </v-icon>
                <span>{{ item.name }}</span>
              </v-btn>
            </v-btn-toggle>
          </v-list-item>

          <v-list-item class="mb-0">
            <v-list-item-title>Departamento</v-list-item-title>
          </v-list-item>

          <v-list-item class="mb-0">
            <ib-autocomplete outlined v-model="pre_filter.departments" :items="user_role_departments" :label="pre_filter.departments?.length ? 'Departamentos selecionados' : 'Todos os departamentos'" prepend-inner-icon="mdi-briefcase-outline" item-text="name" item-value="_id" small-chips return-object clearable multiple hide-details></ib-autocomplete>
          </v-list-item>

          <v-list-item class="mb-0">
            <v-list-item-title>Tags</v-list-item-title>
          </v-list-item>

          <v-list-item class="mb-0">
            <ib-autocomplete outlined v-model="pre_filter.tags" :items="tags" :label="pre_filter.tags?.length ? 'Tags selecionadas' : 'Todas as tags'" prepend-inner-icon="mdi-tag-multiple-outline" item-text="name" item-value="_id" small-chips return-object clearable multiple hide-details></ib-autocomplete>
          </v-list-item>

          <v-list-item class="mb-0">
            <v-list-item-title>Busca individual</v-list-item-title>
          </v-list-item>


          <v-list-item class="mb-2">
            <ib-text-field prepend-inner-icon="mdi-account-outline" v-model="pre_filter.text" outlined label="Nome ou telefone" hide-details="auto" />
          </v-list-item>

          <v-list-item>
            <div class="filter-item my-3" style="display: flex; width: 100%;">
              <v-spacer />
              <ib-btn dark outlined color="error" class="mr-4" @click="clearFilters">
                Limpar filtros
              </ib-btn>
              <ib-btn dark color="primary" @click="applyFilters">
                Aplicar filtros
              </ib-btn>
            </div>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>

    </div>

    <v-dialog v-model="dialogConversation" scrollable max-width="760">
      <v-card>
        <v-card-title class="gray100">
          <div class="contact-container">
            <ib-user-avatar class="my-2" :user="selectedConversation.contact" size="60" color="gray75" />

            <div class="contact-info">
              <span class="contact-name">{{ selectedConversation?.contact?.override_name || selectedConversation?.contact?.name || "Contato" }} <small v-if="selectedConversation?.is_manual">(Atendimento humano)</small></span>
              <span class="contact-phone">{{ $utils.formatPhone(selectedConversation?.contact?.phone) }}</span>
            </div>

            <v-spacer />

            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn large icon color="black" @click="transferDepartment(selectedConversation)">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-face-agent
                  </v-icon>
                </v-btn>
              </template>
              <span>Enviar para atendimento humano</span>
            </v-tooltip>
          </div>
        </v-card-title>

        <v-card-text ref="dialogRef">
          <div class="messages-container flex-grow-1 pa-4">
            <div v-if="!messages || !messages.length" class="d-flex align-center justify-center">Sem mensagens</div>
            <div v-for="(item, message_index) in messages" :id="`message_${message_index}`" :key="`message_${message_index}`" :class="`fade chat-item ${item.origin == 'customer' ? 'customer' : 'chatbot'}-message ${item.deleted ? 'deleted-message' : ''} ${item.type === 'summary' ? 'summary-message' : ''}`" @click="selectedChatItem = item">
              <div v-if="item.deleted" style="font-size: 12px" class="mb-2">
                <i>(Essa mensagem foi apagada)</i>
              </div>

              <div v-if="item.user">
                <b>{{ users[item.user] || 'Colaborador' }}</b>
              </div>

              <div v-if="item.reply_message?.type == 'text'" class="reply-container">
                <v-icon small>
                  mdi-reply-outline
                </v-icon>
                {{ item.reply_message.message }}
              </div>

              <audio v-if="item.type == 'audio' && item.media_url" controls class="mb-2">
                <source :src="item.media_url" type="audio/mpeg">
                Seu navegador não suporte player de audio.
              </audio>
              <span v-if="item.type == 'audio' && item.origin == 'customer'">[TRANSCRIÇÃO]: </span>

              <video v-if="item.type == 'video'" class="video-container" controls="controls">
                <source :src="item.media_url" type="video/mp4">
                <!--Suportado em IE9, Chrome 6 e Safari 5 -->
                O seu navegador não suporta a tag vídeo
              </video>

              <div v-if="item.type == 'image' || (item.type == 'text' && item.media_url)">
                <v-img class="cursor-pointer" :src="item.media_url" contain height="auto" max-width="80vw" @click="showImage(item.media_url)" />
              </div>

              <div v-if="item.type == 'document'" class="cursor-pointer pa-2" @click="openFile(item.media_url)">
                <v-icon size="40">
                  mdi-file-document-outline
                </v-icon>
                <div style="font-weight: 400;" class="mt-2">
                  {{ item.file_name || 'Documento' }}
                </div>
              </div>

              <div v-if="item.canonical_url">
                <b>{{ item.canonical_url }}</b>
              </div>

              <div v-if="item.type === 'summary'">
                <b>RESUMO DA CONVERSA</b> <small>(o contato não vê o resumo)</small>
              </div>

              <div>
                {{ item.message }}
              </div>

              <div class="d-flex align-center">
                <v-spacer />
                <div v-if="item.created" class="message-time">
                  {{ $date.smallFormat(item.created) }}
                  <v-icon v-if="item.origin != 'customer'" size="13" :color="item.status >= 4 ? 'blue' : 'grey'">
                    mdi-check-all
                  </v-icon>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <ib-dialog v-model="departmentDialog" overlay-opacity="0.5" overlay-color="black" max-width="600">
      <v-card>
        <ib-dialog-header title="Classifique e transfira o atendimento" :subtitle="` Transferir atendimento de ${selectedConversation.contact?.override_name || selectedConversation.contact?.name}`" :close="() => { departmentDialog = false }"></ib-dialog-header>

        <v-card-text v-if="selectedConversation?.contact">
          <ib-autocomplete v-model="selectedConversation.contact.tags" chips small-chips multiple outlined :items="tags" item-text="name" item-value="_id" label="Tags ou rótulos" hide-details="auto" @change="changeTags = true">
            <template #selection="{ item }">
              <ib-chip small :color="item.color">
                {{ item.name }}
              </ib-chip>
            </template>
          </ib-autocomplete>
        </v-card-text>

        <v-card-text v-if="selectedConversation">
          <ib-autocomplete v-model="selectedConversation.department" chips small-chips outlined :items="departments" item-text="name" item-value="_id" label="Departamento" hide-details="auto">
            <template #selection="{ item }">
              <ib-chip small color="primary">
                {{ item.name }}
              </ib-chip>
            </template>
          </ib-autocomplete>
        </v-card-text>

        <v-card-actions>
          <ib-btn outlined color="gray200" @click="departmentDialog = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn color="primary" @click="departmentDialog = false; changeConversationData()">
            Transferir
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <v-overlay v-model="imageViewerDialog" z-index="99999" opacity="0.9">
      <v-img :src="media_url" contain style="max-height: 85vh;max-width:85vw" />
      <div style="text-align: center;">
        <ib-btn outlined class="mt-2 mr-4" large color="error lighten-1" @click="imageViewerDialog = false">
          <v-icon class="mr-2">
            mdi-close
          </v-icon>
          Fechar
        </ib-btn>
        <ib-btn class="mt-2" large color="primary" @click="$saveImage(media_url)">
          <v-icon class="mr-2">
            mdi-content-save-outline
          </v-icon>
          Salvar
        </ib-btn>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
  name: "ConversasView",

  data() {
    return {
      filter_sheet: false,
      objects: [],
      totalObjects: 0,
      dialogConversation: false,
      departmentDialog: false,
      imageViewerDialog: null,
      media_url: '',
      changeTags: false,
      selectedConversation: {},
      messages: [],
      tags: [],
      departments: [],
      user_role_departments: [],
      users: {},
      text: "",
      loading: true,
      pre_filter: {
        text: '', changed: false, tags: [], departments: [], finished: { $exists: true },
        types: [
          { name: 'Chatbot', id: 'chatbot', icon: 'mdi-robot-outline' },
          { name: 'Humano', id: 'human', icon: 'mdi-face-agent' }
        ]
      },
      can_clear: false,
      options: { filter: { text: '', finished: { $exists: true } } },
      types: [
        { name: 'Chatbot', id: 'chatbot', icon: 'mdi-robot-outline' },
        { name: 'Humano', id: 'human', icon: 'mdi-face-agent' }
      ],
      tags_dictionary: {},
      headers: [
        { text: 'Foto', value: 'contact.profile_picture', sortable: false },
        { text: 'Nome', value: 'contact.name', sortable: false },
        { text: "Telefone", value: "contact.phone", sortable: false, width: 170 },
        { text: "Canal", value: "chatbot", sortable: false },
        { text: "Tags ou rótulos", value: "contact.tags", sortable: false },
        { text: "Departamento", value: "department", sortable: false },
        { text: 'Início', value: 'created', sortable: false },
        { text: 'Finalização', value: 'finished', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false },
      ]
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },

  watch: {
    options: {
      handler() {
        this.getConversations(this.options);
      },
      deep: true,
    }
  },

  async mounted() {
    const tags_call = Api.getTagsByUser();
    const departments_call = Api.getDepartmentsByUser();
    const users_call = Api.getTeamUsers();

    const promisess = [await tags_call, await departments_call, await users_call];

    const tags = promisess[0];
    const departments = promisess[1];
    const users = promisess[2];

    if (!tags.error && tags.message) this.tags = tags.message;
    this.tags.forEach(i => this.tags_dictionary[i._id] = { name: i.name, color: i.color });

    if (!departments.error && departments.message) {
      this.departments = departments.message;

      if (this.currentUser.user_role == 'user') {
        this.user_role_departments = this.departments.filter(d => (this.currentUser.departments || []).includes(d._id.toString()));
      }
      else {
        this.user_role_departments = this.departments;
      }
    }

    if (!users.error && users.message) {
      this.users = {};
      users.message.forEach(u => { this.users[u._id.toString()] = u.name; })
    }

    this.buildFilter();
  },

  methods: {
    buildFilter() {
      this.options.page = 1;
      this.options.filter.types = this.pre_filter.types;
      this.options.filter.tags = this.pre_filter.tags;
      this.options.filter.text = this.pre_filter.text;

      //TRATANDO DEPARTAMENTOS
      if (this.currentUser.user_role == 'user') {
        if (!this.currentUser.departments) this.currentUser.departments = [];

        if (this.pre_filter.departments.length == 0) {
          this.pre_filter.departments = this.user_role_departments;
        }
      }

      this.options.filter.departments = this.pre_filter.departments;
    },

    async getConversations(options) {
      this.loading = true;
      const resp = await Api.getConversationsByUser(options);
      this.loading = false;

      if (!resp.error && resp.message) {
        this.objects = resp.message.data;
        if (this.pre_filter.tags.length > 0) this.objects = this.objects.filter(i => this.pre_filter.tags.some(j => i.contact?.tags.includes(j._id)));
        this.totalObjects = resp.message.total;
      }
    },

    scrollMessagesToBottom(behavior = 'smooth') {
      const dialogElement = this.$refs.dialogRef;
      dialogElement.scrollTo({ top: dialogElement.scrollHeight + 500, behavior });
    },

    async getMessages(conversation) {
      this.selectedConversation = conversation;
      const resp = await Api.getMessagesByConversation(conversation._id);

      if (!resp.error && resp.message) {
        this.messages = resp.message;
        this.dialogConversation = true;
        setTimeout(() => {
          this.scrollMessagesToBottom('instant');
        }, 100);
      }
    },

    clearFilters() {
      this.pre_filter = {
        text: '', changed: false, tags: [], departments: [],
        types: [
          { name: 'Chatbot', id: 'chatbot', icon: 'mdi-robot-outline' },
          { name: 'Humano', id: 'human', icon: 'mdi-face-agent' }
        ]
      };
      this.can_clear = false;
      this.buildFilter();
      this.getConversations(this.options);
      this.filter_sheet = false;
    },

    applyFilters() {
      this.can_clear = true;
      this.buildFilter();
      this.getConversations(this.options);
      this.filter_sheet = false;
    },

    transferDepartment(item) {
      this.selectedConversation = item;
      this.changeTags = false;
      this.departmentDialog = true;
    },

    async changeContactTags() {
      await Api.changeContactTags(this.selectedConversation.contact, this.selectedConversation._id);
    },

    showImage(media_url) {
      this.media_url = media_url;
      this.imageViewerDialog = true;
    },

    openFile(url) {
      window.open(url, "_blank");
    },

    async changeConversationData() {
      this.loading = true;

      if (this.changeTags) await this.changeContactTags();

      let resp = await Api.changeConversationData(this.selectedConversation);
      if (!resp.error) {
        resp = await Api.resumeConversation(this.selectedConversation._id);
        if (!resp.error) {
          emitToastr("success", "Atendimento retomado com sucesso");
          this.selectedConversation = {};
          this.messages = [];
          this.dialogConversation = false;
          this.getConversations(this.options);
        }
      }

      this.loading = false;
    }
  },
};
</script>

<style scoped>
.filter-menu-title {
  font-weight: 500;
  margin-right: 12px;
}

.contact-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.contact-info {
  display: flex;
  flex-direction: column;
  margin-left: 14px;
}

.contact-name {
  font-size: 18px;
  font-weight: 600;
}

.contact-phone {
  font-size: 14px;
}

.messages-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: calc(100vh - 307px);
  font-size: 14px;
  scrollbar-width: thin !important;
  scrollbar-color: rgba(206, 206, 206, 0.322) transparent !important;
}

.chat-item {
  background-color: white;
  border: 1px solid var(--v-gray100-base);
  color: var(--v-gray600-base);
  max-width: 400px;
  padding: 8px;
  padding-bottom: 4px;
  margin-top: 6px;
  margin-bottom: 6px;
  border-radius: 12px;
  white-space: pre-line;
  word-wrap: break-word;
}

.chatbot-message {
  background-color: var(--v-green100-base);
  border: 1px solid var(--v-green200-base);
  font-weight: 500;
  color: var(--v-green900-base);
  align-self: flex-end;
  word-break: break-word;
}

.customer-message {
  background-color: #ffffff;
  font-weight: 500;
  align-self: flex-start;
  word-break: break-word;
}

.deleted-message {
  background-color: #ffdfdf !important;
  border: 1px solid #ffc9c9
}

.summary-message {
  background-color: #f7cdff !important;
  border: solid 1px #ba68c8 !important
}

.message-time {
  text-align: right;
  font-size: 11px;
}


::v-deep .v-dialog {
  scrollbar-width: thin !important;
  scrollbar-color: rgba(206, 206, 206, 0.322) transparent !important;
}
</style>