<template>
  <div class="menu-page">
    <v-card class="nd-border" flat>
      <v-card-title>
        Audiências
      </v-card-title>

      <v-card-subtitle>
        Importe audiências em planilhas para criar campanhas <ib-btn small @click="showVideo = true"><v-icon size="16" class="mr-1">mdi-help-circle-outline</v-icon>Saiba mais</ib-btn>
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-spacer></v-spacer>
            <ib-btn color="primary" @click.native="dialogCreate = true">
              <v-icon left>mdi-import</v-icon>
              Importar nova audiência
            </ib-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="objects">
              <template #[`item.created`]="{ item }">
                {{ new Date(item.created).toLocaleString('pt-BR').replace(',', ' -') }}
              </template>

              <template #[`item.people`]="{ item }">
                {{ item.people.length }}
              </template>

              <template #[`item.props`]="{ item }">
                {{ item.props && item.props.length && item.props.length > 0 ? item.props.join(' | ') : '' }}
              </template>

              <template #[`item.actions`]="{ item }">
                <v-menu>
                  <template #activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-list-item @click="object = item; dialogCreate = true">
                      <v-list-item-icon>
                        <v-icon>mdi-file-edit-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="object = item; dialog = true">
                      <v-list-item-icon>
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Apagar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <ib-dialog v-model="dialogCreate" max-width="1200">
      <v-card class="pb-2">
        <ib-dialog-header title="Cadastrar ou editar audiência" subtitle="Audiências são grupos de contatos (números de telefone) para os quais você pode enviar mensagens em massa. Inicialmente a audiência é criada a partir da importação de uma planilha." :close="() => { getObjects(); dialogCreate = false }"></ib-dialog-header>

        <v-card-text>
          <v-row>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-file-input v-model="fileToUpload" dense accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" label="Carregue aqui a planilha com a sua audiência" />
              <ib-btn v-if="fileToUpload" large color="primary" class="ml-6" @click="importResults(false)">
                Analisar planilha
              </ib-btn>
            </v-col>


            <v-col v-if="object.people.length > 0" cols="12" lg="6" md="6" sm="12">
              <ib-text-field v-model="object.name" label="Nome da audiência" placeholder="Insira um nome que represente esse público" outlined hide-details="auto" />
            </v-col>



            <v-col v-if="object.people.length > 0" cols="12" lg="6" md="6" sm="12">
              <ib-text-field v-model="object.people.length" :readonly="true" label="Contatos válidos" outlined hide-details="auto" />
            </v-col>

            <v-col v-if="object.people.length > 0" cols="12" lg="6" md="6" sm="12">
              <ib-text-field v-model="object.props" :readonly="true" label="Propriedades reconhecidas" outlined hide-details="auto" />
            </v-col>

            <v-col v-if="object.people.length > 0" cols="12" sm="12">
              <v-switch v-model="object.create_contacts" inset label="Cadastrar audiência como contatos?" />
            </v-col>

            <v-col cols="12">
              <span class="primary--text title">COMO IMPORTAR A PLANILHA?</span>
              <br>
              <div>
                <span><b>Campo obrigatório: <span class="primary--text">telefone</span>. É necessário ter uma coluna na planilha de nome "telefone" sem aspas, coluna essa com os números de telefone dos contatos</b></span>
              </div>
              <div>
                <span><span class="primary--text"><b>-telefone </b></span>número de Whatsapp do contato. Use DDI e DDD, somente números, sem espaços. Exemplo: 5511955555555</span>
              </div>
              <br>
              <span>Quaisquer outras colunas poderão ser utilizadas como variáveis nas mensagens. Exemplo: se existir uma coluna <span class="primary--text">nome</span> com os nomes das pessoas, a mensagem poderá trazer os nomes dinamicamente com a variável <span class="primary--text">{nome}</span>. Obs: se utilizar colunas com outras informações, preencha as células para todos os contatos, caso contrário a mensagem poderá ir incompleta para quem estiver sem a informação cadastrada.</span>
              <br>
              <b>Faça o download da planilha modelo <a href="/modelo_audiencia.xlsx">clicando aqui</a>, depois preencha a partir dela.</b>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex flex-wrap">
          <ib-btn outlined color="gray200" @click.native="getObjects(); dialogCreate = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn v-if="object.people.length > 0" class="my-1" color="primary" :loading="loading" @click.native="createNew">
            Salvar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <ib-dialog v-model="dialog" overlay-opacity="0.5" overlay- max-width="350">
      <v-card>
        <ib-dialog-header title="Apagar audiência" :close="() => { dialog = false }"></ib-dialog-header>
        <v-card-text>Tem certeza que deseja apagar a audiência <b>{{ object.name }}</b>?</v-card-text>
        <v-card-actions>
          <ib-btn outlined color="gray200" @click="dialog = false;">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn color="error" @click="dialog = false; deleteObject(object)">
            Apagar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <video-dialog v-model="showVideo" videoUrl="https://www.youtube.com/embed/VyBHJSrw5cE?rel=0" />
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import VideoDialog from "@/components/VideoDialog.vue";

export default {
  name: "AudienciasView",

  components: {
    VideoDialog
  },

  data() {
    return {
      objects: [],
      showVideo: false,
      showAlert: false,
      fileToUpload: null,
      object: { name: "", people: [], props: [], create_contacts: true },
      selectedItem: {},
      dialog: false,
      dialogCreate: false,
      loading: false,
      headers: [
        { text: 'Criação', value: 'created' },
        { text: 'Nome', value: 'name' },
        { text: "Quantidade de contatos", value: "people", sortable: false },
        { text: 'Variáveis', value: 'props' },
        { text: "Ações", value: "actions", sortable: false }
      ]
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },

  async mounted() {
    await this.getObjects();
  },

  methods: {
    async getObjects() {
      const resp = await Api.getAudienceByUser();

      if (!resp.error && resp.message) {
        this.objects = resp.message;
      }
    },

    async createNew() {
      if (this.object.name == "") return emitToastr("error", "Nome da audiência necessário");


      this.loading = true;
      const resp = await Api.createOrUpdateAudience(this.object);
      this.loading = false;

      if (!resp.error) {
        this.object = { name: "", people: [], props: [] };
        this.dialogCreate = false;
        emitToastr("success", "Audiência cadastrada com sucesso.");
        this.getObjects();
      }
    },

    async importResults(save = false) {
      this.loading = true;
      let resp = await Api.sendAudienceList({}, this.fileToUpload, save);
      this.loading = false;

      if (resp && !resp.error) {
        if (resp.total_count && resp.total_count > 0) {
          this.object.people = resp.valid;
          this.object.props = resp.header;
        }

        if (resp.message) {
          emitToastr("success", resp.message);
        }
      }
    },

    async deleteObject(object) {
      object.filed = true;
      await Api.createOrUpdateAudience(object);
      this.getObjects();
      this.object = { name: "", people: [], props: [] };
    },

  },
};
</script>

<style scoped></style>