<template>
  <div class="menu-page">
    <v-card class="nd-border" flat>
      <v-card-title>
        Mensagens rápidas
      </v-card-title>

      <v-card-subtitle>
        Crie atalhos com mensagens rápidas que otimizarão o seu atendimento ao cliente. <ib-btn small @click="showVideo = true"><v-icon size="16" class="mr-1">mdi-help-circle-outline</v-icon>Saiba mais</ib-btn>
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-spacer></v-spacer>
            <ib-btn color="primary" @click.native="openNew">
              <v-icon left>mdi-plus</v-icon>
              Nova mensagem rápida
            </ib-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="objects" :loading="loading">
              <template #[`item.actions`]="{ item }">
                <v-menu>
                  <template #activator="{ on, attrs }">
                    <ib-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </ib-btn>
                  </template>

                  <v-card>
                    <v-list-item @click="openQuickMessage(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-file-edit-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="confirmDeleteQuickMessage(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Apagar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <ib-dialog v-model="dialog" scrollable max-width="600" persistent>
      <v-card>
        <ib-dialog-header title="Mensagem Rápida" subtitle="Crie ou modifique suas mensagens rápidas para responder com mais agilidade nos atendimentos humanos" :close="() => { dialog = false }"></ib-dialog-header>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <ib-text-field v-model="object.shortcut" class="pt-1" label="Atalho" outlined :messages="`O atalho para a mensagem abaixo será /${object.shortcut || ''}`" prefix="/" />
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="object.message" class="pt-1 nd-text-field" label="Mensagem" placeholder="Escreva a mensagem completa aqui" outlined rows="6" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <ib-btn outlined color="gray200" @click.native="dialog = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn color="primary" :loading="loading" @click.native="save">
            Salvar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>


    <ib-dialog v-model="deleteDialog" overlay-opacity="0.5" overlay-color="black" max-width="350">
      <v-card>
        <ib-dialog-header title="Apagar mensagem rápida" :subtitle="`Tem certeza que deseja apagar a mensagem rápida /${object.shortcut}?`" :close="() => { deleteDialog = false }"></ib-dialog-header>
        <v-card-actions>
          <ib-btn outlined color="gray200" @click.native="deleteDialog = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn color="error" :loading="loading" @click="deleteDialog = false; deleteQuickMessage()">
            Apagar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <video-dialog v-model="showVideo" videoUrl="https://www.youtube.com/embed/QhLZU8qF87g?rel=0" />
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import VideoDialog from "@/components/VideoDialog.vue";


export default {
  name: "MensagensRapidasView",

  components: {
    VideoDialog
  },

  data() {
    return {
      showVideo: false,
      objects: [],
      object: {},
      loading: true,
      dialog: false,
      deleteDialog: false,
      headers: [
        { text: 'Atalho', value: 'shortcut', sortable: false },
        { text: 'Mensagem', value: 'message', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false },
      ]
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },


  async mounted() {
    await this.getQuickMessages();
  },

  methods: {
    async getQuickMessages() {
      this.loading = true;
      const resp = await Api.getQuickMessagesByUser();
      this.loading = false;

      if (!resp.error && resp.message) {
        this.objects = resp.message;
      }
    },

    openNew() {
      this.object = {};
      this.dialog = true;
    },

    confirmDeleteQuickMessage(item) {
      this.object = item;
      this.deleteDialog = true;
    },

    async deleteQuickMessage() {
      this.object.filed = true;
      const resp = await Api.createOrUpdateQuickMessage(this.object);
      if (!resp.error && resp.message) {
        emitToastr("success", "Mensagem rápida apagada com sucesso");
        this.object = {};
        this.getQuickMessages();
      }
    },

    openQuickMessage(item) {
      this.object = item;
      this.dialog = true;
    },

    async save() {
      this.loading = true;
      if (!this.object.shortcut) return emitToastr("error", "O atalho é um campo necessário");
      if (!this.object.message) return emitToastr("error", "A mensagem é um campo necessário");

      this.object.message.split('/').join('');

      const resp = await Api.createOrUpdateQuickMessage(this.object);
      this.loading = false;

      if (!resp.error) {
        emitToastr("success", "Mensagem rápida salva com sucesso");
        this.getQuickMessages();
        this.dialog = false;
      }

    }
  },
};
</script>

<style scoped></style>