<template>
    <v-dialog v-bind="$attrs" v-on="$listeners" max-width="1200">
        <v-responsive v-if="videoUrl && $attrs.value" :aspect-ratio="16 / 9">
            <iframe class="yt-video" :src="videoUrl" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
        </v-responsive>
    </v-dialog>
</template>

<script>
export default {
    props: {
        videoUrl: {
            type: String,
            default: ''
        }
    }
}
</script>