<template>
  <div class="pa-4">
    <v-card class="nd-border pa-2" flat>
      <div class="d-flex align-center">
        <div>
          <v-card-title>
            Contatos
          </v-card-title>

          <v-card-subtitle>
            Importe, exporte, veja ou altere dados de seus contatos. <ib-btn small @click="showVideo = true"><v-icon size="16" class="mr-1">mdi-help-circle-outline</v-icon>Saiba mais</ib-btn>
          </v-card-subtitle>
        </div>

      </div>

      <v-divider class="my-5" />

      <v-row align="center" class="mb-3">
        <v-col cols="12" lg="8" md="8">
          <v-row>
            <v-col cols="12" lg="6" md="6">
              <ib-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Busque por nome ou telefone" clearable hide-details @input="searchOnServer" />
            </v-col>
            <v-col cols="12" lg="6" md="6">
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" lg="4" md="4" class="d-flex justify-end align-center">
          <ib-btn color="primary" class="mr-4" :loading="loading" @click="dialogImport = true">
            <v-icon left>
              mdi-file-upload-outline
            </v-icon>
            <div>Importar</div>
          </ib-btn>

          <ib-btn outlined color="gray200" :loading="loading" @click="exportFile">
            <v-icon left>
              mdi-file-download-outline
            </v-icon>
            <div class="gray950--text">
              Exportar
            </div>
          </ib-btn>
        </v-col>
      </v-row>

      <ib-data-table :headers="headers" :items="objects" :options.sync="options" :server-items-length="totalObjects" :loading="loading">
        <template #[`item.created`]="{ item }">
          {{ new Date(item.created).toLocaleString('pt-BR').replace(',', ' -') }}
        </template>

        <template #[`item.name`]="{ item }">
          <div class="d-flex align-center py-2">
            <ib-user-avatar :user="item" size="40" class="mr-4" />
            <div>
              {{ item.override_name || item.name }}
            </div>
          </div>
        </template>

        <template #[`item.tags`]="{ item }">
          <ib-chip small v-for="(tag, index_tag) in item.tags" v-show="tags_dictionary[tag]" :key="`tag_${index_tag}`" class="my-1 mr-1" :color="tags_dictionary[tag]?.color">
            {{ tags_dictionary[tag]?.name }}
          </ib-chip>
        </template>

        <template #[`item.phone`]="{ item }">
          {{ $utils.formatPhone(item.phone) }}
        </template>

        <template #[`item.ignore`]="{ item }">
          <ib-switch v-if="!item.ignore" :input-value="false" readonly color="primary" hide-details class="ma-0" @click="object = item; dialog1 = true" />
          <ib-switch v-else :input-value="true" readonly color="primary" hide-details class="ma-0" @click="object = item; dialog2 = true" />
        </template>


        <template #[`item.actions`]="{ item }">
          <v-menu>
            <template #activator="{ on, attrs }">
              <ib-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-horizontal</v-icon>
              </ib-btn>
            </template>

            <v-card>
              <v-list-item @click="object = item; tagsOverrideName = true">
                <v-list-item-icon>
                  <v-icon>mdi-file-edit-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Editar nome</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="object = item; tagsDialog = true">
                <v-list-item-icon>
                  <v-icon>mdi-tag-multiple-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Tags / rótulos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item @click="openContact(item._id)">
                <v-list-item-icon>
                  <v-icon>mdi-account-details-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>Dados do contato</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-menu>
        </template>
      </ib-data-table>
    </v-card>

    <ib-dialog v-model="dialog1" overlay-opacity="0.5" overlay-color="black" max-width="450">
      <v-card>
        <ib-dialog-header title="Ignorar contato" :subtitle="`Tem certeza que deseja ignorar ${object.name || object.phone}?`" :close="() => { dialog1 = false }"></ib-dialog-header>
        <v-card-text>Essa pessoa não terá mais suas mensagens tratadas pelo sistema enquanto estiver com a configuração de ignorar ligada.</v-card-text>
        <v-card-actions>
          <ib-btn outlined color="gray200" @click="dialog1 = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>
          <v-spacer />
          <ib-btn dark color="red600" @click="dialog1 = false; ignoreContact(true)">
            Ignorar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <ib-dialog v-model="dialog2" overlay-opacity="0.5" overlay-color="black" max-width="450">
      <v-card>
        <ib-dialog-header title="Ignorar contato" :subtitle="`Tem certeza que deseja deixar de ignorar ${object.name || object.phone}?`" :close="() => { dialog1 = false }"></ib-dialog-header>
        <v-card-text>Essa pessoa passará a ter suas conversas respondidas pelo chatbot e atendimento manual.</v-card-text>
        <v-card-actions>
          <ib-btn outlined color="gray200" @click="dialog2 = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>
          <v-spacer />
          <ib-btn dark color="primary" @click="dialog2 = false; ignoreContact(false)">
            Deixar de ignorar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <ib-dialog v-model="tagsDialog" overlay-opacity="0.5" overlay-color="black" max-width="600" persistent>
      <v-card>
        <ib-dialog-header title="Atribuir tags" subtitle="Organize seus contatos com tags" :close="() => { tagsDialog = false }"></ib-dialog-header>

        <v-card-text>

          <ib-select clearable v-model="object.tags" label="Tags ou rótulos" hide-details prepend-inner-icon="mdi-tag-multiple" :items="tags" item-text="name" item-value="_id" multiple>
            <template #item="{ item }">
              <ib-chip :color="item.color">
                {{ item.name }}
              </ib-chip>
            </template>

            <template #selection="{ item, index }">
              <div class="d-flex">
                <ib-chip v-if="index < 3" small :color="item.color">
                  {{ item.name }}
                </ib-chip>

                <ib-chip v-else-if="index == 3" small>
                  +{{ object.tags.length - 3 }}
                </ib-chip>
              </div>
            </template>
          </ib-select>
        </v-card-text>

        <v-card-actions>
          <ib-btn outlined color="gray200" @click="tagsDialog = false; getContacts(options);">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>
          <v-spacer />
          <ib-btn dark color="primary" @click="tagsDialog = false; changeContactTags()">
            Salvar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <ib-dialog v-model="tagsOverrideName" overlay-opacity="0.5" overlay-color="black" max-width="450" persistent>
      <v-card>
        <ib-dialog-header title="Editar nome" subtitle="Altere o nome do contato por aqui" :close="() => { tagsOverrideName = false }"></ib-dialog-header>

        <v-card-text>
          <ib-text-field v-model="object.override_name" class="pt-1" label="Novo nome do contato" outlined hide-details="auto" />
        </v-card-text>

        <v-card-actions>
          <ib-btn outlined color="gray200" @click="tagsOverrideName = false; getContacts(options);">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>
          <v-spacer />
          <ib-btn dark color="primary" @click="tagsOverrideName = false; changeContactName()">
            Salvar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <ib-dialog v-model="dialogImport" max-width="1200">
      <v-card>
        <ib-dialog-header title="Importar contatos" subtitle="Importe contatos com ou sem propriedades (variáveis) já definidos" :close="() => { dialogImport = false }"></ib-dialog-header>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-file-input v-model="fileToUpload" dense accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" label="Carregue aqui a planilha com seus contatos" />
              <ib-btn v-if="fileToUpload" large color="primary" class="ml-6" @click="importResults(false)">
                Analisar planilha
              </ib-btn>
            </v-col>

            <v-col v-if="import_contact_object.people.length > 0" cols="12" lg="6" md="6" sm="12">
              <ib-text-field v-model="import_contact_object.people.length" :readonly="true" label="Contatos válidos" outlined hide-details="auto" />
            </v-col>

            <v-col v-if="import_contact_object.people.length > 0" cols="12" lg="6" md="6" sm="12">
              <ib-text-field v-model="import_contact_object.props" :readonly="true" label="Propriedades reconhecidas" outlined hide-details="auto" />
            </v-col>

            <v-col cols="12">
              <span class="primary--text title">COMO IMPORTAR A PLANILHA?</span>
              <br>
              <div>
                <span><b>Campo obrigatório: <span class="primary--text">telefone</span>. É necessário ter uma coluna na planilha de nome "telefone" sem aspas, coluna essa com os números de telefone dos contatos</b></span>
              </div>
              <div>
                <span><span class="primary--text"><b>-telefone </b></span>número de Whatsapp do contato. Use DDI e DDD, somente números, sem espaços. Exemplo: 5511955555555</span>
              </div>
              <br>
              <span>Quaisquer outras colunas poderão ser utilizadas como variáveis nas mensagens. Exemplo: se existir uma coluna <span class="primary--text">nome</span> com os nomes das pessoas, a mensagem poderá trazer os nomes dinamicamente com a variável <span class="primary--text">{nome}</span>. Obs: se utilizar colunas com outras informações, preencha as células para todos os contatos, caso contrário a mensagem poderá ir incompleta para quem estiver sem a informação cadastrada.</span>
              <br>
              <b>Faça o download da planilha modelo <a href="/modelo_audiencia.xlsx">clicando aqui</a>, depois preencha a partir dela.</b>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex flex-wrap">

          <ib-btn outlined color="gray200" @click.native="dialogImport = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn v-if="import_contact_object.people.length > 0" class="my-1" color="primary" :loading="loading" @click.native="importContacts">
            Importar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <contact-drawer v-model="contactDrawer" :contact_id="selectedContact" :tags_dictionary="tags_dictionary" />
    <video-dialog v-model="showVideo" videoUrl="https://www.youtube.com/embed/tZvl50D1i2M?rel=0" />
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import { saveAs } from 'file-saver';
import ContactDrawer from "@/components/ContactDrawer";
import VideoDialog from "@/components/VideoDialog.vue";
//import { emitToastr } from "@/lib/Utils";

let timeout = null;


export default {
  name: "ContatosView",

  components: {
    ContactDrawer,
    VideoDialog
  },

  data() {
    return {
      mode: 'table',
      showVideo: false,

      objects: [],
      object: {},
      import_contact_object: { people: [], props: [] },
      totalObjects: 0,
      fileToUpload: null,
      search: "",
      filters: {
        tags: []
      },
      loading: true,
      dialog1: false,
      dialog2: false,
      dialogImport: false,
      tagsDialog: false,
      tagsOverrideName: false,
      contactDrawer: false,
      selectedContact: null,
      options: { itemsPerPage: 10 },
      tags_dictionary: {},
      tags: [],
      headers: [
        {
          text: 'Nome',
          value: 'name',
          sortable: false,
          icon: 'mdi-account-outline'
        },
        {
          text: "Rótulos",
          icon: "mdi-tag-multiple-outline",
          value: "tags",
          sortable: false
        },
        {
          text: "Telefone",
          value: "phone",
          icon: "mdi-phone",
          sortable: false,

        },
        {
          text: "Ignorar",
          value: "ignore",
          sortable: false,
          icon: "mdi-account-remove-outline"
        },
        // { text: 'Criação', value: 'created', sortable: false },
        { text: 'Ações', icon: "mdi-menu", value: 'actions', sortable: false, width: 50 },
      ]
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },

  watch: {
    options: {
      handler() {
        this.getContacts(this.options);
      },
      deep: true,
    },
  },

  async mounted() {
    if (this.currentUser?.access_restrictions?.includes('contacts')) {
      emitToastr("error", "Permissões insuficientes.");
      this.$router.replace({ name: 'Atendimento' });
    }

    const resp = await Api.getTagsByUser();

    if (!resp.error && resp.message) this.tags = resp.message;
    this.tags.forEach(i => this.tags_dictionary[i._id] = { name: i.name, color: i.color });
  },

  methods: {
    openContact(contact_id) {
      this.selectedContact = contact_id;
      this.contactDrawer = true;
    },

    async getContacts(options) {
      this.loading = true;
      const resp = await Api.getContactsByUser(options);
      this.loading = false;

      if (!resp.error && resp.message) {
        this.objects = resp.message.data;
        this.totalObjects = resp.message.total;
      }
    },

    async importContacts() {
      if (this.object.name == "") return emitToastr("error", "Nome da audiência necessário");

      this.loading = true;
      const resp = await Api.importContacts(this.import_contact_object);
      this.loading = false;

      if (!resp.error) {
        this.import_contact_object = { people: [], props: [] };
        this.dialogImport = false;
        emitToastr("success", "Contatos importados com sucesso.");
        this.getContacts();
      }
    },

    async exportFile() {
      if (this.currentUser.user_role == 'user') return emitToastr("error", "Não autorizado. Necessário solicitar ao gerente ou proprietário da conta.");

      this.loading = true;
      const resp = await Api.exportContacts();
      this.loading = false;
      const filename = `${new Date().toLocaleString().split('/').join('_').split(',').join('').split(':').join('_')}`

      const blob = new Blob([resp], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });

      saveAs(blob, `Contatos_${filename}.xlsx`);
    },

    async ignoreContact(ignore) {
      this.object.ignore = ignore;
      const resp = await Api.ignoreContact(this.object);

      if (!resp.error && resp.message) {
        emitToastr("success", "Contato atualizado com sucesso!")
      }
    },

    async importResults(save = false) {
      this.loading = true;
      let resp = await Api.sendAudienceList({}, this.fileToUpload, save);
      this.loading = false;

      if (resp && !resp.error) {
        if (resp.total_count && resp.total_count > 0) {
          this.imported = true;
          this.import_contact_object.people = resp.valid;
          this.import_contact_object.props = resp.header;
          this.total_count = resp.total_count;
        }

        if (resp.message) {
          emitToastr("success", resp.message);
        }
      }
    },

    async changeContactTags() {
      const resp = await Api.changeContactTags(this.object);
      if (!resp.error) emitToastr("success", "Tags atualizadas");
    },

    async changeContactName() {
      const resp = await Api.changeContactName(this.object);
      if (!resp.error) emitToastr("success", "Nome atualizado");
    },

    async searchOnServer() {

      if (timeout) clearTimeout(timeout);
      this.loading = true;

      timeout = setTimeout(() => {
        this.options.page = 1;
        this.options.query = this.search;
        this.loading = false;
        this.getContacts(this.options);
      }, 1000);
    }
  },
};
</script>

<style scoped></style>
