<template>
    <v-navigation-drawer v-model="drawer" class="drawer" :clipped="$vuetify.breakpoint.lgAndUp" width="270" app dark>
        <router-link id="v-step-0" to="/meu-perfil" style="text-decoration: none;">
            <div class="d-flex flex-column align-center mt-8">
                <v-avatar size="80">
                    <v-img v-if="currentUser?.profile_picture" :src="currentUser?.profile_picture" />
                    <div v-else class="w-full h-full primary d-flex justify-center">
                        <v-icon color="white">
                            mdi-account
                        </v-icon>
                    </div>
                </v-avatar>

                <div class="white--text mt-3">
                    <div class="font-weight-400 text-subtitle-2">
                        {{ currentUser?.name }}
                    </div>
                </div>
            </div>
        </router-link>

        <div class="mt-3 d-flex flex-column align-center">
            <v-btn x-small light class="primary" style="color: black" @click="dialog = true">
                Versão 2.8 | Novidades
            </v-btn>

            <div class="mt-2 px-3">
                <span v-for="(chip, index) in chips" :key="`chip_${index}`">
                    <v-icon small :color="chip.status == 'open' ? 'primary' : 'error lighten-1'">{{ chip.status == 'open' ? 'mdi-cellphone-check' : 'mdi-cellphone-remove' }}</v-icon>
                </span>
            </div>
        </div>

        <v-row class="mt-0">
            <v-col cols="12">
                <v-divider class="menu-divider" />
            </v-col>
        </v-row>

        <v-list dense expand style="padding-bottom: 56px;">
            <v-list-item class="mb-2" to="/ajuda">
                <v-list-item-icon>
                    <v-icon color="white">
                        mdi-help-circle-outline
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="white--text">
                    <v-list-item-title>Como usar a plataforma</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="['owner', 'manager'].includes(currentUser?.user_role)" to="/">
                <v-list-item-icon>
                    <v-icon color="green1">
                        mdi-monitor-dashboard
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="white--text">
                    <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="['owner', 'manager'].includes(currentUser?.user_role)" to="/api">
                <v-list-item-icon>
                    <v-icon color="green1">
                        mdi-api
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="white--text">
                    <v-list-item-title>API</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-group v-if="['owner', 'manager'].includes(currentUser?.user_role)" class="mt-2" active-class="white--text" :value="true" style="min-width: 1px;">
                <template #activator>
                    <v-list-item-title class="white--text">
                        Configurações de atendimento
                    </v-list-item-title>
                </template>

                <v-list-item v-if="['owner', 'manager'].includes(currentUser?.user_role)" to="/chatbots" class="white--text">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-robot-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Chatbots de IA</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="['owner', 'manager'].includes(currentUser?.user_role)" to="/flows" class="white--text">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-sitemap-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Chatbots de fluxo</v-list-item-title>
                </v-list-item>


                <v-list-item v-if="['owner', 'manager'].includes(currentUser?.user_role)" to="/chips" class="white--text">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-message-text-fast-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Canais de comunicação</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="['owner', 'manager'].includes(currentUser?.user_role)" to="/equipe" class="white--text">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-account-group-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Minha equipe</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="['owner', 'manager'].includes(currentUser?.user_role)" to="/mensagens-rapidas" class="white--text">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-message-fast-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Mensagens rápidas</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="['owner', 'manager'].includes(currentUser?.user_role)" to="/tags" class="white--text">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-tag-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Tags ou rótulos</v-list-item-title>
                </v-list-item>

                <v-list-item v-if="['owner', 'manager'].includes(currentUser?.user_role)" to="/departamentos" class="white--text">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-briefcase-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Departamentos</v-list-item-title>
                </v-list-item>
            </v-list-group>

            <v-list-group class="mt-2" active-class="white--text" :value="true" style="min-width: 1px;">
                <template #activator>
                    <v-list-item-title class="white--text">
                        Campanhas e agendamentos
                    </v-list-item-title>
                </template>

                <v-list-item to="/audiencias" class="white--text">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-account-group-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Audiências</v-list-item-title>
                </v-list-item>

                <v-list-item to="/campanhas" class="white--text">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-message-fast-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Campanhas</v-list-item-title>
                </v-list-item>

                <v-list-item to="/agendamento" class="white--text">
                    <v-list-item-icon>
                        <span class="mx-3">
                            <v-icon color="white" class="green1--text">mdi-calendar-clock-outline</v-icon>
                        </span>
                    </v-list-item-icon>

                    <v-list-item-title>Envio individual</v-list-item-title>
                </v-list-item>
            </v-list-group>


            <v-list-item v-if="['owner', 'manager', 'user'].includes(currentUser?.user_role)" to="/contatos">
                <v-list-item-icon>
                    <v-icon color="green1">
                        mdi-account-multiple-outline
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="white--text">
                    <v-list-item-title>Contatos</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="['owner', 'manager', 'user'].includes(currentUser?.user_role)" to="/atendimento">
                <v-list-item-icon>
                    <v-icon color="green1">
                        mdi-message-badge-outline
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="white--text">
                    <v-list-item-title>Conversas em andamento</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="['owner', 'manager', 'user'].includes(currentUser?.user_role)" to="/conversas">
                <v-list-item-icon>
                    <v-icon color="green1">
                        mdi-message-check-outline
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content class="white--text">
                    <v-list-item-title>Conversas finalizadas</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-dialog v-model="dialog" width="600" scrollable>
            <v-card>
                <v-card-title>Novidades da plataforma</v-card-title>
                <v-card-subtitle>Acompanhe aqui as novas funcionalidades assim que são lançadas</v-card-subtitle>

                <v-card-text>
                    <div class="py-2">
                        <div><b>Versão 2.8 - 14/12/2024</b></div>
                        <div>● Assistente de inteligência artificial para resumir conversas.</div>
                        <div>● Assistente de inteligência artificial para criar follow-ups personalizados.</div>
                        <div>● Atendentes não poderão mais exportar lista de contatos. Somente gerentes e proprietários terão permissão para essa operação.</div>
                    </div>
                    <v-divider />

                    <div class="py-2">
                        <div><b>Versão 2.7 - 12/12/2024</b></div>
                        <div>● Gatilhos de fluxos a partir de palavras-chave.</div>
                        <div>● Mehorias de performance.</div>
                    </div>
                    <v-divider />
                    <div class="py-2">
                        <div><b>Versão 2.6 - 07/12/2024</b></div>
                        <div>● Novo bloco (componente) de finalizar atendimento no fluxo.</div>
                        <div>● Ao criar uma tag, um departamento ou um chatbot na plataforma, os dados são automaticamente atualizados no fluxo, sem necessidade de atualizar a tela.</div>
                        <div>● Todos os chatbots de IA foram instruídos a não responder com texto formatado com markdown (Exemplo: **Item1** ou [LINK] (https://meusite.com) ).</div>
                        <div>● Proteções para evitar que departamentos ou fluxos ligados a canais sejam apagados.</div>
                        <div>● Contextos de chatbots de IA agora estão limitados a 25 mil caracteres para melhorar a assertividade do chatbot e evitar confusões e lentidão nas respostas.</div>
                        <div>● Melhorias no desempenho do sistema de envios de mensagens e listagem de conversas.</div>
                        <div>● Possibilidade de iniciar uma conversa em um departamento específico.</div>
                        <div>● Assistente de criação de chatbots de IA com modelos pré configurados.</div>
                        <div>● Correções de bugs e melhorias gerais no sistema.</div>
                    </div>
                    <v-divider />
                    <div class="py-2">
                        <div><b>Versão 2.5 - 26/11/2024</b></div>
                        <div>● Melhoria de desempenho na página de atendimentos (chat).</div>
                        <div>● Correções de bugs menores.</div>
                    </div>
                    <v-divider />
                    <div class="py-2">
                        <div><b>Versão 2.4 - 22/11/2024</b></div>
                        <div>● Importação de contatos e suas propriedades.</div>
                        <div>● Novas conversas iniciadas por nome do contato, ao invés de somente o telefone.</div>
                        <div>● Tratamento e avisos de erros nos envios de mensagem.</div>
                        <div>● Correção do bug de gravação de áudio na biblioteca.</div>
                        <div>● Visualização do estado de conexão dos chips no menu lateral.</div>
                    </div>
                    <v-divider />
                    <div class="py-2">
                        <div><b>Versão 2.3 - 10/10/2024</b></div>
                        <div>● Novo sistema de conexão, garantindo mais estabilidade aos chips conectados.</div>
                        <div>● Migração para servidores com maior capacidade.</div>
                        <div>● Possibilidade de renomear canais / chips.</div>
                        <div>● Correções e melhorias gerais.</div>
                    </div>
                    <v-divider />
                    <div class="py-2">
                        <div><b>Versão 2.2 - 08/08/2024</b></div>
                        <div>● Fluxo: componente temporizador de envio (atraso programado no envio das mensagens).</div>
                        <div>● Fluxo: componente randomizador (distribuição randômica)</div>
                    </div>
                    <v-divider />
                    <div class="py-2">
                        <div><b>Versão 2.1 - 30/08/2024</b></div>
                        <div>● Gestão de conversas que estão aguardando resposta. Dashboard aprimorado.</div>
                        <div>● Botão de duplicar fluxo.</div>
                        <div>● Assinaturas atrasadas não terão seus canais desconectados automaticamente. Apenas as canceladas.</div>
                        <div>● Correção do bug na criação de atendente com departamentos selecionados.</div>
                        <div>● Correção do bug que não atualizava em tempo real algumas novas conversas que deveriam chegar na página de conversas em andamento.</div>
                        <div>● Correção do bug que não exibia documentos PDF com legenda.</div>
                        <div>● Melhorias na estabilidade da conexão com Whatsapp.</div>
                    </div>
                    <v-divider />

                    <div class="py-2">
                        <div><b>Versão 2.0 - 24/08/2024</b></div>
                        <div>● API agora pode disparar fluxos, criando a possibilidade de implementações de integrações complexas.</div>
                        <div>● Campanha e agendamento de fluxo agora removem o status de atendimento humano.</div>
                        <div>● Todos os áudios enviados via fluxo serão considerados como gravados na hora.</div>
                        <div>● Correções de bugs e melhorias de desempenho.</div>
                    </div>
                    <v-divider />

                    <div class="py-2">
                        <div><b>Versão 1.9 - 20/08/2024</b></div>
                        <div>● Agora chatbots de IA saberão data e hora atuais.</div>
                        <div>● Correção da foto de perfil dos contatos.</div>
                        <div>● Correção do bug de filtros nas conversas em andamento.</div>
                        <div>● Melhoria no sistema de reconexão do Whatsapp.</div>
                    </div>
                    <v-divider />

                    <div class="py-2">
                        <div><b>Versão 1.8 - 13/08/2024</b></div>
                        <div>● Acompanhamento de campanhas em andamento em tempo real: quando a campanha está enviando mensagens, um novo botão "Acompanhar" fica disponível, mostrando as principais informações dos envios.</div>
                        <div>● Possibilidade de cancelar campanhas em andamento.</div>
                        <div>● Campanhas e envios agendados de TEXTO (não fluxo) são enviados normalmente, porém agora NÃO criam atendimentos/conversas automaticamente, até que o contato responda a mensagem. Ao responder, a conversa será criada de acordo com o primeiro atendimento cadastrado no canal: chatbot de IA, chatbot de fluxo ou atendimento humano.</div>
                    </div>
                    <v-divider />

                    <div class="py-2">
                        <div><b>Versão 1.7 - 11/08/2024</b></div>
                        <div>● Área de dados do contato com tags, variáveis e anotações: Na área de contatos ou nas conversas em andamento, acesse uma conversa e clique no nome do contato ou no menu e acesse "Dados do contato".</div>
                        <div>● Correções diversas nas campanhas e no fluxo.</div>
                        <div>● Melhoria no desempenho dos chatbots de IA.</div>
                    </div>
                    <v-divider />

                    <div class="py-2">
                        <div><b>Versão 1.6 - 31/07/2024</b></div>
                        <div>● Construtor de fluxos.</div>
                        <div>● Possibilidade de ter chatbots de IA enviando para fluxos e fluxos enviando para IA.</div>
                        <div>● Campanhas podem disparar fluxos.</div>
                        <div>● Agendamentos individuais podem disparar fluxos.</div>
                        <div>● Novos tutoriais na área "Como usar a plataforma".</div>
                        <div>● Conversas por chatbot agora finalizam automaticamente após 48 horas de inatividade (antes eram 30 minutos).</div>
                        <div>● Possibilidade de encerrar atendimentos de chatbot sem precisar transferir para humano antes.</div>
                    </div>
                    <v-divider />

                    <div class="py-2">
                        <div><b>Versão 1.5 - 03/07/2024</b></div>
                        <div>● Emojis no campo de mensagem (atendimento humano).</div>
                        <div>● Possibilidade de exportar contatos.</div>
                        <div>● Som de notificação ao receber nova mensagem (tela conversas em andamento).</div>
                        <div>● Chatbot não responde mais "por cima" do atendimento humano.</div>
                        <div>● Melhorias no agendamento de mensagem individual.</div>
                        <div>● Melhorias gerais de UX.</div>
                    </div>
                    <v-divider />

                    <div class="py-2">
                        <div><b>Versão 1.4 - 23/06/2024</b></div>
                        <div>● API de envio de mensagens via sistemas externos.</div>
                        <div>● Paginação nas conversas em andamento.</div>
                        <div>● Correções de bugs menores.</div>
                    </div>
                    <v-divider />

                    <div class="py-2">
                        <div><b>Versão 1.3 - 16/06/2024</b></div>
                        <div>● Sistema de tagueamento automático por palavras-chave (para usar, edite suas tags).</div>
                        <div>● Possibilidade de transferência para humano quando o chatbot receber imagens ou documentos (nas configurações do chatbot).</div>
                        <div>● Correção do bug nos filtros de tag da tela de conversas finalizadas.</div>
                        <div>● Agora o chat mostra quando uma mensagem do usuário é uma resposta a outra mensagem, trazendo o conteúdo original.</div>
                        <div>● Melhorias de desempenho.</div>
                    </div>
                    <v-divider />
                    <div class="py-2">
                        <div><b>Versão 1.2 - 06/06/2024</b></div>
                        <div>● Nome do autor da mensagem (atendente) exibido na plataforma (não é exibido para o contato).</div>
                        <div>● Atalho para gravação de áudio na conversa.</div>
                        <div>● Possibilidade de envio de midia sem salvar na biblioteca.</div>
                        <div>● Melhoria no layout da biblioteca de midia.</div>
                        <div>● Possibilidade de conectar canais diretamente com o atendimento humano.</div>
                    </div>
                    <v-divider />
                    <div class="py-2">
                        <div><b>Versão 1.1 - 31/05/2024</b></div>
                        <div>● Dashboard com filtro por canal / número conectado.</div>
                        <div>● Sistema de atraso no envio de mensagens por chatbot: Evita que seu chatbot responda imediatamente a uma pergunta. Pode ser configurado na área "Chatbots inteligentes", editando seu chatbot. Seção "ajustes gerais", opção "Atraso na resposta".</div>
                        <div>● Comportamento humano: agora o chatbot aguarda 2 segundos entre visualizar a mensagem e começar a digitar. Também digita por 5 segundos antes de enviar a resposta.</div>
                    </div>
                    <v-divider />
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-navigation-drawer>
</template>

<script>
import Api from '@/lib/Api';

export default {
    name: 'AppDrawer',
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            dialog: false,
            chips: []
        }
    },
    computed: {
        drawer: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },
        currentUser() {
            return this.$store.state.currentUser
        },
        isAdmin() {
            return this.currentUser?.admin;
        },
        menuItems: {
            get() {
                return this.$store.state.drawerMenuItems;
            },
            set(value) {
                this.$store.commit('setDrawerMenuItems', value);
            }
        }
    },
    watch: {
        currentUser: {
            immediate: true,
            handler: 'setMenuItems'
        }
    },
    mounted() {
        this.getChips();

        //CHECAGEM BÁSICA DE CANAIS A CADA 30 SEGUNDOS
        setInterval(() => {
            this.getChips();
        }, 30 * 1000);
    },
    methods: {
        openLink(link) {
            window.open(link, "_blank");
        },

        async getChips() {
            const resp = await Api.getChipNumbersByUser();

            if (!resp.error && resp.message) {
                this.chips = resp.message;
            }
        },

        setMenuItems() {
            this.menuItems = []

            if (!this.currentUser) {
                return;
            }
        }
    },
}
</script>

<style scoped>
.menu-divider {
    width: 90%;
    margin: auto;
    opacity: 0.6;
}

.drawer {
    background: rgb(38, 38, 38) !important;
    z-index: 100;
}

::v-deep .v-navigation-drawer__content {
    scrollbar-width: thin !important;
    scrollbar-color: rgba(74, 187, 52, 0.322) transparent !important;
}
</style>