<template>
  <div class="menu-page">
    <div v-if="currentUser.account.max_channels == 1">
      <v-alert class="fade" dense text type="warning">
        Funcionalidade indisponível neste plano. Por favor, faça upgrade para o plano Profissional ou superior.
        <ib-btn class="ml-2" color="warning" @click.native="$router.push('/minha-conta')">
          Ver planos disponíveis
        </ib-btn>
      </v-alert>
    </div>

    <v-alert v-if="showAlert" class="fade pb-4" dense text type="error" dismissible>
      Sua conta não possui nenhum número conectado. Será necessário conectar um número/chip para fazer o envio.
      <div>
        <ib-btn class="mt-2" color="error" @click.native="$router.push('/chips')">
          Conectar número
        </ib-btn>
      </div>
    </v-alert>

    <v-card v-if="currentUser.account.max_channels > 1" class="mb-4 nd-border" flat>
      <v-card-title>
        Chave de API
      </v-card-title>

      <!--
      <v-card-subtitle>
        Envie mensagens de texto puro ou fluxos diretamente para seus contatos através de requisições https. <ib-btn small><v-icon size="16" class="mr-1">mdi-help-circle-outline</v-icon>Saiba mais</ib-btn>
      </v-card-subtitle>
      -->

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <ib-text-field v-model="currentUser.account.api_key" label="API Key" readonly outlined hide-details="auto" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <ib-btn color="primary" class="mb-2" @click.native="dialog = true">
          Nova chave de API
        </ib-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="currentUser.account.max_channels > 1" class="mb-4 nd-border" flat>
      <v-card-title>
        Começar a usar a API
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" lg="6" md="6">
            <ib-autocomplete v-model="selected_chip" outlined :items="chips" item-text="name" no-data-text="Não há números conectados" item-value="_id" label="Selecione um canal de comunicação" hide-details="auto" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <div><b>Tipo 1. API Simples: mensagens enviadas por um sistema seu</b></div>
        <div><b>Método:</b> POST</div>
        <div><b>Headers:</b> Content-type: application/json</div>
        <div><b>URL:</b> https://painel.inteligenciabot.com/api/v1/send-text?chip=<span class="primary--text">{{ selected_chip }}</span>&api_key=<span class="primary--text">{{ currentUser.account?.api_key || 'SUA_CHAVE_DE_API' }}</span></div>
        <div><b>Body:</b> { <span class="primary--text">"phone": "Número do Whatsapp aqui, com código do país, DDD e número. Ex: 5511955555555"</span>, "message": "Texto da sua mensagem aqui!" }</div>

        <div>
          <ib-btn outlined class="mt-2" color="primary" @click.native="copyCurl('text')">
            Copiar CURL
          </ib-btn>
        </div>
      </v-card-text>

      <v-card-text>
        <div><b>Tipo 2. API de fluxo: dispara um fluxo pré cadastrado</b></div>
        <v-row class="my-1">
          <v-col cols="12" lg="6" md="6">
            <ib-autocomplete v-model="selected_flow" outlined :items="flows" item-text="name" no-data-text="Não há fluxos cadastrados" item-value="_id" label="Selecione um fluxo" hide-details="auto" />
          </v-col>
        </v-row>
        <div><b>Método:</b> POST</div>
        <div><b>Headers:</b> Content-type: application/json</div>
        <div><b>URL:</b> https://painel.inteligenciabot.com/api/v1/send-flow?chip=<span class="primary--text">{{ selected_chip }}</span>&api_key=<span class="primary--text">{{ currentUser.account?.api_key || 'SUA_CHAVE_DE_API' }}</span></div>
        <div><b>Body:</b> { <span class="primary--text">"phone": "Número do Whatsapp aqui, com código do país, DDD e número. Ex: 5511955555555"</span>, "flow": "{{ selected_flow }}", "metadata": { "chave_qualquer": "valor qualquer"} }</div>
        <div><b>Obs:</b> Tudo que for enviado no metadata do body poderá ser usado no fluxo. No exemplo acima, poderia ser usado {chave_qualquer} em um componente de texto, e este enviaria "valor qualquer" ao contato.</div>
      </v-card-text>

      <v-card-text>
        <div><b>Tipo 3. API Integração: mensagens enviadas por um sistema de terceiros</b></div>
        <div><b>Método:</b> POST</div>
        <div><b>Headers:</b> Content-type: application/json</div>
        <div>
          <b>URL:</b> https://painel.inteligenciabot.com/api/v1/send-text?chip=<span class="primary--text">{{ selected_chip }}</span>&api_key=<span class="primary--text">{{ currentUser.account?.api_key || 'SUA_CHAVE_DE_API' }}</span>&message=<span class="primary--text">Mensagem_de_texto</span>&phone_field=<span class="primary--text">Campo telefone no body*</span>
        </div>
        <div><b>Body:</b> Objeto enviado pelo sistema de terceiro</div>
        <div>
          <b>Campo telefone no body*:</b> Verificar o nome do campo de telefone no objeto enviado pelo sistema. Por exemplo, se o objeto for algo como { phone: "551195555555" } o valor seria apenas <b>phone</b>. Caso for algo como { customer: { name: "Alex", phone: "551195555555"} } o
          valor seria <b>customer.phone</b>, pois o "phone" está dentro de "customer".
        </div>
      </v-card-text>

    </v-card>


    <ib-dialog v-model="dialog" overlay-opacity="0.5" overlay- max-width="450">
      <v-card>
        <ib-dialog-header title="Trocar chave" subtitle="Tem certeza que deseja trocar a chave de API? Seus sistemas já integrados precisarão ser modificados com a nova chave." :close="() => { dialog = false }"></ib-dialog-header>
        <v-card-actions>
          <ib-btn outlined color="gray200" @click.native="dialog = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn color="error" @click="dialog = false; updateApiKey()">
            Trocar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
  name: "APIView",

  data() {
    return {
      showAlert: false,
      chips: [],
      validChips: [],
      flows: [],
      dialog: false,
      selected_chip: 'SELECIONE UM CANAL DE COMUNICAÇÃO',
      selected_flow: 'SELECIONE UM FLUXO'
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },

  async mounted() {
    await this.$store.dispatch('loadCurrentUser');

    const chips = await Api.getChipNumbersByUser();

    if (!chips.error && chips.message) {
      this.chips = chips.message;
      this.validChips = [];
      let hasConnection = false;

      chips.message.forEach(chip => {
        if (chip.status == 'open') {
          this.validChips.push(chip.name);
          hasConnection = true;
        }
      });

      if (!hasConnection) this.showAlert = true;
    }

    const flows = await Api.getFlowsByUser();

    if (!flows.error && flows.message) {
      this.flows = flows.message;
    }
  },

  methods: {
    async updateApiKey() {
      const resp = await Api.updateUserApiKey();
      if (resp && !resp.error && resp.message) {
        await this.$store.dispatch('loadCurrentUser');
        emitToastr("success", "Chave atualizada com sucesso!")
      }
    },

    async copyCurl(type = 'text') {
      if (type == 'text') {
        await navigator.clipboard.writeText(`curl --location 'https://painel.inteligenciabot.com/api/v1/send-text?chip=${this.selected_chip}&api_key=${this.currentUser?.account?.api_key || 'SUA_CHAVE_DE_API'}' \
--header 'Content-Type: application/json' \
--data '{
    "message": "Texto da mensagem aqui!",
    "phone": "5511955555555"
}'`);
      }

      if (type == 'template') {
        await navigator.clipboard.writeText(`curl --location 'https://painel.inteligenciabot.com/api/v1/send-template?chip=NOME_DO_CHIP&api_key=${this.currentUser?.account?.api_key || 'SUA_CHAVE_DE_API'}&template=NOME_DO_TEMPLATE' \
--header 'Content-Type: application/json' \
--data '{
    "to": "5511955555555",
    "variavel1": "José da Silva",
    "variavel2": "Camiseta M"
}'`);
      }

      emitToastr("success", "Requisição copiada para a área de transferência")
    }

  },
};
</script>

<style scoped></style>