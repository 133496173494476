<template>
  <div class="menu-page">
    <v-card class="nd-border" flat>
      <v-card-title>Ajuda</v-card-title>
      <v-card-subtitle>Nesta seção você poderá encontrar vídeos completos sobre o funcionamento da plataforma. Novos vídeos serão adicionados semanalmente, não deixe de conferir.</v-card-subtitle>

      <v-card-text>

        <v-expansion-panels v-model="panel" :disabled="disabled" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header><b>Funcionamento geral da plataforma (o vídeo mais importante)</b></v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mb-4">
                O vídeo mais importante. Passa por todas as áreas e configurações necessárias para a perfeita configuração da sua central de atendimento
              </div>
              <v-responsive :aspect-ratio="16 / 9" width="90%" max-width="1000">
                <iframe class="yt-video" src="https://www.youtube.com/embed/XDiMCl6tuHw?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
              </v-responsive>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Tipos de chatbot. Qual devo escolher?</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mb-4">
                Chatbot de IA, chatbot de fluxo ou ambos juntos? Como entender qual tipo vai melhor lhe atender
              </div>
              <v-responsive :aspect-ratio="16 / 9" width="90%" max-width="1000">
                <iframe class="yt-video" src="https://www.youtube.com/embed/Z14Pszn3hlI?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
              </v-responsive>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Criando chatbots incríveis</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mb-4">
                Aprenda a criar chatbots profissionais e resolva qualquer necessidade de atendimento usando os recursos certos na plataforma
              </div>
              <v-responsive :aspect-ratio="16 / 9" width="90%" max-width="1000">
                <iframe class="yt-video" src="https://www.youtube.com/embed/ovI823xIX_Y?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
              </v-responsive>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Construtor de fluxo e seus elementos</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="mb-4">
                Aprenda tudo sobre o construtor de fluxos da InteligênciaBot e saiba tirar o máximo proveito de seus poderosos recursos
              </div>
              <v-responsive :aspect-ratio="16 / 9" width="90%" max-width="1000">
                <iframe class="yt-video" src="https://www.youtube.com/embed/mowb8hhJu2I?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
              </v-responsive>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Departamentos</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-responsive :aspect-ratio="16 / 9" width="90%" max-width="1000">
                <iframe class="yt-video" src="https://www.youtube.com/embed/kBR3PWOQ6ZY?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
              </v-responsive>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Minha equipe</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-responsive :aspect-ratio="16 / 9" width="90%" max-width="1000">
                <iframe class="yt-video" src="https://www.youtube.com/embed/yWSrkDnB2Ds?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
              </v-responsive>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Tags ou rótulos</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-responsive :aspect-ratio="16 / 9" width="90%" max-width="1000">
                <iframe class="yt-video" src="https://www.youtube.com/embed/tX77sJgITTA?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
              </v-responsive>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Mensagens rápidas ou respostas rápidas</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-responsive :aspect-ratio="16 / 9" width="90%" max-width="1000">
                <iframe class="yt-video" src="https://www.youtube.com/embed/QhLZU8qF87g?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
              </v-responsive>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Meus chips (conectando o Whatsapp)</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-responsive :aspect-ratio="16 / 9" width="90%" max-width="1000">
                <iframe class="yt-video" src="https://www.youtube.com/embed/F-wiQbFw2l4?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
              </v-responsive>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Contatos</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-responsive :aspect-ratio="16 / 9" width="90%" max-width="1000">
                <iframe class="yt-video" src="https://www.youtube.com/embed/tZvl50D1i2M?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
              </v-responsive>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Audiências</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-responsive :aspect-ratio="16 / 9" width="90%" max-width="1000">
                <iframe class="yt-video" src="https://www.youtube.com/embed/VyBHJSrw5cE?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
              </v-responsive>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Campanhas</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-responsive :aspect-ratio="16 / 9" width="90%" max-width="1000">
                <iframe class="yt-video" src="https://www.youtube.com/embed/64x46-3SGk4?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
              </v-responsive>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Agendamento individual</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-responsive :aspect-ratio="16 / 9" width="90%" max-width="1000">
                <iframe class="yt-video" src="https://www.youtube.com/embed/A6muZy7Y19g?rel=0" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
              </v-responsive>
            </v-expansion-panel-content>
          </v-expansion-panel>


        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
//import Api from "@/lib/Api";
//import { emitToastr } from "@/lib/Utils";
//import { emitToastr } from "@/lib/Utils";


export default {
  name: "AjudaView",

  data() {
    return {
      panel: 0
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },

  async mounted() {

  },

  methods: {
  }
};
</script>

<style scoped>
.yt-video {
  border-radius: 8px;
}

.video-responsive {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 ratio (most common) */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
}

.video-responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;  height: 100%;
  border: 0;
}
</style>