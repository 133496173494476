<template>
  <div v-if="currentUser?.system_user_roles?.includes('tech_dashboard')" class="menu-page">
    <div>
      <div>
        <span class="section-title mb-2">
          <v-icon size="18" class="mr-2">mdi-clock-time-eight-outline</v-icon>
          Tempo real
        </span>
        <v-row>
          <v-col cols="12" md="4" lg="4" sm="12" xs="12">
            <v-card class="mx-auto pt-4 pb-4 nd-border" flat>
              <v-card-title class="py-2">
                <div class="card-row">
                  <v-icon class="icon" color="primary" left>
                    mdi-message-outline
                  </v-icon>
                  <span class="text-h3">
                    <animated-number :value="admin_cards.conversations_all || 0" round="1" :duration="600" />
                  </span>
                </div>

                <div right class="div-text subtitle-1 right mt-1">
                  Conversas em andamento
                </div>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" lg="4" sm="12" xs="12">
            <v-card class="mx-auto pt-4 pb-4 nd-border" flat>
              <v-card-title class="py-2">
                <div class="card-row">
                  <v-icon class="icon" color="primary" left>
                    mdi-robot-outline
                  </v-icon>
                  <span class="text-h3">
                    <animated-number :value="admin_cards.conversations_chatbot || 0" round="1" :duration="600" />
                  </span>
                </div>

                <div right class="div-text subtitle-1 right mt-1">
                  Atendimento chatbot
                </div>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" lg="4" sm="12" xs="12">
            <v-card class="mx-auto pt-4 pb-4 nd-border" flat>
              <v-card-title class="py-2">
                <div class="card-row">
                  <v-icon class="icon" color="warning lighten-1" left>
                    mdi-face-agent
                  </v-icon>
                  <span class="text-h3">
                    <animated-number :value="admin_cards.conversations_manual || 0" round="1" :duration="600" />
                  </span>
                </div>

                <div right class="div-text subtitle-1 right mt-1">
                  Atendimento humano
                </div>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div class="mt-2">
        <v-row>
          <v-col cols="12" md="4" lg="4" sm="12" xs="12">
            <v-card class="mx-auto pt-4 pb-4 nd-border" flat>
              <v-card-title class="py-2">
                <div class="card-row">
                  <v-icon class="icon" color="primary" left>
                    mdi-message-outline
                  </v-icon>
                  <span class="text-h3">
                    <animated-number :value="admin_cards.accounts_active || 0" round="1" :duration="600" />
                  </span>
                </div>

                <div right class="div-text subtitle-1 right mt-1">
                  Contas ativas
                </div>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" lg="4" sm="12" xs="12">
            <v-card class="mx-auto pt-4 pb-4 nd-border" flat>
              <v-card-title class="py-2">
                <div class="card-row">
                  <v-icon class="icon" color="primary" left>
                    mdi-whatsapp
                  </v-icon>
                  <span class="text-h3">
                    <animated-number :value="admin_cards.chips_connected || 0" round="1" :duration="600" />
                  </span>
                </div>

                <div right class="div-text subtitle-1 right mt-1">
                  Chips conectados
                </div>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" lg="4" sm="12" xs="12">
            <v-card class="mx-auto pt-4 pb-4 nd-border" flat>
              <v-card-title class="py-2">
                <div class="card-row">
                  <v-icon class="icon" color="warning lighten-1" left>
                    mdi-message-fast-outline
                  </v-icon>
                  <span class="text-h3">
                    <animated-number :value="admin_cards.campaigns_all || 0" round="1" :duration="600" />
                  </span>
                </div>

                <div right class="div-text subtitle-1 right mt-1">
                  Campanhas criadas
                </div>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div class="mt-2">
        <v-row>
          <v-col cols="12" md="4" lg="4" sm="12" xs="12">
            <v-card class="mx-auto pt-4 pb-4 nd-border" flat>
              <v-card-title class="py-2">
                <div class="card-row">
                  <v-icon class="icon" color="primary" left>
                    mdi-message-outline
                  </v-icon>
                  <span class="text-h3">
                    <animated-number :value="admin_cards.messages_all || 0" round="1" :duration="600" />
                  </span>
                </div>

                <div right class="div-text subtitle-1 right mt-1">
                  Mensagens trocadas
                </div>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" lg="4" sm="12" xs="12">
            <v-card class="mx-auto pt-4 pb-4 nd-border" flat>
              <v-card-title class="py-2">
                <div class="card-row">
                  <v-icon class="icon" color="primary" left>
                    mdi-robot-outline
                  </v-icon>
                  <span class="text-h3">
                    <animated-number :value="admin_cards.messages_chatbot || 0" round="1" :duration="600" />
                  </span>
                </div>

                <div right class="div-text subtitle-1 right mt-1">
                  Chatbot
                </div>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" lg="4" sm="12" xs="12">
            <v-card class="mx-auto pt-4 pb-4 nd-border" flat>
              <v-card-title class="py-2">
                <div class="card-row">
                  <v-icon class="icon" color="warning lighten-1" left>
                    mdi-face-agent
                  </v-icon>
                  <span class="text-h3">
                    <animated-number :value="admin_cards.messages_manual || 0" round="1" :duration="600" />
                  </span>
                </div>

                <div right class="div-text subtitle-1 right mt-1">
                  Humanas
                </div>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div>
        <span class="section-title mb-4 mt-6">
          <v-icon size="18" class="mr-2">mdi-chart-bar</v-icon>
          Relatórios
        </span>
        <v-row class="mb-2">
          <v-col cols="12">
            <v-btn color="primary" class="mr-2 mb-2" :outlined="interval != 1" :loading="loading" @click.native="getReports(1)">
              Hoje
            </v-btn>
            <v-btn color="primary" class="mr-2 mb-2" :outlined="interval != 7" :loading="loading" @click.native="getReports(7)">
              7 dias
            </v-btn>
            <v-btn color="primary" class="mr-2 mb-2" :outlined="interval != 30" :loading="loading" @click.native="getReports(30)">
              30 dias
            </v-btn>
            <v-btn color="primary" class="mr-2 mb-2" :outlined="interval != 90" :loading="loading" @click.native="getReports(90)">
              3 meses
            </v-btn>
            <v-btn color="primary" class="mr-2 mb-2" :outlined="interval != 180" :loading="loading" @click.native="getReports(180)">
              6 meses
            </v-btn>
            <v-btn color="primary" class="mr-2 mb-2" :outlined="interval != 365" :loading="loading" @click.native="getReports(365)">
              1 ano
            </v-btn>

            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
              <template #activator="{ on, attrs }">
                <v-btn color="primary" class="mr-2 mb-2" :outlined="(typeof (interval) != 'object')" :loading="loading" v-bind="attrs" v-on="on">
                  Intervalo personalizado
                </v-btn>
              </template>
              <v-date-picker v-model="date" locale="pt-BR" range no-title scrollable>
                <v-spacer />
                <v-btn text color="primary" @click="menu = false">
                  Cancelar
                </v-btn>
                <v-btn text color="primary" @click="saveDate(date)">
                  Ok
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </div>


      <v-row class="mt-4">
        <v-col cols="12">
          <v-card class="nd-border" flat :loading="loading">
            <v-card-title>
              Distribuição das conversas
            </v-card-title>
            <v-card-text :class="`${loading ? 'blur' : ''}`">
              <div id="chart1">
                <apexChart ref="chart1" height="350" :type="charts_type" :options="chart1Options" :series="series1" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col cols="12">
          <v-card class="nd-border" flat :loading="loading">
            <v-card-title>
              Distribuição das mensagens
            </v-card-title>
            <v-card-text :class="`${loading ? 'blur' : ''}`">
              <div id="chart2">
                <apexChart ref="chart2" height="350" :type="charts_type" :options="chart2Options" :series="series2" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" md="6" sm="12">
          <v-card class="fill-height nd-border" flat :loading="loading">
            <v-card-title>Distribuição de chips</v-card-title>
            <v-card-text :class="`${loading ? 'blur' : ''}`">
              <div id="chart4">
                <apexChart ref="chart4" type="donut" height="400" :options="chart4Options" :series="series4" />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import AnimatedNumber from "animated-number-vue";

export default {
  name: "DashboardView",

  components: { AnimatedNumber },

  data() {
    return {
      loading: false,
      interval: 7,

      menu: false,
      date: null,

      conversation_cards: { all: 0, chatbot: 0, manual: 0 },
      admin_cards: {},

      series1: [],
      series2: [],
      series4: [],

      departments_labels: [],

      charts_type: 'bar',

      chart1Options: {
        grid: { show: false },
        chart: {
          stacked: true,
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              enabled: true,
              total: {
                enabled: true,
                formatter: function (w) {
                  return w;
                },
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            const total = opts.w.globals.stackedSeriesTotals[opts.dataPointIndex];
            const percentage = ((val / total) * 100).toFixed(0) + "%";
            return percentage;
          }
        },
        legend: {
          position: 'top',
          fontSize: '14px',
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [""],
          convertedCatToNumeric: false,
          labels: {
            formatter: function (val) {
              return new Date(`${val} 12:00:00`).toLocaleDateString('pt-BR');
            },
            style: {
              colors: "#000",
            },
          },
        }
      },

      chart2Options: {
        grid: { show: false },
        chart: {
          stacked: true,

          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              enabled: true,
              total: {
                enabled: true,
                formatter: function (w) {
                  return w;
                },
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) {
            const total = opts.w.globals.stackedSeriesTotals[opts.dataPointIndex];
            const percentage = ((val / total) * 100).toFixed(0) + "%";
            return percentage;
          }
        },
        legend: {
          position: 'top',
          fontSize: '14px',
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: [""],
          convertedCatToNumeric: false,
          labels: {
            formatter: function (val) {
              return new Date(`${val} 12:00:00`).toLocaleDateString('pt-BR');
            },
            style: {
              colors: "#000",
            },
          },
        }
      },

      chart4Options: {
        labels: [],
        grid: { show: false },
        dataLabels: {
          enabled: true
        },
        legend: {
          position: 'top',
          fontSize: '14px',
        },
      },
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },

    getIntervalName() {
      switch (this.interval) {
        case 1:
          return 'Hoje'
        case 7:
          return '7 dias'
        case 30:
          return '30 dias'
        case 90:
          return '3 meses'
        case 180:
          return '6 meses'
        case 365:
          return '1 ano'

        default:
          return 'Intervalo personalizado'
      }
    },
  },

  async mounted() {
    this.getReports(this.interval);
    //if (!this.currentUser?.admin)
  },

  methods: {
    saveDate(date) {
      this.menu = false;
      this.getReports(date);
    },

    async getReports(interval) {
      this.loading = true;
      this.interval = interval;
      const resp = await Api.getAdminDashboardData(this.interval);
      this.charts_type = interval > 30 ? 'area' : 'bar';
      this.loading = false;

      if (resp && resp.error) {
        return emitToastr("error", resp.message)
      }

      if (resp && resp.message) {

        if (resp.message.admin_cards) this.admin_cards = resp.message.admin_cards;

        if (resp.message.admin_charts?.conversations) {
          let cat = [];
          let serie1 = { name: "Atendimento humano", data: [] };
          let serie2 = { name: "Chatbot de IA", data: [] };
          let serie3 = { name: "Chatbot de Fluxo", data: [] };


          resp.message.admin_charts.conversations.forEach(i => {
            cat.push(i._id);
            serie1.data.push(i.human);
            serie2.data.push(i.ai_chatbot);
            serie3.data.push(i.flow_chatbot);
          });

          this.series1 = [serie1, serie2, serie3];
          this.chart1Options.xaxis.categories = cat;
          if (this.$refs.chart1) this.$refs.chart1.refresh();
        }


        if (resp.message.admin_charts?.messages) {
          console.log(resp.message.admin_charts?.messages)
          let cat = [];
          let serie1 = { name: "Mensagens humanas", data: [] };
          let serie2 = { name: "Mensagens chatbot", data: [] };
          let serie3 = { name: "Mensagens do contato", data: [] };

          resp.message.admin_charts.messages.forEach(i => {
            cat.push(i._id);
            serie1.data.push(i.user);
            serie2.data.push(i.chatbot);
            serie3.data.push(i.customer);
          });

          this.series2 = [serie1, serie2, serie3];
          this.chart2Options.xaxis.categories = cat;
          if (this.$refs.chart2) this.$refs.chart2.refresh();
        }

        if (resp.message.admin_charts?.chip_distribution) {
          let series4 = [];
          this.chart4Options.labels = [];

          resp.message.admin_charts.chip_distribution.forEach(i => {
            if (i._id) {
              this.chart4Options.labels.push(i._id);
              series4.push(i.all);
            }
          });

          this.series4 = series4;
          if (this.$refs.chart4) this.$refs.chart4.refresh();
        }
      }
    }
  },
};
</script>

<style scoped>
.section-title {
  display: flex;
  flex-direction: row;
  align-content: center;
  font-size: 18px;
  font-weight: 600;
  color: var(--v-gray600-base);
}

.icon {
  font-size: 40px;
}

.card-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.div-text {
  width: 100%;
  text-align: right;
}

.card-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right {
  justify-content: flex-end !important;
}

.text-h3 {
  color: rgb(53, 53, 53);
}
</style>
