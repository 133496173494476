<template>
  <div class="menu-page">
    <v-card class="nd-border" flat>
      <v-card-title>
        Mensagens Agendadas
      </v-card-title>

      <v-card-subtitle>
        Agende envios individuais para contatos específicos. <ib-btn small @click="showVideo = true"><v-icon size="16" class="mr-1">mdi-help-circle-outline</v-icon>Saiba mais</ib-btn>
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-spacer></v-spacer>
            <ib-btn color="primary" @click.native="object = {}; dialogScheduledMessage = true">
              <v-icon left>mdi-plus</v-icon>
              Novo agendamento
            </ib-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="objects" :loading="loading">
              <template #[`item.status`]="{ item }">
                {{ statuses[item.status] || "Agendado" }}
              </template>

              <template #[`item.scheduledTo`]="{ item }">
                {{ $date.smallFormat(item.scheduledTo) }}
              </template>

              <template #[`item.to`]="{ item }">
                {{ $utils.formatPhone(item.to || "") }}
              </template>

              <template #[`item.message`]="{ item }">
                {{ item.message && item.message.length > 80 ? `${item.message.substring(0, 79)}...` : item.message }} {{ !item.message && item.flow ? 'Fluxo' : '' }}
              </template>

              <template #[`item.actions`]="{ item }">
                <v-menu v-if="item.status == 'scheduled'" class="d-flex">
                  <template #activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-list-item @click="confirmDeleteObject(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Apagar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="openObject(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-file-edit-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <ib-dialog v-model="deleteDialog" overlay-opacity="0.5" overlay-color="black" max-width="350">
      <v-card>
        <ib-dialog-header title="Apagar agendamento" :close="() => { deleteDialog = false }"></ib-dialog-header>

        <v-card-text>Tem certeza que deseja apagar o agendamento para <b>{{ object.to }}</b>?</v-card-text>
        <v-card-actions>
          <ib-btn outlined color="gray200" @click="deleteDialog = false;">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn color="error" @click="deleteDialog = false; deleteObject(object)">
            Apagar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <dialog-scheduled-message v-model="dialogScheduledMessage" phone="" :all-message="object" :chips="chips" :callback="getObjects" />
    <video-dialog v-model="showVideo" videoUrl="https://www.youtube.com/embed/A6muZy7Y19g?rel=0" />
  </div>
</template>

<script>
import Api from "@/lib/Api";
import DialogScheduledMessage from "@/components/DialogScheduledMessage";
import { emitToastr } from "@/lib/Utils";
import VideoDialog from "@/components/VideoDialog.vue";


export default {
  name: "MensagensAgendadasView",

  components: {
    DialogScheduledMessage,
    VideoDialog
  },

  data() {
    return {
      objects: [],
      showVideo: false,
      object: {},
      chips: [],
      statuses: { "sent": "Enviado", "error": "Erro", "scheduled": "Agendado" },
      loading: true,
      dialogScheduledMessage: false,
      deleteDialog: false,
      headers: [
        { text: 'Data e hora', value: 'scheduledTo', sortable: false },
        { text: 'Telefone', value: 'to', sortable: false },
        { text: 'Mensagem', value: 'message', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false },
      ]
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },

  async mounted() {
    await this.getObjects();
    await this.getChips();
  },

  methods: {
    async getObjects() {
      this.loading = true;
      const resp = await Api.getScheduledMessagesByUser();
      this.loading = false;

      if (!resp.error && resp.message) {
        this.objects = resp.message;
      }
    },

    async getChips() {
      const resp = await Api.getChipNumbersByUser();

      if (!resp.error && resp.message) {
        this.chips = resp.message;
      }
    },

    openObject(item) {
      if (item._id && item.to) {
        const ddi = item.to.substring(0, 2);
        if (ddi === "55") item.to = item.to.substring(2);
      }
      this.object = item;
      this.dialogScheduledMessage = true;
    },

    confirmDeleteObject(item) {
      this.object = item;
      this.deleteDialog = true;
    },

    async deleteObject() {
      this.object.filed = true;
      const resp = await Api.updateScheduledMessage(this.object);

      if (!resp.error) {
        this.object = {};
        emitToastr("success", "Agendamento apagado com sucesso.");
        this.getObjects();
      }
    },

  },
};
</script>

<style scoped></style>