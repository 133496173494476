import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ptBr from 'vuetify/lib/locale/pt';

import IconFlow from '@/components/Icons/Flow.vue';
import IconAi from '@/components/Icons/Ai.vue';
import IconGeometry from '@/components/Icons/Geometry.vue';
import IconIntegration from '@/components/Icons/Integration.vue';
import IconLayout from '@/components/Icons/Layout.vue';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        current: 'ptBr',
        locales: { ptBr }
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                // vuetify colors
                primary: '#0B9055',
                green1: '#00e320',
                grey1: '#4c4c4c',

                darkbackground: '#ffffff',
                darkbackground2: '#ffffff',

                //new colors
                gray25: '#FCFCFC',
                gray50: '#F7F7F7',
                gray75: '#F5F5F5',
                gray100: '#EFEFEF',
                gray200: '#DCDCDC',
                gray400: '#989898',
                gray500: '#7C7C7C',
                gray600: '#656565',
                gray950: '#292929',
                gray1000: '#141414',

                green50: '#EDFCF3',
                green100: '#D3F8E0',
                green200: '#AAF0C7',
                green600: '#0B9055',
                green700: '#097347',
                green900: '#094B31',

                red400: '#F97970',
                red600: '#DE3024',
            }
        }
    },
    icons: {
        values: {
            flow: {
                component: IconFlow 
            },
            ai: {
                component: IconAi
            },
            geometry: {
                component: IconGeometry
            },
            integration: {
                component: IconIntegration
            },
            layout: {
                component: IconLayout 
            }
        }
    }
});

