<template>
  <div class="menu-page">
    <v-alert prominent color="primary" text icon="mdi-school-outline">
      <v-row align="center">
        <v-col cols="12" lg="8" md="8" sm="8" class="grow">
          Quer aprender a criar fluxos incríveis? Entenda o funcionamento de cada componente assistindo nossos vídeos e tire proveito do que há de melhor na plataforma.
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="d-flex" @click="$router.push('/ajuda')">
          <v-spacer></v-spacer>
          <ib-btn class="primary">Ver vídeos</ib-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-card class="nd-border" flat>
      <v-card-title>
        Chatbots de fluxo
      </v-card-title>

      <v-card-subtitle>
        Crie fluxos de atendimento para automatizar o envio de mensagens. Use os gatilhos automáticos para ativar o fluxo certo assim que seu contato enviar determinadas palavras-chave. <ib-btn small @click="showVideo = true"><v-icon size="16" class="mr-1">mdi-help-circle-outline</v-icon>Saiba mais</ib-btn>
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-spacer></v-spacer>
            <ib-btn color="primary" @click.native="dialog = true">
              <v-icon left>mdi-plus</v-icon>
              Novo fluxo
            </ib-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="items" :loading="loading">
              <template #[`item.keywords`]="{ item }">
                <span v-if="!item.trigger">Não</span>
                <div v-if="item.trigger && item.keywords">
                  <ib-chip v-for="(keyword, index) in item.keywords" :key="`keyword_${index}`" small class="mr-1 my-1" color="#0B9055">
                    {{ keyword }}
                  </ib-chip>
                </div>
              </template>

              <template #[`item.actions`]="{ item }">
                <v-menu class="d-flex">
                  <template #activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-list-item @click="openOptionsDialog(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-flash-auto</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Ativar por gatilho</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="deleteItem(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Apagar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item :to="`/flows/${item._id}`">
                      <v-list-item-icon>
                        <v-icon>mdi-file-edit-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectedFlow = item; dialog2 = true">
                      <v-list-item-icon>
                        <v-icon>mdi-content-duplicate</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Duplicar fluxo</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-menu>


              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <ib-dialog v-model="dialog" scrollable max-width="600" persistent>
      <v-card>
        <ib-dialog-header title="Nome do fluxo" :close="() => { dialog = false }"></ib-dialog-header>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <ib-text-field class="pt-2" v-model="payload.name" label="Nome" outlined hide-details="auto" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <ib-btn color="gray200" outlined @click="dialog = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>
          <v-spacer />
          <ib-btn color="primary" :loading="saving" @click="save">
            Criar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <ib-dialog v-model="dialog2" scrollable max-width="600" persistent>
      <v-card>
        <ib-dialog-header :title="`Duplicar fluxo ${selectedFlow.name}`" :close="() => { dialog2 = false }"></ib-dialog-header>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <ib-text-field class="pt-2" v-model="payload.name" label="Nome do novo fluxo" outlined hide-details="auto" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <ib-btn color="gray200" outlined @click="dialog2 = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>
          <v-spacer />
          <ib-btn color="primary" :loading="saving" @click="duplicateFlow">
            Duplicar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <ib-dialog v-model="dialog3" scrollable max-width="600" persistent>
      <v-card>
        <ib-dialog-header title="Gatilhos por palavra-chave" subtitle="Cadastre palavras ou frases enviadas por seus contatos que ativarão esse fluxo automaticamente" :close="() => { dialog3 = false }"></ib-dialog-header>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-switch v-model="payload.trigger" dense inset class="my-1" label="Ativar com palavras-chave" hide-details="auto" />
            </v-col>

            <v-col v-if="payload.trigger" cols="12">
              <ib-text-field v-model="new_keyword" class="pt-1" label="Nova palavra-chave" placeholder="Digite a aperte ENTER" outlined hide-details="auto" append-outer-icon="mdi-plus" @keyup.enter="addItem(new_keyword)" @click:append-outer="addItem(new_keyword)" />
            </v-col>

            <v-col v-if="payload.trigger && payload.keywords" cols="12">
              <label>Palavras-chave enviadas pelo contato que irão ativar esse fluxo</label>
              <div>
                <ib-chip v-for="(item, index) in payload.keywords" :key="index" dark close class="primary mr-2 my-2" @click:close="payload.keywords.splice(index, 1)">
                  {{ item }}
                </ib-chip>
                <ib-chip v-if="!payload.keywords || payload.keywords.length == 0" small class="warning">
                  Crie pelo menos uma palavra-chave
                </ib-chip>
              </div>
            </v-col>

            <v-col v-if="payload.trigger" cols="12">
              <v-switch v-model="payload.trigger_human" dense inset class="my-1" label="Ativo em atendimento humano" hide-details="auto" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <ib-btn color="gray200" outlined @click.native="dialog3 = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>
          <v-spacer />
          <ib-btn color="primary" :loading="loading" @click.native="updateOptions">
            Salvar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <video-dialog v-model="showVideo" videoUrl="https://www.youtube.com/embed/mowb8hhJu2I?rel=0" />
  </div>
</template>

<script>
import StartNode from '@/flow-editor/nodes/StartNode';
import VideoDialog from "@/components/VideoDialog.vue";

export default {
  name: "FlowList",

  components: {
    VideoDialog
  },

  data() {
    return {
      showVideo: false,
      items: [],
      payload: {},
      dialog: false,
      dialog2: false,
      dialog3: false,
      loading: false,
      saving: false,
      selectedFlow: {},
      new_keyword: '',
      headers: [
        { text: 'Nome', value: 'name', sortable: false },
        { text: 'Gatilhos', value: 'keywords', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false, width: 100 },
      ]
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },
  async mounted() {
    await this.load();
  },
  methods: {
    openOptionsDialog(item) {
      this.payload = JSON.parse(JSON.stringify(item));
      this.dialog3 = true;
    },

    addItem(item) {
      if (!item || item == '') return this.$api.emitToastr("error", "Palavra-chave necessária");
      if (this.payload.keywords.find(k => k.toLowerCase() == item.toLowerCase())) return this.$api.emitToastr("error", "Palavra-chave já cadastrada");
      this.payload.keywords.push(item);
      this.new_keyword = "";
    },

    async load() {
      this.loading = true;

      const response = await this.$api.getFlowsByUser();

      if (response.error) return

      this.items = response.message;

      setTimeout(() => {
        this.loading = false;
      }, 800);
    },

    async deleteItem(item) {
      const confirmDelete = await this.$store.dispatch('confirmDialog', {
        title: "Apagar fluxo",
        message: `Deseja realmente apagar o fluxo ${item.name}?`
      });

      if (!confirmDelete) return

      const response = await this.$api.createOrUpdateFlow({
        ...item,
        filed: true
      });

      if (response.error) return

      this.$toast("success", "Fluxo apagado com sucesso");

      await this.load();
    },

    async duplicateFlow() {
      if (!this.payload.name) {
        return this.$toast("error", "O nome é um campo necessário");
      }

      this.saving = true;
      const response = await this.$api.duplicateFlow(this.selectedFlow._id, this.payload.name);

      if (response.error) {
        this.saving = false;
        return
      }

      this.$toast("success", "Fluxo criado com sucesso");

      await this.load();

      setTimeout(() => {
        this.saving = false;
        this.dialog2 = false;
      }, 800);
    },

    async updateOptions() {
      this.saving = true;

      if (this.payload.trigger && !this.payload.keywords.length) return this.$toast("error", "É necessário adicionar pelo menos uma palavra-chave");

      const response = await this.$api.createOrUpdateFlow(this.payload);

      if (response.error) {
        this.saving = false;
        return
      }

      this.$toast("success", "Fluxo salvo com sucesso");

      await this.load();

      setTimeout(() => {
        this.saving = false;
        this.dialog3 = false;
      }, 400);
    },

    async save() {
      if (!this.payload.name) {
        return this.$toast("error", "O nome é um campo necessário");
      }

      this.saving = true;

      const response = await this.$api.createOrUpdateFlow({
        name: this.payload.name,
        nodes: [new StartNode()],
      });

      if (response.error) {
        this.saving = false;
        return
      }

      this.$toast("success", "Fluxo salvo com sucesso");

      await this.load();

      setTimeout(() => {
        this.saving = false;
        this.dialog = false;
      }, 400);
    }
  },
};
</script>