<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    overlay-opacity="0.92"
    overlay-color="error lighten-1"
    width="650"
  >
    <v-card>
      <v-card-title>
        Assinatura em atraso
      </v-card-title>

      <v-card-subtitle>
        <b>Regularize sua assinatura para evitar a interrupção dos serviços.</b> <br>
        Se você já pagou, ignore essa mensagem. Ela sumirá em alguns minutos.
      </v-card-subtitle>

      <v-card-text
        v-for="(invoice, index) in pastdue_invoices"
        :key="`invoice_${index}`"
      >
        <div class="text-subtitle-2">
          {{ `Assinatura mês ${invoice.cycle}` }}
        </div>
        <v-btn
          color="primary"
          @click="openLink(invoice.payment_url)"
        >
          Link de pagamento
        </v-btn>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="error"
          @click="dialog = false"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            data: {},
            pastdue_invoices: []
        }
    },
    async mounted() {
        this.checkSubscription();

        //A CADA 3 HORAS
        setInterval(() => {
            this.checkSubscription()
        }, 3 * 60 * 60 * 1000);
    },
    methods: {
        async checkSubscription() {
            const resp = await this.$api.getGuruSubscription();

            if (resp && !resp.error && resp.message && resp.message.last_status == 'pastdue') {
                this.data = resp.message;
                if (this.data.invoices) this.pastdue_invoices = this.data.invoices.filter(x => x.status == 'pastdue');
                this.dialog = true;
            }
        },

        openLink(link) {
            window.open(link, "_blank");

            document.addEventListener("visibilitychange", async () => {
                this.dialog = false;
            });
        }
    }
};
</script>

<style></style>