<template>
  <v-dialog
    v-model="dialog"
    width="350"
    persistent
  >
    <v-card>
      <v-card-title>
        Gravação de áudio
      </v-card-title>

      <v-card-text>
        <v-row class="pt-1">
          <v-col
            cols="12"
            class="text-center"
          >
            <v-btn
              v-if="!recording"
              icon
              height="65"
              width="65"
              @click="startRecording"
            >
              <v-icon size="55">
                mdi-microphone
              </v-icon>
            </v-btn>
            <v-btn
              v-if="recording"
              icon
              height="65"
              width="65"
              @click="stopRecording"
            >
              <v-icon size="55">
                mdi-pause
              </v-icon>
            </v-btn>
            <div
              v-if="recording"
              class="status-label"
            >
              Gravando...
            </div>
            <audio
              v-show="audio_source"
              class="mt-2"
              :src="audio_source"
              controls
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="error"
          text
          @click="closeDialog"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="!audio_source"
          :loading="loading"
          @click="send"
        >
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from '@/lib/Api';
const MicRecorder = require('mic-recorder-to-mp3');
const recorder = new MicRecorder({ bitRate: 128 });

export default {
    props: {
        value: Boolean,
        sendMedia: Function,
    },
    data() {
        return {
            loading: false,
            audio_data: null,
            recording: false,
            audio_source: null,
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(val) {
                let delay = 10;
                if (val === false) {
                    if (this.recording) {
                        this.stopRecording();
                        delay = 50;
                    }
                }

                setTimeout(() => {
                    this.clear();
                    this.$emit('input', val);
                }, delay);
            }
        }
    },
    methods: {
        clear() {
            this.loading = false;
            this.recording = false;
            this.audio_data = null;
            this.audio_source = null;
        },

        closeDialog() {
            this.dialog = false;
        },

        async send() {
            await this.uploadRecord();
        },

        async startRecording() {
            this.audio_source = null;
            recorder.start().then(() => {
                this.recording = true;
            }).catch((e) => {
                this.recording = false;
                console.error(e);
            });
        },

        stopRecording() {
            recorder
                .stop()
                .getMp3().then(([buffer]) => {
                    const file = new File(buffer, 'gravacao.mp3', {
                        type: "audio/mpeg",
                        lastModified: Date.now()
                    });

                    this.audio_data = file;
                    this.audio_source = URL.createObjectURL(file);
                    this.recording = false;
                }).catch((e) => {
                    this.recording = false;
                    console.log(e);
                });
        },

        async uploadRecord() {
            this.loading = true;
            const resp = await Api.uploadFile(this.audio_data, 'audio', "", true);
            this.loading = false;

            if (!resp.error && resp.message) {
                const media = { ...resp.message, ptt: true };
                this.sendMedia(media);
                this.dialog = false;
            }
        },
    }
};
</script>

<style>
.status-label {
    font-size: 16px;
    font-weight: 500;
    margin-top: 12px;
}
</style>