<template>
  <div class="menu-page">
    <v-card class="nd-border" flat>
      <v-card-title>
        Departamentos
      </v-card-title>

      <v-card-subtitle>
        Crie departamentos para organizar o atendimento e transferir seus contatos para o atendente certo. <ib-btn small @click="showVideo = true"><v-icon size="16" class="mr-1">mdi-help-circle-outline</v-icon>Saiba mais</ib-btn>
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-spacer></v-spacer>
            <ib-btn color="primary" @click.native="openNew">
              <v-icon left>mdi-plus</v-icon>
              Novo departamento
            </ib-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="objects" :loading="loading">
              <template #[`item.actions`]="{ item }">
                <v-menu>
                  <template #activator="{ on, attrs }">
                    <ib-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </ib-btn>
                  </template>

                  <v-card>
                    <v-list-item @click="openObject(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-file-edit-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="confirmDeleteObject(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Apagar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <ib-dialog v-model="dialog" scrollable max-width="600" persistent>
      <v-card>
        <ib-dialog-header title="Departamento" subtitle="Crie ou modifique departamentos para organizar os atendimentos de forma simplificada e eficiente. Os atendentes só poderão ver conversas de seus departamentos." :close="() => { dialog = false }"></ib-dialog-header>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <ib-text-field v-model="object.name" class="pt-1" label="Nome do departamento" outlined hide-details="auto" />
            </v-col>

            <v-col cols="12">
              <ib-text-field v-model="object.description" class="pt-1" label="Descrição (opcional)" outlined hide-details="auto" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <ib-btn outlined color="gray200" @click.native="dialog = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn color="primary" :loading="loading" @click.native="save">
            Salvar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>


    <ib-dialog v-model="deleteDialog" overlay-opacity="0.5" overlay-color="black" max-width="350">
      <v-card>
        <ib-dialog-header title="Apagar departamento" :subtitle="`Tem certeza que deseja apagar o departamento ${object.name}?`" :close="() => { deleteDialog = false }"></ib-dialog-header>

        <v-card-actions>
          <ib-btn outlined color="gray200" @click.native="deleteDialog = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>

          <v-spacer />

          <ib-btn color="error" @click="deleteDialog = false; deleteObject()">
            Apagar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <video-dialog v-model="showVideo" videoUrl="https://www.youtube.com/embed/kBR3PWOQ6ZY?rel=0" />
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import VideoDialog from "@/components/VideoDialog.vue";


export default {
  name: "MensagensRapidasView",

  components: {
    VideoDialog
  },

  data() {
    return {
      showVideo: false,
      objects: [],
      object: {},
      loading: true,
      dialog: false,
      deleteDialog: false,
      headers: [
        { text: 'Nome', value: 'name', sortable: false },
        { text: 'Descrição', value: 'description', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false },
      ]
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },

  async mounted() {
    await this.getObjects();
  },

  methods: {
    async getObjects() {
      this.loading = true;
      const resp = await Api.getDepartmentsByUser();
      this.loading = false;

      if (!resp.error && resp.message) {
        this.objects = resp.message;
      }
    },

    openNew() {
      this.object = {};
      this.dialog = true;
    },

    confirmDeleteObject(item) {
      this.object = item;
      this.deleteDialog = true;
    },

    async deleteObject() {
      this.object.filed = true;
      const resp = await Api.createOrUpdateDepartment(this.object);
      if (!resp.error && resp.message) {
        emitToastr("success", "Departamento apagado com sucesso");
        this.object = {};
        this.getObjects();
      }
    },

    openObject(item) {
      this.object = item;
      this.dialog = true;
    },

    async save() {
      if (!this.object.name) return emitToastr("error", "O nome é um campo necessário");

      this.loading = true;
      const resp = await Api.createOrUpdateDepartment(this.object);
      this.loading = false;

      if (!resp.error) {
        emitToastr("success", "Departamento salvo com sucesso");
        this.getObjects();
        this.dialog = false;
      }
    }
  },
};
</script>

<style scoped></style>