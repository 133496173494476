<template>
  <div class="menu-page">
    <v-alert prominent color="primary" text icon="mdi-school-outline">
      <v-row align="center">
        <v-col cols="12" lg="8" md="8" sm="8" class="grow">
          Quer aprender a criar um chatbot de IA profissional? Assista nossos vídeos para ficar expert no assunto.
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="d-flex" @click="$router.push('/ajuda')">
          <v-spacer></v-spacer>
          <ib-btn class="primary">Ver vídeos</ib-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-card class="nd-border" flat>
      <v-card-title>
        Chatbots de IA
      </v-card-title>

      <v-card-subtitle>
        Crie chatbots incríveis com IA e deixe seu atendimento inicial no automático. <ib-btn small @click="showVideo = true"><v-icon size="16" class="mr-1">mdi-help-circle-outline</v-icon>Saiba mais</ib-btn>
      </v-card-subtitle>
      
      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-spacer></v-spacer>
            <v-menu offset-y>
              <template #activator="{ on, attrs }">
                <ib-btn color="primary" v-bind="attrs" v-on="on">
                  <v-icon left>mdi-plus</v-icon>
                  Novo chatbot de IA
                </ib-btn>
              </template>

              <v-list dense>
                <v-list-item two-line class="cursor-pointer" @click="openNew">
                  <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title><b>CRIAR DO ZERO</b></v-list-item-title>
                    <v-list-item-subtitle class="cursor-pointer">
                      Sem a utilização de modelo ou assistente de criação de prompt
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>


                <v-list-item v-for="(item, index) in chatbot_models" :key="`model_${index}`" two-line class="cursor-pointer" @click="newChatbotByModel(item)">
                  <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title><b>{{ item.name }}</b></v-list-item-title>
                    <v-list-item-subtitle class="cursor-pointer">
                      {{ item.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="objects" :loading="loading">
              <template #[`item.actions`]="{ item }">
                <v-menu>
                  <template #activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-list-item @click="object = item; dialogCreate = true">
                      <v-list-item-icon>
                        <v-icon>mdi-file-edit-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="object = item; dialogDelete = true">
                      <v-list-item-icon>
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Apagar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-menu>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <ib-dialog v-model="dialogCreate" persistent scrollable max-width="1200">
      <v-card>
        <ib-dialog-header title="Criação de chatbot inteligente" subtitle="Crie aqui um assistente completo, com as características mais adequadas para atender o seu público" :close="() => { getObjects(); dialogCreate = false }"></ib-dialog-header>

        <v-card-text>
          <v-card-text class="mt-0">
            <v-row>
              <v-col cols="12" lg="6" md="6" sm="12">
                <ib-text-field v-model="object.name" label="Identificador do chatbot" placeholder="Um nome para diferenciar esse chatbot de outros, se existirem" outlined hide-details="auto" />
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider />

          <v-card-title style="color: black">
            Persona
          </v-card-title>

          <v-card-subtitle class="mb-4">
            Aqui vamos definir quem é o seu chatbot
          </v-card-subtitle>

          <v-card-text class="mt-0">
            <v-row>
              <v-col cols="12" lg="4" md="4" sm="12">
                <ib-text-field v-model="object.instructionsObject.persona_name" label="Nome da persona" placeholder="Como o chatbot se chama?" outlined hide-details="auto" />
              </v-col>

              <v-col cols="12" lg="4" md="4" sm="12">
                <ib-select v-model="object.instructionsObject.persona_gender" chips small-chips clearable item-text="name" item-value="id" :items="genders" label="Gênero" outlined hide-details="auto">
                  <template #selection="{ item }">
                    <v-chip small color="primary">
                      {{ item.name }}
                    </v-chip>
                  </template>
                </ib-select>
              </v-col>

              <v-col cols="12" lg="4" md="4" sm="12">
                <ib-select v-model="object.instructionsObject.personality_types" clearable chips small-chips multiple :items="personalities" label="Personalidade(s) (escolha até 3)" outlined hide-details="auto" @change="checkPersonalities">
                  <template #selection="{ item }">
                    <v-chip small color="primary">
                      {{ item }}
                    </v-chip>
                  </template>
                </ib-select>
              </v-col>

              <v-col cols="12">
                <ib-text-field v-model="object.instructionsObject.persona_mission" label="Missão do chatbot" placeholder="Escreva aqui, de forma completa, qual a missão deste chatbot" outlined hide-details="auto" />

                <v-expansion-panels dark focusable class="mt-2">
                  <v-expansion-panel class="primary lighten-2">
                    <v-expansion-panel-header>Exemplos de missão (para copiar e alterar)</v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-2">
                      <div>Fornecer suporte técnico, auxiliando os clientes na configuração e uso adequado do produto.</div>
                      <div>Personalizar a experiência do cliente, oferecendo sugestões de produtos com base em suas preferências e histórico de compras.</div>
                      <div>Auxiliar na resolução de problemas comuns, como dificuldades de acesso à conta, esquecimento de senha e problemas de navegação no site.</div>
                      <div>Fornecer informações detalhadas sobre o currículo do curso, incluindo disciplinas, carga horária e objetivos de aprendizagem.</div>
                      <div>Orientar os clientes sobre processos de devolução, troca e política de reembolso da empresa.</div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider />


          <v-divider />

          <v-card-title style="color: black">
            Contexto e conhecimento do chatbot
          </v-card-title>

          <v-card-subtitle class="mb-4">
            O seu assistente saberá responder sobre tudo que está aqui, não economize nas informações. Tudo que você quer que ele saiba responder precisa estar aqui
          </v-card-subtitle>

          <v-card-text class="mt-0">
            <v-row>
              <v-col cols="12">
                <v-textarea class="nd-text-field" v-model="object.instructionsObject.context" rows="10" label="Contexto geral" placeholder="Aqui você escreve (ou cola) toda a base de conhecimento do chatbot. Ele saberá responder apenas sobre o conteúdo aqui inserido. Vá fundo, coloque toda informação possível aqui." outlined hide-details="auto" />
              </v-col>

              <v-col cols="12">
                <ib-text-field v-model="object.instructionsObject.fallback" label="Como agir se não tiver a resposta?" placeholder="Oriente ensinando ao chatbot o que fazer (exemplo: Diga que ainda não tem essa informação. Ofereça suporte através do e-mail suporte@suaempresa.com)" outlined hide-details="auto" />

                <v-expansion-panels dark focusable class="mt-2">
                  <v-expansion-panel class="primary lighten-2">
                    <v-expansion-panel-header>Exemplos de textos (para copiar e alterar)</v-expansion-panel-header>
                    <v-expansion-panel-content class="pt-2">
                      <div>Diga que não sabe falar sobre isso, mas você pode entrar em contato com nosso suporte em seusuporte@seusite.com</div>
                      <div>Fale que ainda não tem essa informação. Ofereça ajuda com outra questão.</div>
                      <div>Se desculpe por não saber falar sobre. Ofereça uma nova ajuda com outro assunto.</div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider />

          <v-card-title style="color: black">
            Ajustes gerais
          </v-card-title>

          <v-card-subtitle class="mb-2">
            Configure suas preferências de atendimento
          </v-card-subtitle>

          <v-card-text class="mt-0">
            <v-row>
              <v-col cols="12" lg="6" md="6" sm="12">
                <ib-text-field v-model="object.response_delay" label="Atraso na resposta (em segundos)" hide-details="auto" type="number" messages="Se quiser que o chatbot responda imediatamente, digite 0. Se quiser que demore alguns segundos, digite um tempo adequado." outlined />
              </v-col>

              <v-col cols="12" lg="6" md="6" sm="12">
                <v-switch v-model="object.chatbot_auto_transfer_if_is_media" inset label="Transferir a conversa ao receber imagens ou documentos" />
              </v-col>

              <v-col cols="12">
                <v-switch v-model="object.chatbot_auto_transfer" inset label="Transferir a conversa quando o contato solicitar atendimento humano" />
              </v-col>

              <v-col v-if="object.chatbot_auto_transfer || object.chatbot_auto_transfer_if_is_media" cols="12">
                <ib-select v-model="object.redirect" chips mandatory small-chips item-text="name" item-value="id" :items="redirects" label="Transferir para?" color="primary" outlined hide-details="auto" @change="changeChatbot(item)" />
              </v-col>

              <v-col v-if="object.redirect == 'human'" cols="12">
                <ib-text-field v-if="object.chatbot_auto_transfer" v-model="object.chatbot_auto_transfer_message" label="Aviso de transferência para atendimento humano" placeholder="Quando o chatbot transferir o atendimento para humano, o que ele dirá para o usuário?" hide-details="auto" outlined messages="Escreva literalmente o que o chatbot irá dizer no momento da transferência para o atendimento humano." />
              </v-col>

              <v-col v-if="object.redirect == 'flow_chatbot' && (object.chatbot_auto_transfer || object.chatbot_auto_transfer_if_is_media)" cols="12">
                <ib-select v-model="object.flow" chips mandatory small-chips item-text="name" item-value="_id" :items="flows" label="Fluxo" color="primary" outlined hide-details="auto" @change="changeChatbot(item)" />
              </v-col>

              <v-col cols="12">
                <ib-text-field v-model="object.finish_conversation_message" label="Frase secreta para encerrar atendimento humano" placeholder="Digite aqui uma frase e lembre-se dela quando quiser encerrar um atendimento pelo seu celular" outlined messages="Sempre que uma pessoa falar no canal, o chatbot iniciará o atendimento. Caso você responda diretamente pelo Whatsapp do seu celular, o chatbot não atuará mais, considerando então este atendimento como atendimento humano. Para encerrar o atendimento humano via plataforma, basta clicar no botão de mesmo nome. Porém, caso você esteja no seu celular, poderá cadastrar uma frase aqui que, ao ser enviada por você, encerrará automaticamente o atendimento. Tente algo como 'Se precisar de mim é só chamar'. Você precisará falar exatamente a mesma frase" />
              </v-col>
            </v-row>
          </v-card-text>


          <v-card-title style="color: black">
            Temperatura
          </v-card-title>
          <v-card-subtitle class="mb-4">
            Quanto maior, mais criativo o chatbot fica, mas ele pode inventar respostas. Quanto menor, mais preciso e fiel ao conteúdo. RECOMENDAÇÃO: 0.5 na maior parte dos casos. Próximo de 0 para orçamentos ou cálculos. Próximo de 0.8 para conteúdos com muito texto e focados em
            comunicação,
            ajuda com dúvidas.
          </v-card-subtitle>

          <v-card-text>
            <v-row>
              <v-col cols="12" class="mt-3">
                <v-slider v-model="object.temperature" thumb-label="always" max="1" min="0" step="0.1" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card-text>


        <v-card-actions class="d-flex flex-wrap pb-4 mt-2">
          <ib-btn color="gray200" outlined @click.native="getObjects(); dialogCreate = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>
          <v-spacer />
          <ib-btn color="primary" :loading="loading" @click.native="createNew">
            Salvar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <ib-dialog v-model="dialogDelete" overlay-opacity="0.5" overlay-color="black" max-width="350">
      <v-card>
        <ib-dialog-header title="Apagar chatbot" :close="() => { dialogDelete = false }"></ib-dialog-header>
        <v-card-text>Tem certeza que deseja apagar o chatbot <b>{{ object.name }}</b>?</v-card-text>
        <v-card-actions>
          <ib-btn outlined color="gray200" @click="dialogDelete = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>
          <v-spacer />
          <ib-btn color="error" :loading="loading" @click="dialogDelete = false; deleteChatbot(object)">
            Apagar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <ib-dialog v-model="dialogNewModel" persistent scrollable max-width="900">
      <v-card>
        <ib-dialog-header :title="selectedModel?.name" subtitle="Preencha os campos com informações do seu negócio para que nosso sistema crie seu chatbot" :close="() => { dialogNewModel = false }"></ib-dialog-header>

        <v-card-text>
          <v-row class="pt-2">
            <v-col v-for="(item, index) in selectedModel?.content" :key="`col_${index}`" cols="12">
              <ib-text-field v-if="item.type === 'v-text-field'" v-model="newChatbotModel[item.name]" outlined :required="item.required" hide-details="auto" :label="item.description" />
              <v-textarea class="nd-text-field" v-if="item.type === 'v-textarea'" v-model="newChatbotModel[item.name]" rows="3" :required="item.required" outlined hide-details="auto" :label="item.description" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          {{ newChatbotText }}
        </v-card-text>

        <v-card-actions class="d-flex flex-wrap">
          <ib-btn outlined color="gray200" @click.native="dialogNewModel = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>
          <v-spacer />
          <ib-btn class="my-1" color="primary" :loading="loading" @click.native="createChatbotText">
            Pré configurar chatbot
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <video-dialog v-model="showVideo" videoUrl="https://www.youtube.com/embed/ovI823xIX_Y?rel=0" />
  </div>
</template>

<script>
import Api from '@/lib/Api';
import { emitToastr } from '@/lib/Utils';
import VideoDialog from "@/components/VideoDialog.vue";

//import Api from "@/lib/Api";
//import { emitToastr } from "@/lib/Utils";

export default {
  name: "ChatbotsView",

  components: {
    VideoDialog
  },

  data() {
    return {
      showVideo: false,
      dialogCreate: false,
      dialogDelete: false,
      dialogNewModel: false,
      objects: [],
      flows: [],
      chatbot_models: [],
      selectedModel: { name: '', variables: [] },
      newChatbotModel: {},
      newChatbotText: '',
      headers: [
        { text: 'Identificador', value: 'name', sortable: false },
        { text: 'Nome da persona', value: 'instructionsObject.persona_name', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      redirects: [{ name: 'Chatbot de Fluxo', id: 'flow_chatbot' }, { name: 'Atendimento humano', id: 'human' }],
      object: {
        name: "",
        temperature: 0.3,
        instructionsObject: {
          persona_name: "",
          persona_gender: "feminina",
          persona_mission: "",
          personality_types: [],
          fallback: "",
          context: ""
        }
      },
      genders: [
        { id: "feminina", name: "Feminino" },
        { id: "masculina", name: "Masculino" }
      ],
      personalities: [
        "Amigável",
        "Profissional",
        "Engraçado",
        "Prestativo",
        "Encorajador",
        "Empático",
        "Direto ao ponto",
        "Inspirador",
        "Educado",
        "Animado",
        "Simpático",
        "Sofisticado",
        "Sarcástico",
        "Encantador",
        "Tranquilo",
        "Divertido",
        "Descontraído",
        "Formal",
        "Informal",
        "Perspicaz",
        "Vendedor"
      ],
      loading: false
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    }
  },

  async mounted() {
    this.getObjects();
  },

  methods: {
    async getObjects() {
      this.loading = true;
      const resp = await Api.getChatbotsByUser();

      if (resp.error) {
        emitToastr("error", resp.message || "Erro ao carregar chatbots")
      }
      else {
        if (resp.message) this.objects = resp.message;
      }

      const flows = await Api.getFlowsByUser();

      if (flows.error) {
        emitToastr("error", flows.message || "Erro ao carregar fluxos")
      }
      else {
        if (flows.message) this.flows = flows.message;
      }

      const models = await Api.getChatbotModels();

      if (!models.error && models.message) this.chatbot_models = models.message;

      this.loading = false;

    },

    clear() {
      this.object = {
        name: "",
        temperature: 0.3,
        instructionsObject: {
          persona_name: "",
          persona_gender: "feminina",
          persona_mission: "",
          personality_types: [],
          fallback: "",
          context: ""
        }
      };
    },

    checkPersonalities(p) {
      if (p.length > 3) {
        p.length = 3;
        emitToastr("warning", "Máximo de características de personalidade atingido.");
      }
    },

    openNew() {
      this.clear();
      this.dialogCreate = true;
    },

    async createNew() {
      if (this.object.name == "") return emitToastr("error", "Identificador do chatbot necessário");
      if (this.object.instructionsObject == "") return emitToastr("error", "Instruções necessárias");
      if (this.object.instructionsObject.persona_name == "") return emitToastr("error", "Nome da persona necessário");
      if (this.object.instructionsObject.persona_gender == "") return emitToastr("error", "Gênero da persona necessário");
      if (this.object.instructionsObject.persona_mission == "") return emitToastr("error", "Missão da persona necessária");
      if (this.object.instructionsObject.personality_types.length === 0) return emitToastr("error", "Selecione pelo menos 1 tipo de personalidade");
      if (this.object.instructionsObject.fallback == "") return emitToastr("error", "Digite como o chatbot deve responder caso façam uma pergunta que ele não sabe ou não pode responder");
      if (this.object.instructionsObject.context == "") return emitToastr("error", "Escreva o contexto, a base de conhecimento do chatbot.");

      this.loading = true;
      const resp = await Api.createOrUpdateChatbot(this.object);
      this.loading = false;

      if (resp.error) {
        emitToastr("error", resp.message || "Erro ao carregar chatbots")
      }
      else {
        if (resp.message) {
          emitToastr("success", "Chatbot enviado com sucesso.")
          this.getObjects();
          this.dialogCreate = false;
          this.clear();
        }
      }

    },

    async deleteChatbot() {
      this.object.filed = true;
      this.loading = true;
      const resp = await Api.createOrUpdateChatbot(this.object);
      this.loading = false;

      if (!resp.error && resp.message) {
        emitToastr("success", "Chatbot apagado com sucesso.")
        this.getObjects();
        this.dialogDelete = false;
        this.clear();
      }
    },

    newChatbotByModel(model) {
      this.selectedModel = model;
      this.dialogNewModel = true;
      this.newChatbotModel = {};
      this.newChatbotText = '';
    },

    createChatbotText() {
      for (const i in this.selectedModel.content) {
        if (this.selectedModel.content[i].required && !this.newChatbotModel[this.selectedModel.content[i].name]) return emitToastr("error", `É necessário preencher o campo: ${this.selectedModel.content[i].description}`)
      }


      let text = "";

      for (const i in this.selectedModel.content) {
        if (this.newChatbotModel[this.selectedModel.content[i].name] || this.selectedModel.content[i].type == 'text') {
          let obj = {};
          obj[this.selectedModel.content[i].name] = this.newChatbotModel[this.selectedModel.content[i].name];
          text += `${this.$renderChatbotText(this.selectedModel.content[i].text, obj)}\n\n`;
        }
      }

      if (text) {
        this.object.instructionsObject.context = text;
        this.object.instructionsObject.fallback = "Não tenho essa informação no momento";
        if (this.selectedModel.personality_types) this.object.instructionsObject.personality_types = this.selectedModel.personality_types;
        if (this.selectedModel.persona_mission) this.object.instructionsObject.persona_mission = this.selectedModel.persona_mission;
        if (this.selectedModel.chatbot_auto_transfer_message) this.object.chatbot_auto_transfer_message = this.selectedModel.chatbot_auto_transfer_message;
        this.object.response_delay = 2;
        this.object.redirect = 'human';
        this.object.chatbot_auto_transfer = true;
        this.object.chatbot_auto_transfer_if_is_media = true;
        this.dialogNewModel = false;
        this.dialogCreate = true;
      }
    }
  },
};
</script>

<style scoped>
.small-text {
  font-size: 12px;
}
</style>