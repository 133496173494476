<template>
  <v-app-bar color="#f5f5f5" :clipped-left="$vuetify.breakpoint.lgAndUp" elevation="0" class="gray600--text">
    <v-btn icon color="gray950" @click.stop="$emit('toggle-drawer')">
      <v-icon>$layout</v-icon>
    </v-btn>

    <div style="width:1px;height:16px" class="gray200 mr-4 ml-1" />

    <div class="text-sm font-weight-semibold">
      {{ pageTitle }}
    </div>

    <v-spacer />

    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="px-3 cursor-pointer" @click="$router.push('/chips')">
          <span v-for="(chip, index) in chips" :key="`chip_${index}`">
            <v-icon size="18" :color="chip.status == 'open' ? 'primary lighten-1' : 'error lighten-1'">{{ chip.status == 'open' ? 'mdi-cellphone-check' : 'mdi-cellphone-remove' }}</v-icon>
          </span>
        </div>
      </template>
      <span>Estado dos chips</span>
    </v-tooltip>

    <v-menu offset-y>
      <template #activator="{ on }">
        <ib-btn color="gray500" text small v-on="on">
          <v-icon class="gray950--text" left>
            mdi-bell
          </v-icon>
          <v-badge inline color="red400" :value="notificationLengthText" :content="notificationLengthText" offset-y="22" class="cursor-pointer mr-2" style="pointer-events: inherit;" />
        </ib-btn>
      </template>

      <v-list dense>
        <v-list-item @click="$router.push({ name: 'Atendimento' }).catch(() => { })">
          <v-list-item-title>
            {{ openConversations === 0 ? 'Sem atendimento humano aberto' : openConversations > 1 ? `${openConversations} atendimentos humanos em andamento` : `${openConversations} atendimento humano aberto` }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import Api from "@/lib/Api";

export default {
  data() {
    return {
      chips: []
    }
  },

  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    pageTitle() {
      return this.$route.meta.title || this.$route.name
    },
    openConversations() {
      return this.$store.state.conversations.open_manual_count;
    },
    notificationLengthText() {
      const conversations = this.$store.state.conversations.open_manual_count;

      if (conversations <= 9) {
        return conversations;
      }

      return '9+';
    }

  },

  mounted() {
    this.getChips();

    //CHECAGEM BÁSICA DE CANAIS A CADA 30 SEGUNDOS
    setInterval(() => {
      this.getChips();
    }, 30 * 1000);
  },

  created() {
    this.$root.$refs.global = this;
  },

  methods: {
    async getChips() {
      const resp = await Api.getChipNumbersByUser();

      if (!resp.error && resp.message) {
        this.chips = resp.message;
      }
    }
  }
};
</script>
