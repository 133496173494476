<template>
  <div class="login_container fade">
    <div v-if="logged" class="left">
      <div />
      <div class="left-header">
        <b>Você está logado.</b> Entre com navegação anônima ou deslogue o acesso atual.
      </div>
      <div />
    </div>

    <div v-if="!logged" class="left">
      <div class="left-header">
        <v-img class="logo mt-2" src="@/assets/logo2.png" />
      </div>

      <div class="login-form px-4 fade">
        <v-row>
          <v-col cols="12" class="text-center">
            <h1>Entre na sua conta</h1>
            <span>Insira seus dados abaixo.</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="customer_email" class="nd-text-field" color="green700" label="Digite o e-mail da conta" placeholder="Digite o e-mail da conta" outlined hide-details="auto" />
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="email" class="nd-text-field" color="green700" label="Digite seu e-mail" placeholder="Digite seu e-mail" outlined hide-details="auto" />
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="password" class="nd-text-field" color="green700" :type="showPassword ? 'text' : 'password'" label="Digite sua senha" @keydown.enter="login" placeholder="Digite sua senha" outlined hide-details="auto" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" />
          </v-col>

          <v-col cols="12">
            <v-btn class="nd-button-large" height="50" dark large block color="green600" @click="login">
              Fazer login
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <div class="bottom-text px-4">
        <span style="text-align: left;">© 2025 ∙ Inteligência Bot</span>
        <span style="text-align: right;">Todos os direitos reservados</span>
      </div>
    </div>

    <div class="right">
      <v-img class="login-image" src="@/assets/login_image.png" />
    </div>
  </div>
</template>

<script>
import Api from "@/lib/Api";
//import { emitToastr } from "@/lib/Utils";

export default {
  name: "LoginView",
  data() {
    return {
      logged: false,
      customer_email: "",
      email: "",
      password: "",
      showPassword: false,
    };
  },
  mounted() {
    const token = Api.getToken();
    if (token) this.logged = true;
  },

  methods: {
    async login() {
      const response = await Api.login(this.email, this.password, this.remember, true, this.customer_email);

      if (response.error) {
        this.$toast('error', response.message);
        return
      }

      this.$router.push("/");
    }
  },
};
</script>

<style scoped>
.login_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 100%;
  align-content: center;
  align-items: center;
  background: white;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 16px;
}

.left-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.first-access-alert {
  max-width: 500px;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: white;
  padding: 16px;
}

.login-form {
  max-width: 450px;
}

.login-image {
  max-height: calc(100vh - 32px);
  max-width: 50vw;
  border-radius: 6px;
}

.bottom-text {
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo {
  max-width: 350px;
}

@media only screen and (max-width: 900px) {
  .login_container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    height: 100%;
    align-content: center;
    align-items: center;
    background: white;
  }

  .right {
    padding: 0px;
    display: none;
  }

  .login-image {
    display: none;
  }

  .logo {
    max-width: 270px;
  }
}
</style>