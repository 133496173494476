<template>
  <div class="menu-page">
    <v-alert prominent color="primary" text icon="mdi-school-outline">
      <v-row align="center">
        <v-col cols="12" lg="8" md="8" sm="8" class="grow">
          Quer entender qual o melhor tipo de chatbot para fazer o primeiro atendimento? Assista nossos vídeos
          para usufruir do que há de melhor em automação.
        </v-col>
        <v-col cols="12" lg="4" md="4" sm="4" class="d-flex" @click="$router.push('/ajuda')">
          <v-spacer></v-spacer>
          <ib-btn class="primary">Ver vídeos</ib-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-card class="nd-border" flat>
      <v-card-title>
        Meus chips - Conectar chip
      </v-card-title>

      <v-card-subtitle>
        Conecte aqui seus números de Whatsapp para receber e enviar mensagens. <ib-btn small @click="showVideo = true"><v-icon size="16" class="mr-1">mdi-help-circle-outline</v-icon>Saiba mais</ib-btn>
      </v-card-subtitle>

      <v-card-text>
        <v-row>
          <v-col cols="12" class="d-flex">
            <v-spacer></v-spacer>
            <ib-btn color="primary" @click.native="dialogNew = true">
              <v-icon>mdi-plus</v-icon>Novo chip
            </ib-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col v-for="(item, index) in chip_numbers" :key="index" cols="12" lg="6" md="12" sm="12">
            <v-card class="nd-border" flat>
              <v-card-title :class="item.status == 'open' ? 'primary white--text' : 'error white--text'">
                {{ item.name }}
                <v-btn class="ml-2" icon dark @click="selectedItem = JSON.parse(JSON.stringify(item)); dialogRename = true"><v-icon>mdi-pencil-outline</v-icon></v-btn>
              </v-card-title>

              <v-card-subtitle :class="`${item.status == 'open' ? 'primary white--text' : 'error white--text'}`">
                {{ connection_statuses[item.status] }}
              </v-card-subtitle>

              <v-card-text>
                <v-row class="mt-4">
                  <v-col cols="12" lg="6" md="6" sm="12">
                    <ib-select v-model="item.redirect" chips mandatory small-chips item-text="name" item-value="id" :items="redirects" label="Primeiro atendimento" color="primary" outlined hide-details="auto" @change="changeChatbot(item)" />
                  </v-col>

                  <v-col v-if="!item.redirect || item.redirect == 'ai_chatbot'" cols="12" lg="6" md="6" sm="12">
                    <ib-select v-model="item.chatbot" chips mandatory small-chips item-text="name" item-value="_id" :items="chatbots" label="Chatbot" color="primary" outlined hide-details="auto" @change="changeChatbot(item)" />
                  </v-col>

                  <v-col v-if="!item.redirect || item.redirect == 'flow_chatbot'" cols="12" lg="6" md="6" sm="12">
                    <ib-select v-model="item.flow" chips mandatory small-chips item-text="name" item-value="_id" :items="flows" label="Fluxo" color="primary" outlined hide-details="auto" @change="changeChatbot(item)" />
                  </v-col>

                  <v-col cols="12" lg="6" md="6" sm="12">
                    <ib-select v-model="item.department" chips mandatory small-chips item-text="name" item-value="_id" :items="departments" label="Departamento" color="primary" outlined hide-details="auto" @change="changeChatbot(item)" />
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions class="d-flex flex-wrap">
                <v-menu>
                  <template #activator="{ on, attrs }">
                    <ib-btn color="error" v-bind="attrs" v-on="on">
                      <v-icon>mdi-cellphone-remove</v-icon> Desconectar
                    </ib-btn>
                  </template>

                  <v-card>
                    <v-list-item v-if="item.status == 'open'" @click="disconnectChip(item)">
                      <v-list-item-icon>
                        <v-icon>mdi-cellphone-remove</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Apenas desconectar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item @click="selectedItem = item; dialog = true">
                      <v-list-item-icon>
                        <v-icon>mdi-cellphone-off</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>Resetar conexão</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-menu>

                <v-spacer></v-spacer>
                <ib-btn v-if="item.changed" color="primary" class="mr-2 my-1" @click.native="changeChipChatbot(item)">
                  Salvar
                </ib-btn>
                <ib-btn v-if="!item.changed && item.status != 'open' && !item.qrcode" class="my-1" color="primary" @click.native="connectChip(item)">
                  Conectar
                </ib-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <ib-dialog v-model="dialogQrCode" scrollable max-width="600" persistent>
      <v-card>
        <ib-dialog-header title="Conectar Whatsapp" subtitle="Siga as instruções abaixo para conectar seu Whatsapp" :close="() => { qrcode_chip = {}; dialogQrCode = false }"></ib-dialog-header>

        <v-card-text v-if="qrcode_chip.qrcode && qrcode_chip.status != 'open'" class="mt-4">
          <div>1. Abra o Whatsapp no seu celular</div>
          <div>
            2. Toque no ícone
            <svg class="mx-1" height="24px" viewBox="0 0 24 24" width="24px">
              <rect fill="#f2f2f2" height="24" rx="3" width="24" />
              <path d="m12 15.5c.825 0 1.5.675 1.5 1.5s-.675 1.5-1.5 1.5-1.5-.675-1.5-1.5.675-1.5 1.5-1.5zm0-2c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5zm0-5c-.825 0-1.5-.675-1.5-1.5s.675-1.5 1.5-1.5 1.5.675 1.5 1.5-.675 1.5-1.5 1.5z" fill="#818b90" />
            </svg>
            no Android ou em
            <svg class="mx-1" width="24" height="24" viewBox="0 0 24 24">
              <rect fill="#F2F2F2" width="24" height="24" rx="3" />
              <path d="M12 18.69c-1.08 0-2.1-.25-2.99-.71L11.43 14c.24.06.4.08.56.08.92 0 1.67-.59 1.99-1.59h4.62c-.26 3.49-3.05 6.2-6.6 6.2zm-1.04-6.67c0-.57.48-1.02 1.03-1.02.57 0 1.05.45 1.05 1.02 0 .57-.47 1.03-1.05 1.03-.54.01-1.03-.46-1.03-1.03zM5.4 12c0-2.29 1.08-4.28 2.78-5.49l2.39 4.08c-.42.42-.64.91-.64 1.44 0 .52.21 1 .65 1.44l-2.44 4C6.47 16.26 5.4 14.27 5.4 12zm8.57-.49c-.33-.97-1.08-1.54-1.99-1.54-.16 0-.32.02-.57.08L9.04 5.99c.89-.44 1.89-.69 2.96-.69 3.56 0 6.36 2.72 6.59 6.21h-4.62zM12 19.8c.22 0 .42-.02.65-.04l.44.84c.08.18.25.27.47.24.21-.03.33-.17.36-.38l.14-.93c.41-.11.82-.27 1.21-.44l.69.61c.15.15.33.17.54.07.17-.1.24-.27.2-.48l-.2-.92c.35-.24.69-.52.99-.82l.86.36c.2.08.37.05.53-.14.14-.15.15-.34.03-.52l-.5-.8c.25-.35.45-.73.63-1.12l.95.05c.21.01.37-.09.44-.29.07-.2.01-.38-.16-.51l-.73-.58c.1-.4.19-.83.22-1.27l.89-.28c.2-.07.31-.22.31-.43s-.11-.35-.31-.42l-.89-.28c-.03-.44-.12-.86-.22-1.27l.73-.59c.16-.12.22-.29.16-.5-.07-.2-.23-.31-.44-.29l-.95.04c-.18-.4-.39-.77-.63-1.12l.5-.8c.12-.17.1-.36-.03-.51-.16-.18-.33-.22-.53-.14l-.86.35c-.31-.3-.65-.58-.99-.82l.2-.91c.03-.22-.03-.4-.2-.49-.18-.1-.34-.09-.48.01l-.74.66c-.39-.18-.8-.32-1.21-.43l-.14-.93a.426.426 0 00-.36-.39c-.22-.03-.39.05-.47.22l-.44.84-.43-.02h-.22c-.22 0-.42.01-.65.03l-.44-.84c-.08-.17-.25-.25-.48-.22-.2.03-.33.17-.36.39l-.13.88c-.42.12-.83.26-1.22.44l-.69-.61c-.15-.15-.33-.17-.53-.06-.18.09-.24.26-.2.49l.2.91c-.36.24-.7.52-1 .82l-.86-.35c-.19-.09-.37-.05-.52.13-.14.15-.16.34-.04.51l.5.8c-.25.35-.45.72-.64 1.12l-.94-.04c-.21-.01-.37.1-.44.3-.07.2-.02.38.16.5l.73.59c-.1.41-.19.83-.22 1.27l-.89.29c-.21.07-.31.21-.31.42 0 .22.1.36.31.43l.89.28c.03.44.1.87.22 1.27l-.73.58c-.17.12-.22.31-.16.51.07.2.23.31.44.29l.94-.05c.18.39.39.77.63 1.12l-.5.8c-.12.18-.1.37.04.52.16.18.33.22.52.14l.86-.36c.3.31.64.58.99.82l-.2.92c-.04.22.03.39.2.49.2.1.38.08.54-.07l.69-.61c.39.17.8.33 1.21.44l.13.93c.03.21.16.35.37.39.22.03.39-.06.47-.24l.44-.84c.23.02.44.04.66.04z" fill="#818b90" />
            </svg>
            no iPhone
          </div>
          <div>
            3. Toque em <b>Dispositivos conectados</b> e, em seguida, em <b>Conectar um dispositivo</b>
          </div>
          <div>4. Aponte seu celular para esta tela para capturar o QR code</div>
        </v-card-text>

        <v-card-text v-if="qrcode_chip.qrcode && qrcode_chip.status != 'open'" class="py-6 d-flex justify-center" style="background: white;">
          <qrcode-vue :value="qrcode_chip.qrcode" size="260" level="M" />
        </v-card-text>
      </v-card>
    </ib-dialog>

    <ib-dialog v-model="dialogNew" scrollable max-width="600" persistent>
      <v-card>
        <ib-dialog-header subtitle="Na primeira configuração, é necessário conectá-lo assim como se conecta um número ao Whatsapp Web." title="Whatsapp" :close="() => { dialogNew = false }"></ib-dialog-header>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <ib-text-field v-model="new_chip_name" class="pt-1" label="Nome do canal" outlined color="primary" hide-details="auto" @keyup="formatText" />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <ib-select v-model="new_chip_redirect" chips class="pt-1" mandatory small-chips item-text="name" item-value="id" :items="redirects" label="Primeiro atendimento" color="primary" outlined hide-details="auto" />
            </v-col>
            <v-col v-if="new_chip_redirect == 'ai_chatbot'" cols="12" lg="6" md="6" sm="12">
              <ib-select v-model="new_chip_chatbot" class="pt-1" chips small-chips clearable item-text="name" item-value="_id" :items="chatbots" label="Chatbot" color="primary" outlined hide-details="auto" />
            </v-col>
            <v-col v-if="new_chip_redirect == 'flow_chatbot'" cols="12" lg="6" md="6" sm="12">
              <ib-select v-model="new_chip_flow" class="pt-1" chips small-chips clearable item-text="name" item-value="_id" :items="flows" label="Fluxo" color="primary" outlined hide-details="auto" />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <ib-select v-model="new_chip_department" class="pt-1" chips small-chips clearable item-text="name" item-value="_id" :items="departments" label="Departamento padrão" color="primary" outlined hide-details="auto" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <ib-btn color="gray200" outlined @click.native="dialogNew = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>
          <v-spacer />
          <ib-btn color="primary" :loading="loading" @click.native="createNew">
            Salvar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <ib-dialog v-model="dialogRename" scrollable max-width="600" persistent>
      <v-card>
        <ib-dialog-header title="Renomear chip" :close="() => { dialogRename = false }"></ib-dialog-header>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <ib-text-field v-model="selectedItem.name" class="pt-1" label="Nome do canal" outlined color="primary" hide-details="auto" @keyup="formatText" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>

          <ib-btn color="gray200" outlined @click.native="dialogRename = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>
          <v-spacer />
          <ib-btn color="primary" :loading="loading" @click.native="renameChip(selectedItem);">
            Salvar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>


    <ib-dialog v-model="dialog" overlay-opacity="0.5" overlay-color="black" max-width="420">
      <v-card>
        <ib-dialog-header title="Resetar conexão" subtitle="Pode resolver problemas de conexão, mas será necessário conectar com QRCode novamente." :close="() => { dialog = false }"></ib-dialog-header>

        <v-card-text>
          Tem certeza que deseja apagar as credenciais do canal <b>{{ selectedItem.name }}</b>? Para
          conectar novamente será necessária a leitura do QRCode.
        </v-card-text>
        <v-card-actions>

          <ib-btn color="gray200" outlined @click="dialog = false">
            <div class="gray1000--text">
              Cancelar
            </div>
          </ib-btn>
          <v-spacer />
          <ib-btn color="error" @click="dialog = false; resetCreds(selectedItem)">
            Resetar
          </ib-btn>
        </v-card-actions>
      </v-card>
    </ib-dialog>

    <video-dialog v-model="showVideo" videoUrl="https://www.youtube.com/embed/F-wiQbFw2l4?rel=0" />
  </div>
</template>

<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";
import QrcodeVue from "qrcode.vue";
import VideoDialog from "@/components/VideoDialog.vue";

let socket;
let socket_connection_count = 0;

export default {
  name: "ChipNumbersView",

  components: {
    QrcodeVue,
    VideoDialog
  },

  data() {
    return {
      showVideo: false,
      chip_numbers: [],
      new_chip_name: "",
      new_chip_redirect: "ai_chatbot",
      new_chip_chatbot: "",
      new_chip_department: "",
      new_chip_flow: "",
      selectedItem: {},
      qrcode_chip: {},
      chatbots: [],
      departments: [],
      flows: [],
      redirects: [{ name: 'Chatbot de IA', id: 'ai_chatbot' }, { name: 'Chatbot de Fluxo', id: 'flow_chatbot' }, { name: 'Atendimento humano', id: 'human' }],
      loading: false,
      dialog: false,
      dialogNew: false,
      dialogRename: false,
      dialogQrCode: false,
      connection_statuses: {
        "close": "Desconectado",
        "new": "Desconectado",
        "open": "Conectado",
        "connecting": "Conectando",
      }
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },

  mounted() {
    this.getChips();
    this.getChatsbots();
    this.getDepartments();
    this.getFlows();
    this.startSocket();
  },

  async beforeDestroy() {
    socket.disconnect();
    socket = null;
  },

  methods: {
    async getChips() {
      const resp = await Api.getChipNumbersByUser();

      if (!resp.error && resp.message) {
        this.chip_numbers = resp.message;
      }
    },

    async getChatsbots() {
      const resp = await Api.getChatbotsByUser();

      if (!resp.error && resp.message) {
        this.chatbots = resp.message;
      }
    },

    async getDepartments() {
      const resp = await Api.getDepartmentsByUser();

      if (!resp.error && resp.message) {
        this.departments = resp.message;
      }
    },

    async getFlows() {
      const resp = await Api.getFlowsByUser();

      if (!resp.error && resp.message) {
        this.flows = resp.message;
      }
    },

    startSocket() {
      if (socket_connection_count >= 3) {
        emitToastr("error", "Erro na conexão com o socket. Atualize a página para tentar novamente.");
        return;
      }

      if (!this.$store.state.SOCKET_IO_URL) {
        socket_connection_count++
        setTimeout(() => { this.startSocket(); }, 500 + socket_connection_count * 2000);
        emitToastr("info", "Aguardando endereço do socket...");
        return;
      }

      socket_connection_count = 0;
      socket = this.$io(this.$store.state.SOCKET_IO_URL, { path: '/sock/' });

      socket.on("connect", () => {
        socket.emit("join-room", this.currentUser?.account?._id);
      });


      socket.on("receive-message", data => {
        if (data?.type == 'qrcode') {
          if (data.chip_number_id && data.message) this.updateQrcode(data.chip_number_id, data.message);
        }

        if (data?.type == 'status') {
          if (data.chip_number_id && data.message) this.updateStatus(data.chip_number_id, data.message);
        }
      });
    },

    async createNew() {
      if (this.new_chip_name == "") return emitToastr("error", "Nome do número necessário");
      if (this.new_chip_redirect == "ai_chatbot" && this.new_chip_chatbot == "") return emitToastr("error", "Chatbot necessário para este tipo de primeiro atendimento.");
      if (this.new_chip_redirect == "flow_chatbot" && this.new_chip_flow == "") return emitToastr("error", "Chatbot de fluxo necessário para este tipo de primeiro atendimento.");
      if (this.new_chip_department == "") return emitToastr("error", "Departamento de entrada da conversa necessário");

      const chip = {
        name: this.new_chip_name.trim(),
        status: "new",
        chatbot: this.new_chip_chatbot,
        flow: this.new_chip_flow,
        redirect: this.new_chip_redirect,
        department: this.new_chip_department,
        active: false
      }

      this.loading = true;
      const resp = await Api.createOrUpdateChipNumber(chip);
      this.loading = false;

      if (!resp.error) {
        this.new_chip_name = "";
        emitToastr("success", "Número cadastrado com sucesso. Agora será necessário fazer a conexão.");
        this.getChips();
        this.dialogNew = false;
      }
    },

    updateStatus(chip_number_id, status) {
      this.chip_numbers.forEach(chip => {
        if (chip._id.toString() == chip_number_id.toString()) {
          chip.status = status;
          delete chip.qrcode;
          this.dialogQrCode = false;
        }
      })
    },

    updateQrcode(chip_number_id, qrcode) {
      this.chip_numbers.forEach(chip => {
        if (chip._id.toString() == chip_number_id.toString()) {
          this.qrcode_chip = JSON.parse(JSON.stringify(chip));
          this.qrcode_chip.qrcode = qrcode;
          this.dialogQrCode = true;
          this.$forceUpdate();
        }
      })
    },

    async connectChip(chip) {
      await Api.connectChipNumber(chip, true);
    },

    async changeChipChatbot(chip) {
      const resp = await Api.changeChipNumberChatbot(chip);

      if (resp.error) {
        emitToastr("error", resp.message);
      }
      else {
        emitToastr("success", "Canal atualizado com sucesso");
        this.getChips();
      }
    },

    async renameChip(chip) {
      const resp = await Api.renameChipNumber(chip);

      if (resp.error) {
        emitToastr("error", resp.message);
      }
      else {
        emitToastr("success", "Canal renomeado com sucesso");
        this.getChips();
      }

      this.dialogRename = false;
    },


    async disconnectChip(chip) {
      await Api.connectChipNumber(chip, false);
    },

    async resetCreds(chip) {
      chip.reset = true;
      const resp = await Api.createOrUpdateChipNumber(chip);
      if (!resp.error) emitToastr("success", "Atualizado com sucesso!")

      this.getChips();
      this.selectedItem = {};
    },

    formatText() {
      let text = this.new_chip_name;
      if (!text) return;

      text = text.toLowerCase();
      text = text.normalize("NFD").replace(/[^a-z0-9]/gi, "");
      text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      text = text.split(" ").join("");

      this.new_chip_name = text;
    },

    async changeChatbot(item) {
      item.changed = true;
      this.$forceUpdate();
    }
  },
};
</script>

<style scoped></style>