const toastr = require("toastr");
const toastrOptions = { transitionIn: "fadeIn", transitionOut: "fadeOut", position: "top-right", progressBar: true };

export const emitToastr = (type = "error", title = "", message = "") => {
  return toastr[type](title, message, toastrOptions);
};

export const onlyNumbers = (string) => {
  return /^[0-9]+$/.test(string);
}

export function hexToRGB(value) {
    value = value.replace("#", "");

    return {
        r: parseInt(value.substring(0, 2), 16),
        g: parseInt(value.substring(2, 4), 16),
        b: parseInt(value.substring(4, 6), 16),
    };
}

export function rgbToHSL(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    let h, s, l = (max + min) / 2;

    if (max === min) {
        h = s = 0;
    }
    else {
        const d = max - min;

        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }

        h /= 6;
    }

    h = Math.round(h * 360);
    s = Math.round(s * 100);
    l = Math.round(l * 100);

    return { h, s, l };
}

export function hexToHSL(value) {
    const rgb = hexToRGB(value);

    return rgbToHSL(rgb.r, rgb.g, rgb.b);
}
