<template>
  <v-avatar v-bind="$attrs" v-on="$listeners" :color="color">
    <v-img v-if="user.profile_picture" :src="user.profile_picture" />
    <div v-else class="font-weight-bold" :style="initials.length > 1 ? 'font-size: 60%' : 'font-size: 80%'">
      {{ initials }}
    </div>
  </v-avatar>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      default: 'gray100'
    }
  },
  computed: {
    initials() {
      const name = this.user?.override_name || this.user?.name;
      if (!name) return "";
      
      const [first, last] = name.split(' ')

      if (first && last) {
        return first.charAt(0) + last.charAt(0)
      }

      return first?.charAt(0)

    },
  }
}
</script>
