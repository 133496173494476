<template>
  <v-navigation-drawer v-model="drawer" fixed temporary right width="550" max-width="80%" style="z-index: 999;">
    <v-list-item>
      <ib-btn v-if="isMobile" icon large class="mr-1" @click="drawer = false">
        <v-icon>mdi-close</v-icon>
      </ib-btn>

      <ib-user-avatar :user="contact" size="55" class="mr-4 my-4" />

      <v-list-item-content>
        <v-list-item-title><b>{{ contact.override_name || contact.name }}</b></v-list-item-title>
        <v-list-item-subtitle>
          <v-icon size="16">mdi-phone-outline</v-icon>
          {{ $utils.formatPhone(contact.phone) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mx-4 semi_transparent" />

    <v-list dense>
      <v-list-item v-if="contact?.tags?.length" class="mb-2">
        <v-list-item-icon class="mb-2">
          <v-icon>mdi-tag-multiple-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content class="gray600--text">
          <v-list-item-title class="mb-2 subtitle-2 ">
            <b>Tags</b>
          </v-list-item-title>
          <v-list-item-title class="d-flex flex-wrap">
            <ib-chip v-for="(tag, index_tag) in contact?.tags" v-show="tags_dictionary[tag]" :key="`tag2_${index_tag}`" small class="mr-1 mt-1" :color="tags_dictionary[tag]?.color">
              {{ tags_dictionary[tag]?.name }}
            </ib-chip>
          </v-list-item-title>

        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="contact?.tags?.length" class="mx-4 semi_transparent" />

      <v-list-item v-if="contact_data_vars.length > 0" class="mb-2 gray600--text">
        <v-list-item-icon class="mb-2">
          <v-icon>mdi-account-details-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="mb-2 subtitle-2">
            <b>Variáveis</b>
          </v-list-item-title>
          <v-list-item-title v-for="(item, index) in contact_data_vars" :key="`c_var_${index}`">
            <b>{{ item.key }}:</b> {{ item.value }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="contact_data_vars.length" class="mx-4 semi_transparent" />

      <v-list-item class="mb-2 gray600--text">
        <v-list-item-icon class="mb-2">
          <v-icon>mdi-note-edit-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="mb-2 subtitle-2">
            <b>Anotações</b>
          </v-list-item-title>
          <v-row>
            <v-col v-for="(item, index) in contact.annotations" :key="`annotations_${index}`" cols="12">
              <div v-if="item.user && item.created" class="small-text mb-1"><b>{{ item.user }}</b> - {{ $date.smallFormat(item.created) }}</div>
              <div class="d-flex">
                <v-textarea v-model="contact.annotations[index].text" class="mt-2 nd-text-field" rows="4" :label="`Anotação ${index + 1}`" placeholder="Escreva aqui a anotação sobre esse contato ou atendimento efetuado." outlined hide-details="auto" />
                <ib-btn icon color="error lighten-2" class="ml-2" @click="contact.annotations.splice(index, 1)">
                  <v-icon size="20">mdi-trash-can-outline</v-icon>
                </ib-btn>
              </div>
            </v-col>

            <v-col cols="12">
              <ib-btn small class="mr-4" color="primary" outlined @click="contact.annotations.push({ user: currentUser.name, created: new Date(), text: '' })">
                <v-icon left>
                  mdi-plus
                </v-icon>
                Nova anotação
              </ib-btn>
              <ib-btn small color="primary" @click="changeContactAnnotations()">
                Salvar
              </ib-btn>
            </v-col>
          </v-row>
        </v-list-item-content>

      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Api from '@/lib/Api';
import { emitToastr } from "@/lib/Utils";

export default {
  props: {
    value: Boolean,
    contact_id: String,
    tags_dictionary: Object
  },
  data() {
    return {
      loading: false,
      contact: {},
      contact_data_vars: []
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },

    currentUser() {
      return this.$store.state.currentUser
    },

    drawer: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  watch: {
    drawer: {
      async handler() {
        if (this.drawer && this.contact_id) {
          this.loading = true;
          const resp = await Api.getContactById(this.contact_id);
          this.loading = false;

          if (!resp.error && resp.message) {
            this.contact = resp.message;
            this.contact_data_vars = [];

            if (this.contact.data && Object.keys(this.contact.data).length > 0) {
              this.contact_data_vars = Object.keys(this.contact.data).map(item => ({ key: item, value: this.contact.data[item] }));
            }

            if (this.contact.annotations?.length) {
              this.contact.annotations.forEach((a, index) => {
                if (typeof (a) === 'string') {
                  this.contact.annotations[index] = { text: a };
                }
              })
            }
          }
        }
      },
      deep: false,
    }
  },
  methods: {
    async changeContactAnnotations() {
      this.loading = true;
      const resp = await Api.changeContactAnnotations(this.contact);
      this.loading = false;
      if (!resp.error) emitToastr("success", "Anotações atualizadas");

    }
  }
};
</script>

<style>
.semi_transparent {
  opacity: 0.5;
}

.small-text {
  font-size: 12px;
}

textarea {
  scrollbar-width: thin !important;
  scrollbar-color: rgba(206, 206, 206, 0.5) transparent !important;
}
</style>